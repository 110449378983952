export interface breadcrumbtype {
  url: string;
  value: string;
  isActive: boolean;
}

export interface columnType {
  key: string;
  title: string;
  isSortable: boolean;
  displayType?:
  | string
  | 'STATUS'
  | 'LINK'
  | 'TEXT'
  | 'ACTION'
  | 'DELETE'
  | 'EDIT'
  | 'REJECTION_COMMENTS'
  | 'INPUT'
  | 'EDIT-DELETE'
  | 'CONDITIONALLINK'
  | 'ADD-DELETE';
  filterable?: boolean;
  filterOptions?: Array<any>;
  cellHeaderClass?: string;
  cellDataClass?: string;
  items?: Array<string>;
  menuItems?: Array<string>;
  expandRowData?: Array<{ title: string, key: string, class: string }>;
  sticky?: boolean;
  conditionKey?: string;
  isAdd?: boolean;
  dateFormat?: string;
}

export interface dynamicColumnType {
  MMC_COLNAME: string;
  MMC_COLDISPLAYNAME: string;
  MMC_HIDDEN: string;
  MMC_ELEMENTTYPE: string;
  MMC_MAPPEDCOL: string;
  MMC_REQUIRED: string;
  MMC_VALIDATIONLIST?: Array<any>;
}

export interface filterDataModel {
  id: number;
  title: string;
  subFilters: Array<subfilterData>
}

export interface subfilterData {
  id: number;
  title: string;
  key: string;
  type: string;
  value: string | null;
  filters: Array<subSubfilterData>;
  options: any;
  innerTitle?: any;
  titleClass: string;
}


export interface subSubfilterData {
  id: number;
  count: number;
  title: string;
  key: string;
  label: string;
  checked: boolean;
  disabled: boolean;
}

export enum OperationType {
  navigation = 0,
  input = 1,
  select = 2,
  showHidePanel = 3,
  search = 4,
  reset = 5,
  edit = 6,
  delete = 7,
  export = 8,
  add = 9,
  popup = 10,
  reload = 11,
  view = 12,
  expandableRow = 13,
  submit = 14
}
export interface LdFlag {
  key: string;
  value: any;
}

export enum Frequency {
  oneTime = 1,
  daily = 4,
  weekly = 8,
  monthly = 16
}

export enum Weeks {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7
}
export interface filterType {
  id: number;
  name: string;
  stext: string;
}

export enum StepperType {
  demandDurationDone = 'DEMANDDURATIONDONE',
  jobDescriptionDone = 'JOBDESCRIPTIONDONE'
}
