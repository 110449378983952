import { AfterViewInit, Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import * as d3 from 'd3';
@Component({
  selector: 'app-donutchart',
  templateUrl: './donutchart.component.html',
  styleUrls: ['./donutchart.component.scss']
})
export class DonutchartComponent implements OnInit, AfterViewInit {
  @Input() data: any;
  @Input() uniqueId: string;
  @ViewChild('donutChart', { static: false }) donutChart: any;

  ngOnInit(): void {
  }
  constructor() {
    this.uniqueId = '';
   }

  ngAfterViewInit(): void {
    this.plotDonut();
  }

  @HostListener('window:resize', ['$event'])
  plotDonut(): void {
    if (this.donutChart) {
      const chart = d3.select(this.donutChart.nativeElement);
      chart.selectAll('svg').remove();
      const containerWidth = this.donutChart.nativeElement.offsetWidth;
      const width = (containerWidth > 275) ? 240 : 180;
      const height = width;
      const radius = Math.min(width, height) / 2;
      const thickness = 74;
      const svgWidth = chart.node().getBoundingClientRect().width;
      const svgHeight = 300;
      const svg = chart
        .append('svg')
        .attr('width', svgWidth)
        .attr('height', svgHeight)
        .append('g').attr('width', width)
        .attr('transform', 'translate(' + svgWidth / 2 + ',' + svgHeight / 2 + ')')
        .attr('role', 'img')
        .attr('aria-describedby', this.uniqueId);
      const color: any = d3
        .scaleOrdinal()
        .domain(this.data)
        .range(this.data.map((d: any) => d.color));
      const pie = d3
        .pie()
        .value((d: any) => d.value)
        .sort(null);

      const dataReady = pie(this.data);
      const arc: any = d3.arc().innerRadius(radius - thickness).outerRadius(radius);
      d3.selectAll('.tooltip').remove();
      const divTooltip = d3
        .select('body')
        .append('div')
        .attr('class', 'tooltip')
        .attr('id',this.uniqueId)
        .style('opacity', 0);

      svg
        .selectAll('g')
        .data(dataReady)
        .enter()
        .append('path')
        .attr('d', arc)
        .attr('fill', (d: any, i: any) => color(i))
        .attr('cx', 52)
        .attr('cy', 52)
        .attr('transform', 'translate(0, 0)')

        .on('mousemove',  (d) => {
          const x = d.pageX + 10;
          const y = d.pageY + 10;
          divTooltip.style('opacity', 0.9);
          divTooltip
            .html('<div><span class="donut-tooltip id=' + this.uniqueId + ''
              + d.currentTarget.__data__.data.type + '"></span>'
              + d.currentTarget.__data__.data.key
              + '<span style="margin-left:10px;margin-right:6px"><b>' + d.currentTarget.__data__.data.value + '</b></span></div>');
          divTooltip.style('left', x + 'px');
          divTooltip.style('top', y + 'px');
        })
        .on('mouseout', function () {
          divTooltip.style('opacity', 0);

          d3.select(this).transition()
            .duration(500)
            // .ease("bounce")
            .attr('d', arc);
        })
        .on('mouseover', function () {
          const arc: any = d3.arc().innerRadius(radius - thickness).outerRadius(radius * 1.08);
          d3.select(this).transition()
            .attr('d', arc);
        });
    }
  }

}
