import { AfterViewInit, Directive, HostListener, Input } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';

@Directive({
  selector: '[appNgSelect]'
})
export class NgSelectDirective implements AfterViewInit {
  @Input() appNgSelect = '';
  @Input() isSearchInput = false;
  constructor(private el: NgSelectComponent) {
  }
  ngAfterViewInit(): void {
    this.el.element?.querySelectorAll('.ng-input')[0].setAttribute('aria-label', this.appNgSelect);
    this.el.element?.querySelectorAll('.ng-value-container')[0].setAttribute('aria-live', 'polite');
  }
  @HostListener('open', ['$event']) open(): void {
    if (this.isSearchInput) {
      setTimeout(() => {
        this.focusInputElement();
      });
    }
  }
  @HostListener('close', ['$event']) close(): void {
    if (this.isSearchInput) {
      this.el.focus();
    }
  }
  @HostListener('document:keydown.enter', ['$event']) onKeydownHandler(): void {
    if (this.isSearchInput) {
      this.focusInputElement();
    }
  }
  focusInputElement(): void {
    const element: HTMLElement = document?.querySelector('.ng-dropdown-header input') as HTMLElement;
    if (element)
      element.focus();
  }
}
