import { Component, ElementRef, HostListener, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-personalization',
  templateUrl: './personalization.component.html',
  styleUrls: ['./personalization.component.scss']
})
export class PersonalizationComponent implements OnInit {
  @Input() maxCheckboxCount: number;
  @Input() expandRowData: boolean;
  checkboxGroup: FormGroup | undefined;
  chkArray: any;
  isReset: boolean;
  setDefault: boolean;

  @ViewChild('scrollContainer', { static: false }) scrollContainer!: ElementRef;

  constructor(public dialogRef: MatDialogRef<PersonalizationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder,
    public commonServics: CommonService) {
    this.chkArray = [];
    this.isReset = false;
    this.setDefault = false;
    this.maxCheckboxCount = 0;
    this.expandRowData = false;
  }

  ngOnInit(): void {
    this.setForm();
  }
  onCheckboxChange(event: any): void {
    const chkArray = this.checkboxGroup?.controls['checkboxesValues'].value === null
      ? [] : this.checkboxGroup?.controls['checkboxesValues'].value;
    if (event.checked) {
      chkArray.push(event.source.value);
    } else {
      const idx = chkArray.findIndex((x: any) => x === event.source.value);
      chkArray.splice(idx, 1);
    }
    this.checkboxGroup?.controls['checkboxesValues'].setValue(chkArray);
  }

  getCount(): number {
    return this.data.columnDef.filter((t: any) => t.isHidePersonalized !== true).length;
  }

  setForm(): void {
    if (this.data.expandRowData) {
      const temp = this.data.columnDef.find((i: any) => i.key === 'actions')['expandRowData'];
      this.data.columnDef = [...this.data.columnDef, ...temp];
    }
    else {
      this.data.columnDef = [...this.data.columnDef];
    }
    this.maxCheckboxCount = this.data.maxCheckboxCount;
    this.checkboxGroup = this.fb.group({
      checkboxes: this.fb.array(
        this.data.columnDef.map((x: any) => this.data.selectedValues.includes(x.key) ?
          { value: true, disabled: x.isDisabled } : { value: false, disabled: x.isDisabled })),
      checkboxesValues: [this.data.selectedValues, [Validators.required, Validators.minLength(3), Validators.maxLength(5)]]
    });
  }

  resetForm(): void {
    this.isReset = true;
    this.checkboxGroup?.reset();
    this.data.selectedValues = this.commonServics.sanitizeJSON(this.data.defaultValues);
    this.checkboxGroup = this.fb.group({
      checkboxes: this.fb.array(this.data.columnDef.map((x: any) => this.data.selectedValues.includes(x.key) ? true : false)),
      checkboxesValues: [this.data.selectedValues, [Validators.required, Validators.minLength(3), Validators.maxLength(5)]]
    });
  }
  applyForm(): void {
    // check and pass back event with the selected value to resource-management
    this.dialogRef.close({ event: this.checkboxGroup?.value?.checkboxesValues, setDefault: this.setDefault });
  }

  @HostListener('wheel', ['$event'])
  onMouseWheel(event: WheelEvent): void {
    if (this.scrollContainer && this.scrollContainer.nativeElement) {
      const containerElement = this.scrollContainer.nativeElement;
      containerElement.scrollLeft += event.deltaX;
      containerElement.scrollLeft += event.deltaY;
      event.preventDefault();
    }
  }
}
