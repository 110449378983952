import { Component, OnInit, inject, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {
  openNewTab: boolean;
  snackBarRef = inject(MatSnackBarRef);
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, private router: Router) {
    this.openNewTab = data.openNewTab;
  }

  ngOnInit(): void {
  }

  routeTo(routeUrl: string): void {
    if (this.openNewTab) {
      window.open(routeUrl, '_blank');
    } else {
      this.router.navigate([routeUrl]);
    }
  }
}
