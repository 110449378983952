export class ConstantData {
  public static rebarToken = 'https://login.microsoftonline.com/f3211d0e-125b-42c3-86db-322b19a65a22/oauth2/token';
  public static rebarMessaging = 'https://rebarmessaging-publisher.ciostage.accenture.com/Publish';
  public static getUserGroup = 'GetUserGroup?code=cccon4TfxPmUP9a5CYa2qhrilc9eNVrijQwhaC6PILkXAzFuVNqJ4g==';
  public static getUserRole = 'GetUserRole?code=pu2jGBcHWcsn7nTs-PLr7Y-zO_jUrE4o_mFBJhYKPXZ8AzFuQfvctA==';
  public static getQueuedList = 'GetQueuedList?code=-cOrBlBxqTNH6c88eTBTrKPmzg1um-IdQpFC_pT0TaeqAzFu0byAdw==';
  public static deleteAuditTrail = 'DeleteAuditTrailRequest?code=3MfT1JJ7u-0BqWaa0tAYA8cwPCNjEG0Vu-qw-tgWxsI0AzFu6_79GA==';
  public static insertDemand = 'InsertDemandAuditTrailRequest?code=nm6TWUSqfTxlJoW65IgmsIUaDVJEQtBk0ISyihX_G63jAzFudxgCKw==';
  public static insertUserAccess = 'InsertUserAccessAuditTrailRequest?code=xAIWA6j3tMfYlFpkcIjjb-tkoB-2GpPh29btG6M7m0BhAzFudyAKoQ==';
  public static getDownloadDetails = 'DownloadFile?code=ADBOwDLvBocsTWe0S9AhEzQpxLyH4zBfnopbNoiP1PTSAzFu-5LJAQ==';
  public static GetUserDetail = 'GetUserDetail?code=AQ-hb4HG4JiIyHgrHcvUnnysFNZJUREiXsFfmOzQDn5JAzFuiT9k-g==';
  public static getProjectDetails = 'GetProjDetails?code=U2Vxrut2JGW3H_pwL95l_f0OTy8nV9cTdCbIph9vOPjqAzFucQXJSA==';
  public static searchByName = 'taskName';
  public static searchByDate = 'generatedOn';
  public static searchByDateRange = 'dateRange';
  public static searchByDataCheck = 'dataCheck';
  public static searchByOneDateRange = 'oneDateRange';
  public static donutData = 'RouterService/SSA/SSADATASERVICE/ROUTING/FetchApplicationStats';
  public static refusalData = 'RouterService/SSA/SSADATASERVICE/ROUTING/FetchRejRefStatus';
  public static projectDetails = 'RouterService/SOFTLOCK/LockHistory/ROUTING/LockHistory';
  public static ResourceDetails = 'RouterService/SSA/SSADATASERVICE/ROUTING/GetResourceWithoutCondition';
  public static FetchMatchingDemandData = 'RouterService/SSA/SSADATASERVICE/ROUTING/FetchMatchingDemandData';

  public static preferenceDetails = 'GetRMPreferenceDetails';
  public static learningDetails = 'GetMyLearningDetails';
  public static certificationDetails = 'GetMyCertificationDetails';
  public static teamsURL = 'https://teams.microsoft.com/l/chat/0/0?users=';
  public static domainName = '@accenture.com';
  public static tagList = 'RouterService/TAGFRAMEWORK/TAGFRAMEWORK/ROUTING/GetTagList';
  public static saveTagList = 'RouterService/TAGFRAMEWORK/TAGFRAMEWORK/ROUTING/TagAssociationGenericInsert';
  public static deleteTagList = '/RouterService/TAGFRAMEWORK/TAGFRAMEWORK/ROUTING/TagAssociationGenericDelete';
  public static getIaspireDetails = 'GetIaspireDetails';
  public static getSkillData = '/RouterService/SSA/SSADATASERVICE/ROUTING/GetResourceWithoutCondition';
  public static getFetchFlexLevel = '/RouterService/SSA/SSADATASERVICE/ROUTING/FetchFlexLevel';
  public static isTransferApprovalRequired = '/RouterService/TRANSFER/TRANSFERSERVICE/ROUTING/IsTransferLock';
  public static fetchDemandDetails = 'RouterService/SSA/SSADATASERVICE/ROUTING/FetchDemandList';
  public static getIJPDetails = 'RouterService/SSA/SSADATASERVICE/ROUTING/GetIJPDetails';
  public static getACMDetails = 'RouterService/SSA/SSADATASERVICE/ROUTING/GetACMDetails';
  public static supplyTag = 'RouterService/SOFTLOCK/SupplyTag/ROUTING/SupplyTag';
  public static checkFIFOTagSupply = 'RouterService/SOFTLOCK/SupplyTag/ROUTING/CheckFIFOTagSupply';
  public static checkTagSupplyCrossIGSupply = 'RouterService/SOFTLOCK/SupplyTag/ROUTING/CheckTagSupplyCrossIGSupply';
  public static defaultDate = '01/01/0001';
  public static talentTaskId = 119;
  public static taskId = 117;
  public static user_TaskID = 145;
  public static SR_TaskID = 123;
  public static fetchSupplyDashboardList = '/RouterService/SSA/SSADATASERVICE/ROUTING/FetchSupplyDashboardData';
  public static locking = 'RouterService/LOCKING/LOCKSERVICE/ROUTING/Locking';
  public static preValidationCheck = 'RouterService/TRANSFER/TRANSFERSERVICE/ROUTING/PreValidationCheck';
  public static UserDetails = 'https://smartsupply.ciostage.accenture.com/app/GetUserAcessDetail';
  public static markasreachable = 'RouterService/SOFTLOCK/RejectionRefusal/ROUTING/Reachable';
  public static notreachable = 'RouterService/SOFTLOCK/RejectionRefusal/ROUTING/NotReachable';
  public static rejectionRefusal = 'RouterService/SOFTLOCK/RejectionRefusal/ROUTING/RejectionRefusal';
  public static getRejectionReasons = 'RouterService/SOFTLOCK/MasterData/ROUTING/GetRejectionReasons';
  public static checkTagSupplyCoolingPeriod = 'RouterService/SOFTLOCK/SupplyTag/ROUTING/CheckTagSupplyCoolingPeriod';
  public static fetchSupplyDashboardData = 'RouterService/SSA/SSADATASERVICE/ROUTING/FetchSupplyDashboardData';
  public static getResourceDetails = 'RouterService/SOFTLOCK/MasterData/ROUTING/GetResourceDetails';
  public static fetchLeaveDetails = 'FetchLeaveDetails';
  public static getMasterData = 'RouterService/DEMAND/CREATE_DEMAND/BASICMASTERDATA/MASTERDATA';
  public static getUIAccessControl = '/RouterService/DEMAND/CREATE_DEMAND/ACESSCONTROL/UIAccessControl';
  public static releaseReopen = 'RouterService/CORELOCKING/RELEASE_REOPEN/ROUTING/RELEASE_REOPEN';
  public static getAutoCompleteName = 'AutoCompleteName';
  public static getAutoCompleteEID = 'AutoCompleteEID';
  public static fetchResource = 'FetchResource';
  public static getstatusAPI = 'GetstatusAPI';
  public static getScheduledTaskList = 'GetScheduledTaskList';
  public static deleteScheduledTask = 'DeleteScheduledTask';
  public static editScheduledTask = 'EditScheduledTask';

  /* ACCESS MANAGEMENT - RAISE ACCESS REQUEST DETAILS */
  public static getTeamUserGroupDetails = 'getAllUserGroups';
  public static getDUDetails = 'getDuDetails';
  public static getUserRoleDetails = 'getUGRoleDetails';
  public static getSubTeamUserMemberGroupDetails = 'getUserGroupMemberDetails';
  public static raiseAccessRequest = 'RaiseAccessRequest';
  public static getAccessRequestDetails = 'GetAccessRequestDetails';
  public static deletePendingAccessRequest = 'DeletePendingAccessRequest';
  public static getUserDetails = 'GetUserAccessDetails';
  public static getMyAccessDetails = 'GetMyAccessDetails';
  public static updateDefaultAccess = 'UpdateDefaultAccess';
  public static viewAccessRequestDashboard = 'ViewAccessRequestDashboard';
  public static viewRevokeAccess = 'ViewRevokeAccess';
  public static revokeAccess = 'RevokeAccess';
  public static ApproveAccessRequest = 'ApproveAccessRequest';
  public static RejectAccessRequest = 'RejectAccessRequest';
  public static GetExistingUserDetails = 'GetExistingUserDetails';
  public static extendValidityAccessRequest = 'ExtendValidityAccessRequest';
  public static gridPageSize = 40;
  public static deleteTalent = 'DeleteQueuedTask';
  public static downloadTalentFile = 'DownloadFile';
  public static getTalentQueuedList = 'GetQueuedList';
  public static getMoreActionsDetailsFunction = 'GetMoreActionsDetailsFunction';
  public static updateRedirectionPageFunction = 'UpdateRedirectionPageFunction';
  public static getMyRequestTracker = 'GetMyRequestTracker';
  public static getActiveRoleDetails = 'GetActiveRoleDetails';
  public static getUserGroupForRole = 'GetUserGroupForRole';
  public static getUGMDetails = 'GetUGMDetails';
  public static getSearchUser = 'SearchUser';
  public static getViewExistingUserAccess = 'ViewExistingUserAccess';
  /* MAINTAIN MASTER */
  public static getUserDetailsForMasters = 'GetUserDetails?code=sPRP0RWkx-IUHRngBEIm3gEalegSOTk_8tavs7U6GtLQAzFud9Yb6w==';
  public static maintainMasterData = 'GetAllMasters?code=em9T-WIWw_GwUugHyb1TZZnMKf6w3pmFomlB8H7e6EreAzFuxWp66A==';
  public static getMasterById = 'GetMasterByMasterID?code=p_-eJXauD_08GZhDKsg1nRmw7DpFzar8EMthgv319EBgAzFuEMmYbg==';
  public static createMasterByMasterID = 'CreateMasterByMasterID?code=i17OE5GGH8RMmPNLxJgqclazwYD6AjlZLYjsq8dRmndLAzFukh9sDQ==';
  public static updateMasterByMasterID = 'UpdateMasterByMasterID?code=ofMc-ZssNaOJUqAe8Wj64wSx5loxr5ymDxzzxSel4qD1AzFuhODNYQ==';
  public static exportMasterByMasterID = 'ExportMasterByMasterID';
  public static getTaggedResources = '/RouterService/SSA/SSADATASERVICE/ROUTING/FetchCandidate_SupplyTaggedResources';
  public static getMasterGridRowData = 'GetMasterGridRowData';

  /** BULK UPLOAD */
  public static getTemplateList = 'RouterService/UPLOAD/BULKUPLOAD/ROUTING/TEMPLATELIST';
  public static getTemplateListById = 'RouterService/UPLOAD/BULKUPLOAD/ROUTING/TEMPLATELISTBYID';
  public static uploadExcelData = 'RouterService/UPLOAD/BULKUPLOAD/ROUTING/UPLOADEXCELDATA';
  public static downloadtemplate = 'RouterService/UPLOAD/BULKUPLOAD/ROUTING/EXPORT';
  public static submitData = 'RouterService/UPLOAD/BULKUPLOAD/ROUTING/IMPORTDATA';
  public static uploadExcelLogs = 'RouterService/UPLOAD/BULKUPLOAD/ROUTING/UPLOADEXCELLOG';
  public static mailData = 'RouterService/UPLOAD/BULKUPLOAD/ROUTING/GENERATEUPLOADMAIL';
  public static requisitionTrigger = 'RouterService/UPLOAD/BULKUPLOAD/ROUTING/REQUISITIONTRIGGER';
  public static updateRequisitionTrigger = 'RouterService/UPLOAD/BULKUPLOAD/ROUTING/UPDATEREQUISITIONTRIGGER';
  public static createDemandTrigger = 'RouterService/DEMAND/CREATE_DEMAND/DEMANDTRIGGER/CREATEDEMANDTRIGGER';
  public static uploadBenchSR = 'BulkUploadWrapperWA/UploadBenchSR';
  public static uploadProjectSR = 'BulkUploadWrapperWA/UploadProjectSR';
  public static UploadBenchSRUpdate = 'BulkUploadWrapperWA/UploadBenchSRUpdate';
  public static UploadUpdateProjectSR = 'BulkUploadWrapperWA/UploadUpdateProjectSR';
  public static UploadSchedulingCommitment = 'BulkUploadWrapperWA/UploadSchedulingCommitment';
  public static validateChargeWBSE = 'RouterService/EXTERNAL/MRDRWBSE/GET/GETMRDRWBSE';
  public static exportGenericUploadDataURL = 'RouterService/UPLOAD/BULKUPLOAD/ROUTING/EXPORTDATA';
  public static UploadERollOffRequest = 'BulkUploadWrapperWA/UploadERollOffRequest';
  public static UploadRRDDetails = 'BulkUploadWrapperWA/UploadRRDDetails';
  public static UploadDemandCreation = 'BulkUploadWrapperWA/UploadDemandCreation';
  public static uploadBulkSRWithdraw = 'BulkUploadWrapperWA/UploadBulkSRWithdraw';
  public static UploadTechAssessmentUpdate = 'BulkUploadWrapperWA/UploadTechAssessmentUpdate';
  public static UploadDemandStatus = 'BulkUploadWrapperWA/UploadDemandStatus';
  public static reUploadFailedReqRecords = 'BulkUploadWrapperWA/ReUploadFailedReqRecords';
  public static reUploadFailedDemandReqRecords = 'BulkUploadWrapperWA/ReUploadFailedDemandRecords';
  public static reUploadFailedErollOffReqRecords = 'BulkUploadWrapperWA/ReUploadFailedERollOffRecords';
  public static reUploadFailedResourceReqRecords = 'BulkUploadWrapperWA/ReUploadFailedResourceRecords';
  public static UploadTRFlag = 'BulkUploadWrapperWA/UploadTRFlag';
  public static reUploadFailedNominationRecords = 'BulkUploadWrapperWA/ReUploadFailedNominationRecords';
  public static UploadNomination = 'BulkUploadWrapperWA/UploadNomination';
  public static UploadPRRUpdate = 'BulkUploadWrapperWA/UploadPRRData';
  /** Tracker Screen */
  public static getTrackerData = 'BulkUploadWrapperWA/InitialGridDataLoad';
  public static downloadData = 'BulkUploadWrapperWA/ExportData';
  public static getTrackerErrorData = 'BulkUploadWrapperWA/DisplayErrorValidations';
  public static refreshUploadStatus = 'BulkUploadWrapperWA/RefreshUploadStatus';

  public static getAddSupply = 'RouterService/SSA/SSADATASERVICE/ROUTING/FetchChooseSupplyData';
  public static getDemandDetails = 'RouterService/SSA/SSADATASERVICE/ROUTING/GetDemandDetails';
  public static getTransferMasterData = 'RouterService/TRANSFER/TRANSFERSERVICE/ROUTING/GetTransferMasterData';
  public static getSwapAutocomplete = 'RouterService/LOCKING/LOCKDASHBOARD/ROUTING/SwapReport_AutoComp';
  public static getIDCWBS = 'RouterService/TRANSFER/TRANSFERSERVICE/ROUTING/GetIDCWBS';

  public static getTest = 'RouterService/TRANSFER/TRANSFERSERVICE/ROUTING/Test';
  public static submitApproval = 'RouterService/TRANSFER/TRANSFERSERVICE/ROUTING/CreateTIR';
  public static ValidateProjWBSE = 'RouterService/TRANSFER/TRANSFERSERVICE/ROUTING/ValidateMRDRWBSe';
  public static demandDETAILSEDITSELECT = 'RouterService/DEMAND/GET_DEMAND/GET/DEMANDDETAILSEDITSELECT';
  public static fetchFlexLevel = 'RouterService/SSA/SSADATASERVICE/ROUTING/FetchFlexLevel';
  public static getWorkerInstanceURL = 'RouterService/EXTERNAL/WORKDAYTALENTCARD/GET/GETAZUREADWORKDAYTALENTTOKEN';

  public static resubmitWarningReqRecords = 'BulkUploadWrapperWA/ResubmitWarningReqRecords';
  public static resubmitDemandWarningReqRecords = 'BulkUploadWrapperWA/ResubmitWarningDemandRecords';

  /*Talent Details*/
  public static loadPersonalResult = 'LoadPersonalResult';
  public static savePersonalResult = 'SavePersonalResult';
  public static employeeDemographics = 'GetEmployeeDemographics';
  public static updateEmployeeDemographics = 'UpdateEmployeeDemographics';
  public static fetchRejectionData = 'GetResourceRejectionDetails';
  public static deleteRejectionsData = 'DeleteRejectionData';
  public static fetchNonSchedulableDetails = 'FetchNonSchedulableDetails';
  public static fetchNonSchedulableTypeData = 'FetchNonSchedulableTypeData';
  public static saveNonSchedulableDetails = 'SaveNonSchedulableDetails';
  public static deleteNonSchedulableTypeData = 'DeleteNonSchedulableTypeData';
  public static transferDetails = 'TransferDetails';
  public static getSharedLockHistory = 'GetSharedLockHistory';
  public static lockTrailDetails = 'LockTrailDetails';
  public static getLockHistory = 'GetLockHistory';
  public static getlocationHistory = 'FetchLocationHistoryDetails';
  public static getNotReachableDetails = 'GetNotReachableDetails';
  public static notReachableDelete = 'NotReachableDelete';
  public static saveNotReachableEditDetails = 'SaveNotReachableEditDetails';
  public static saveVisaRequirementsResult = 'SaveVisaRequirementsResult';
  public static fetchVisaDetails = 'FetchVisaDetails';
  public static fetchRenegeDetails = 'FetchRenege_OfferWithdrawn_Reason';
  public static renegeOfferWithrawn = 'Renege_OfferWithrawn';
  public static markResourceOnboard = 'MarkResourceOnboard';
  public static viewRefusalValidationDetails = 'ViewRefusalValidationDetails';
  public static getResourceRefusalDetails = 'GetResourceRefusalDetails';


  public static hrRelated = 'HrRelated';
  public static updatehrRelated = 'UpdateHrRelated';
  public static getManagementLevelAPI = 'GetManagementLevelAPI';

  // SHARED LOCK
  public static getSharedLockRequest = 'RouterService/CORELOCKING/SHARED_LOCK/ROUTING/GETSHAREDLOCKREQUESTS';
  public static getsharedLocksCounts = 'RouterService/CORELOCKING/SHARED_LOCK/ROUTING/GETSHAREDLOCKREQUESTSCOUNT';
  public static getEmployeeDetails = 'RouterService/CORELOCKING/SHARED_LOCK/ROUTING/GETEMPLOYEEDETAILS';
  public static getSelectDemandDetails = 'RouterService/DEMAND/GET_DEMAND/GET/DEMANDDETAILSEDITSELECT';
  public static getSharedResourcesForGrid = 'RouterService/CORELOCKING/SHARED_LOCK/ROUTING/GetSharedResourcesForGrid';
  public static approveSharedLockRequest = 'RouterService/CORELOCKING/SHARED_LOCK/ROUTING/APPROVESHAREDLOCKREQUEST';
  public static getResourceDetailChooseSupply = 'RouterService/SOFTLOCK/MasterData/ROUTING/GetResourceDetails_ChooseSupply';
  public static rejectSharedLockRequest = 'RouterService/CORELOCKING/SHARED_LOCK/ROUTING/REJECTSHAREDLOCKREQUEST';
  public static unAssignedSharedLockRequest = 'RouterService/CORELOCKING/SHARED_LOCK/ROUTING/UNASSIGNSHAREDRESOURCE';

  //CREATE DEMAND
  public static segmentDemandSearch = 'Demand/SegmentDemandSearch';
  public static getSkillDetails = 'Demand/GetSkillDetails';
  public static getYrsOfExpMapping = 'Demand/GetLevelDetails';
  public static basicMasterData = 'Demand/MASTERDATA';
  public static getEnterpriseID = 'Demand/GetEnterpriseID';
  public static getSkillDetailsById = 'Demand/GetSkillDetailsById';
  public static riskIndicator = 'Demand/Risk_Indicator';
  public static gcpCountry = 'Demand/GetGcpCountry';
  public static getGuProject = 'Demand/GetCounterProject';
  public static getProjectsListByUser = 'Demand/GetProjDetailsbyUser';
  public static getDemandSegmentation = 'Demand/GetDemandSegmentation';
  public static getSubCategory = 'Demand/MASTERDATA_CONDITION';
  public static getCopyDemandData = 'Demand/GetCopyDemandData';
  public static getCopyDemandEnData = 'Demand/GetCopyDemandEnData';
  public static fetchLeadTime = 'RouterService/SSA/SSADATASERVICE/ROUTING/FetchLeadTime';
  public static fetchFlexLevelData = 'RouterService/SSA/SSADATASERVICE/ROUTING/FetchFlexLevel';
  public static fetchPopularDemandTemplate = 'RouterService/SSA/SSADATASERVICE/ROUTING/FetchPopularDemandTemplate';
  public static fetchAvailableSupplyData = 'RouterService/SSA/SSADATASERVICE/ROUTING/AvaialableSupplyData';
  public static fetchResourcePyramid = 'RouterService/SSA/SSADATASERVICE/ROUTING/FetchResourcePyramid';
  public static getRRDDemandOwner = 'RouterService/SOFTLOCK/DemandDetails/ROUTING/GetRRDDemandOwner';
  public static iJPExclusion = 'Demand/IJPExclusion';
  public static getSegmentToolTip = 'Demand/GetToolTipDetails';
  public static validateWBSE = 'RouterService/EXTERNAL/MRDRWBSE/GET/GETMRDRWBSE';
  public static createDemand = 'Demand/createdemand';
  public static transferTrailDetails = 'FetchTransferTrailDetails';
  public static updateSharedLockRollOff = 'RouterService/CORELOCKING/SHARED_LOCK/ROUTING/UpdateSharedLockRollOff';
  public static getSkillProximity = 'RouterService/SSA/SSADATASERVICE/ROUTING/FetchSkillOntology';
  public static getExpertRoleData = 'Demand/GETEXPERTPROJECTROLEDETAILS';
  public static getGcpCountry = 'Demand/GetGcpCountry';
  public static getFacilityDecommissionDetails = 'Demand/GetFacilityDecommissionDetails';
  public static getMiChartData = 'RouterService/SSA/SSADATASERVICE/ROUTING/GetMiChartData';
  public static getJobDescription = 'Demand/GetOpenAIPromptInputData';
  public static insertOpenAIPromptInputData = '/Demand/InsertOpenAIPromptInputData';
  public static updateEmployeeDemographicsNew = 'UpdateEmployeeDemographicsNew';
  public static saveFeedback = 'FeedbackSubmission';
  public static getTalentEvaluatorDetails = 'DemandWA/GetTalentEvaluatorDetails';
  // Search SR
  public static getWithdrawOptions = 'RouterService/SR/REQUISITIONSERVICE/ROUTING/SearchSelectCriteria?RequestText=&Type=WDR';
  public static fetchPersonalisedColumnData = 'RequisitionWA/FetchPersonalisedColumnData';
  public static fetchSRGridAndFilterData = 'RequisitionWA/FetchSRGridAndFilterData';
  public static savePersonalisedColumnData = 'RequisitionWA/SavePersonalisedColumnData';
  public static saveWithdraw = 'RouterService/SR/REQUISITIONSERVICE/ROUTING/Withdraw';

  // PROJECT
  public static fetchProject = 'ProjectWA/FetchProject';
  public static approveRejectProject = 'ProjectWA/ShareProjectApproveReject';
  public static getProjectDetail = 'ProjectWA/FillProjectEditDetails';
  public static saveProjectDetail = 'ProjectWA/ProjectEditDetailsSave';
  public static getAutoCompleteTFS = 'ProjectWA/AutoCompleteTFS';
  public static fetchProjectPersonalisedColumnData = 'ProjectWA/FetchPersonalizedColumnData';
  public static saveProjectPersonalisedColumnData = 'ProjectWA/SavePersonalizedColumnData';

  // DMAND - RFF FLOW
  public static getCopyRFFData = 'Demand/GetCopyRFFData';

}
