<div mat-dialog-header class="py-2 py-lg-0">
    <button mat-dialog-close aria-label="Close popup" class="smart-icon-close btn-close"></button>
    <h1 mat-dialog-title class="mb-2">Schedule Task</h1>
</div>
<form *ngIf="scheduleTaskFormGroup" [formGroup]="scheduleTaskFormGroup">
    <mat-dialog-content class="custom-overflow-scroll schedule-task-content">
        <div class="d-block invalid-feedback mt-0 mb-4">
            Fields marked with * are mandatory
        </div>
        <div class="row mb-3">
            <div class="col-sm-6">
                <label for="taskNameInput" class="form-label mb-1">Task Name</label>
                <input type="text" class="form-control" id="taskNameInput" formControlName="taskName">
            </div>
        </div>
        <div class="mb-3">
            <label for="taskDescription" class="form-label mb-1">Task Description</label>
            <textarea class="form-control" id="taskDescription" formControlName="taskDescription" rows="1"></textarea>
        </div>
        <div class="mb-3">
            <label class="form-label mb-1 d-block" id="scheduleGroupLabel">Schedule Task</label>
            <mat-radio-group aria-labelledby="scheduleGroupLabel" formControlName="scheduleTaskOption"
                (change)="onScheduleChange($event)">
                <mat-radio-button [value]=frequency.oneTime>One Time</mat-radio-button>
                <mat-radio-button [value]=frequency.daily>Daily</mat-radio-button>
                <mat-radio-button [value]=frequency.weekly>Weekly</mat-radio-button>
                <mat-radio-button [value]=frequency.monthly>Monthly</mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="mb-3" *ngIf="scheduleTaskCheck([frequency.weekly])">
            <div class="mat-checkbox-group">
                <ng-container *ngFor="let weekday of weekdays;" class="checkbox">
                    <mat-checkbox [checked]="checkboxWeekDaySelection(weekday)"
                        (change)="onWeekdayChange($event.checked,weekday)" type="checkbox">
                        {{weekday.substring(0,3)}}
                    </mat-checkbox>
                </ng-container>
            </div>
        </div>
        <div class="row g-3 schedule-task-content">
            <div class="col-auto" *ngIf="scheduleTaskCheck([frequency.daily, frequency.monthly])">
                <label for="occursEvery" class="form-label mb-1">Occurs Every</label>
                <ng-select [clearable]="false" class="smart-ng-select filter-dropdown-selection" [virtualScroll]="true"
                    appendTo="body" labelForId="occursEvery" formControlName="occursEvery"
                    placeholder="Select Occurrence" appNgSelect="Occurs Every">
                    <ng-option *ngFor="let item of [].constructor(occurrenceCount);let i=index;" [value]="i+1">
                        {{scheduleTaskCheck([frequency.monthly])? (i+1)+nth(i+1)+" Day":(i+1)+"
                        Day(s)"}}
                    </ng-option>
                </ng-select>
            </div>
            <div class="col-auto">
                <label for="selectStartDateRange" class="form-label mb-1">Start Date <span
                        class="mandatory-field lh-1">*</span></label>
                <div class="date-picker-container position-relative">
                    <input id="selectStartDateRange" class="form-control smart-form-control" matInput
                        [min]="minFromDate" [matDatepicker]="pickerStartDate" (click)="pickerStartDate.open()"
                        (dateChange)="startTimeValidation()" formControlName="selectedFromDate">
                    <span class="smart-icon-calendar position-absolute" (click)="pickerStartDate.open()"></span>
                    <mat-datepicker #pickerStartDate></mat-datepicker>
                </div>
            </div>
            <div class="col-auto ps-md-1 ps-sm-0" *ngIf="!scheduleTaskCheck([frequency.oneTime])">
                <label for="selectEndDateRange" class="form-label mb-1 ">End Date</label>
                <div class="date-picker-container position-relative">
                    <input id="selectEndDateRange" class="form-control smart-form-control" matInput [min]="minToDate"
                        [matDatepicker]="pickerEndDate" (click)="pickerEndDate.open()" formControlName="selectedToDate">
                    <span class="smart-icon-calendar position-absolute" (click)="pickerEndDate.open()"></span>
                    <mat-datepicker #pickerEndDate></mat-datepicker>
                </div>
            </div>
            <div class="col-auto">
                <label for="startTime" class="form-label mb-1" id="statTimeLabel">Start Time <span
                        class="mandatory-field lh-1">*</span></label>
                <div class="timepicker d-flex align-items-center position-relative">
                    <ng-select labelForId="startTime" [clearable]="false" placeholder="HH"
                        class="smart-ng-select filter-dropdown-selection timepicker-select" [items]="hours"
                        formControlName="startTime" appendTo="body" appNgSelect="hours"
                        (change)="startTimeValidation()">
                    </ng-select>
                    <span>:</span>
                    <label for="startMinute" class="visually-hidden">Start Minute</label>
                    <ng-select labelForId="startMinute" [clearable]="false" placeholder="MM"
                        class="smart-ng-select filter-dropdown-selection timepicker-select" [items]="minutes"
                        formControlName="startMinute" appendTo="body" appNgSelect="minutes"
                        (change)="startTimeValidation()">
                    </ng-select>
                    <span class="smart-icon-timer-clock timepicker-icon pe-2"></span>
                </div>
            </div>
        </div>
        <span class="d-block invalid-feedback validation-text mt-2"
            *ngIf="scheduleTaskFormGroup.controls['selectedToDate']?.hasError('matDatepickerMin')">
            The schedule's End Date cannot be before it's Start Date.
        </span>
        <span class="d-block invalid-feedback validation-text mt-2"
            *ngIf="scheduleTaskFormGroup.controls['startTime']?.errors?.['invalid']">
            Start Time should be greater than the Current Time
        </span>
        <span class="d-block invalid-feedback validation-text mt-2"
            *ngIf="scheduleTaskFormGroup.controls['selectedFromDate']?.hasError('matDatepickerMin')">
            Start Date should be greater than the Current Date
        </span>
        <span class="d-block invalid-feedback validation-text mt-2"
            *ngIf="scheduleTaskFormGroup.controls['selectedFromDate']?.value === null">
            Start Date cannot be blank.
        </span>
    </mat-dialog-content>
    <div mat-dialog-actions class="justify-content-end">
        <button class="btn btn-link text-uppercase text-decoration-none me-3 smart-btn smart-btn-link"
            (click)="setForm()">Reset</button>
        <button class="btn smart-btn smart-primary-btn text-uppercase" [disabled]="!scheduleTaskFormGroup.valid"
            (click)="onFormSubmit()">Schedule</button>
    </div>
</form>