import { Component, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { filter, Subject, Subscription, takeUntil } from 'rxjs';
import { CommonService } from './core/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivationStart, NavigationStart } from '@angular/router';
import { environment } from 'src/environments/environment';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { Store } from '@ngrx/store';
import * as sharedActions from 'src/app/store/actions/shared.actions';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'demandsupply-2109';
  private readonly _destroying$ = new Subject<void>();
  hideHeader: boolean;
  subscription: Subscription | undefined;
  isAccess: boolean | undefined;
  isUserLoggedIn: boolean;
  isNewUserReqPending: boolean | undefined;
  userDetailResponse: any;
  isLoginFailed: boolean | undefined;
  ldFlags: { key: string; value: any; }[] = [];
  isRaiseRequest: boolean;

  constructor(private authService: MsalService, private router: Router, private broadcastService: MsalBroadcastService,
    private commonService: CommonService, public dialog: MatDialog,
    private readonly store: Store) {
    this.hideHeader = false;
    this.isUserLoggedIn = false;
    this.isRaiseRequest = false;
  }
  ngOnInit(): void {
    this.authService.handleRedirectObservable().subscribe({
      next: (result: AuthenticationResult) => {
        if (result) {
          this.authService.instance.setActiveAccount(result.account);
        }
        // Sets login display when using redirects
        this.authService.instance.setActiveAccount(this.authService.instance.getAllAccounts()[0]);
        this.initUserAuthorization();
      },
      error: (error) => console.log(error)
    });
    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe(() => {
        // console.log(result);
      });
    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        // console.log(this.authService.instance.getAllAccounts()[0])
      }
      );
    this.subscription = this.commonService.hideHeaderObservable.subscribe(val => this.hideHeader = val);

    this.router.events.subscribe((event) => {
      const env: any = environment;

      if (event instanceof NavigationStart) {
        if (this.isNewUserReqPending && event.url.split('?')[0] !== '/my-access' && event.url.split('/')[2] !== 'raise-access') {
          this.router.navigate(['/my-access']);
        } else if (env.env !== 'local' && this.isAccess && event.url === '/') {
          window.location.href = env.redirectOldUrl;
        }
      }
      if (event instanceof ActivationStart) {
        if (env.env !== 'local' && event && event.snapshot && event.snapshot.data && event.snapshot.data['isFeatureDisabled']) {
          this.router.navigateByUrl('');
        }
      }
    });
  }

  initUserAuthorization(): void {
    const username = this.authService.instance.getAllAccounts()[0].username;
    const enterpriseID = username.split('@')[0];
    sessionStorage.setItem('userId', enterpriseID);
    this.setLDClientFlags(enterpriseID, username);
    const param = {
      'EnterpriseId': enterpriseID
    };
    const userAccessConfig = {
      parameter: param,
      environmentVariable: 'accessManagementAPIUrl',
      constantVariable: 'getUserDetails'
    };
    this.commonService.postApi(userAccessConfig).subscribe((res: any) => {
      this.userDetailResponse = res.uGDetails;
      if (res && res.success && this.userDetailResponse) {
        this.isUserLoggedIn = true;
        if (this.userDetailResponse.aU_ID > 0) {
          this.isAccess = true;
          if (this.userDetailResponse.isDefaultUGRole_Incorrect) {
            this.isNewUserReqPending = true;
            this.router.navigate(['/my-access']);
          }

        } else if (this.userDetailResponse.isNewUser && this.userDetailResponse.isRequestPending) {
          this.isAccess = true;
          this.isNewUserReqPending = true;
          this.router.navigate(['/my-access']);
        } else {
          this.checkLdFlag('Raise_Access_Request_1st_login') ? this.isAccess = false : window.location.href = environment.redirectOldUrl;
        }
        this.store.dispatch(sharedActions.updateUserDetails(this.userDetailResponse));
      } else {
        this.isLoginFailed = true;
      }
    }, (error) => {
      this.isLoginFailed = true;
      console.log('initUserAuthorization Error: ', error);
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
    this.subscription?.unsubscribe();
  }
  scroll(el: HTMLElement): void {
    el.scrollIntoView({ behavior: 'smooth' });
    el.focus();
  }
  raiseRequestEvent(event: any): void {
    if (event && event.success) {
      const data = this.userDetailResponse;
      data.isRequestPending = true;
      this.store.dispatch(sharedActions.updateUserDetails(this.userDetailResponse));
      this.isAccess = true;
      this.isNewUserReqPending = true;
      this.router.navigate(['/my-access'], { queryParams: { message: event.message }, skipLocationChange: true });
    } else {
      this.isRaiseRequest = false;
    }
  }
  raiseRequest(): void {
    this.isRaiseRequest = true;
  }
  setLDClientFlags(userId: string, uName: string): void {
    const userName = uName.includes(',') ? uName.split(',').reverse().join(' ') : uName;
    const user = {
      key: userId + '@accenture.com',
      firstName: userName, lastName: '',
      email: userId + '@accenture.com',
      custom: { groups: ['Google', 'Microsoft'] }
    };

    const ldclient = LDClient.initialize(environment.ldcClientId, user);
    ldclient.on('ready', () => {
      const allFlags = ldclient.allFlags();
      Object.entries(allFlags).forEach(([key, value]) => {
        if (value) {
          this.ldFlags.push({ key: key, value: value });
        }
      });
      this.store.dispatch(sharedActions.updateLDClientFlags({ data: this.ldFlags }));
    });
  }
  checkLdFlag(flag: string): boolean {
    return (this.ldFlags && this.ldFlags.length > 0) ? this.ldFlags.findIndex((t: any) => t.key === flag) > -1 : false;
  }
  checkAccessFlag(): boolean {
    let isAccessFlag = false;
    if (this.isAccess) {
      isAccessFlag = true;
    } else if (!this.isAccess && this.isRaiseRequest) {
      isAccessFlag = true;
    }
    return isAccessFlag;
  }
}
