export const MyAccess = {
  breadcrumbData: [
    { isActive: false, url: '#', value: 'Home' },
    { isActive: true, url: '#', value: 'My Access' }
  ],
  columnDef: [
    {
      key: 'accessRequestID',
      title: 'ACCESS REQUEST ID',
      isSortable: true
    },
    {
      key: 'userGroup',
      title: 'TEAM (USER GROUP)',
      isSortable: true
    },
    {
      key: 'acessReqUserGroupMemId',
      title: 'SUB-TEAM(USER GROUP MEMBER)',
      isSortable: true
    },
    {
      key: 'role',
      title: 'ROLE',
      isSortable: true
    },
    {
      key: 'approverName',
      title: 'APPROVERS',
      isSortable: true
    },
    {
      displayType: 'STATUS',
      key: 'currentStatus',
      title: 'STATUS',
      isSortable: false,
      filterable: true,
      filterOptions: ['Pending', 'Approved', 'Rejected', 'Deleted']
    },
    {
      displayType: 'DELETE',
      key: 'DELETE',
      title: '',
      isSortable: false
    }
  ],

  myAccessColumnDef: [
    {
      key: 'setDefault', title: 'SET DEFAULT', isSortable: false,
      displayType: 'RADIO', cellHeaderClass: 'text-center', cellDataClass: 'text-center'
    },
    { key: 'userGroupAcronym', title: 'TEAM', isSortable: true },
    { key: 'subteamUserGroup', title: 'SUB-TEAM', isSortable: true },
    { key: 'roleAcronym', title: 'ROLE', isSortable: true }
  ],

  pendingColumnDef: [
    { key: 'accessRequestID', title: 'ACCESS REQUEST ID', isSortable: true },
    { key: 'userGroupAcronym', title: 'TEAM', isSortable: true },
    { key: 'acessReqUserGroupMemId', title: 'SUB-TEAM', isSortable: true },
    { key: 'roleAcronym', title: 'ROLE', isSortable: true },
    { key: 'approverName', title: 'APPROVERS', isSortable: true },
    { key: 'trainingYN', title: 'TRAINING STATUS', isSortable: true },
    {
      key: 'action', title: 'DELETE ACCESS REQUEST', isSortable: false, displayType: 'DELETE',
      cellHeaderClass: 'text-center', cellDataClass: 'text-center'
    }
  ],

  approvedColumnDef: [
    { key: 'accessRequestID', title: 'ACCESS REQUEST ID', isSortable: true },
    { key: 'userGroupAcronym', title: 'TEAM', isSortable: true },
    { key: 'acessReqUserGroupMemId', title: 'SUB-TEAM', isSortable: true },
    { key: 'roleAcronym', title: 'ROLE', isSortable: true },
    { key: 'approverName', title: 'APPROVERS', isSortable: true },
    { key: 'validityEndDate', title: 'EXPIRING ON', isSortable: true, fixedWidth: '145px' },
    { key: 'trainingYN', title: 'TRAINING STATUS', isSortable: true },
    {
      key: 'isRequestRaised', title: 'EXTEND VALIDITY', isSortable: false, displayType: 'CHECKBOX',
      cellHeaderClass: 'text-center ps-2 table-header-action', cellDataClass: 'text-center'
    }
  ],
  rejectedColumnDef: [
    { key: 'accessRequestID', title: 'ACCESS REQUEST ID', isSortable: true },
    { key: 'userGroupAcronym', title: 'TEAM', isSortable: true },
    { key: 'acessReqUserGroupMemId', title: 'SUB-TEAM', isSortable: true },
    { key: 'roleAcronym', title: 'ROLE', isSortable: true },
    { key: 'approverName', title: 'APPROVERS', isSortable: true },
    { key: 'rejectedOn', title: 'REJECTED ON', isSortable: true, displayType: 'DATE_FORMAT' },
    { key: 'trainingYN', title: 'TRAINING STATUS', isSortable: true },
  ],
  deletedColumnDef: [
    { key: 'accessRequestID', title: 'ACCESS REQUEST ID', isSortable: true },
    { key: 'userGroupAcronym', title: 'TEAM', isSortable: true },
    { key: 'acessReqUserGroupMemId', title: 'SUB-TEAM', isSortable: true },
    { key: 'roleAcronym', title: 'ROLE', isSortable: true },
    { key: 'approverName', title: 'APPROVERS', isSortable: true },
    { key: 'deletedOn', title: 'DELETED ON', isSortable: true, displayType: 'DATE_FORMAT' },
    { key: 'trainingYN', title: 'TRAINING STATUS', isSortable: true }
  ],

  extendValidityColumnDef: [
    {
      key: 'isRequestRaised', title: '',
      isSortable: false, displayType: 'CHECKBOX', cellHeaderClass: 'text-center', cellDataClass: 'text-center'
    },
    { key: 'teamUserGroup', title: 'TEAM (USER GROUP)', isSortable: true },
    { key: 'subteamUserGroup', title: 'SUB-TEAM (USER GROUP MEMBER)', isSortable: true },
    { key: 'role', title: 'ROLE', isSortable: true },
    { key: 'userGroupMemberEndDate', title: 'END DATE', isSortable: true, displayType: 'DATE_FORMAT' },
    { key: 'dateNoOfDays', title: 'EXPIRING IN', isSortable: true, displayType: 'EXPIRIY' }
  ],
  statusList: [
    { key: 0, value: 'Pending' }, { key: 1, value: 'Approved' }, { key: 2, value: 'Rejected' }, { key: 3, value: 'Deleted' }
  ]
};

export enum Status {
  Pending = 0,
  Approved = 1,
  Rejected = 2,
  Deleted = 3
}

export enum RaiseRequest {
  Role = 0,
  Team = 1,
  Subteam = 2,
  AccessReason = 3
}

