<div class="personalization-modal-container">
    <div mat-dialog-header class="mat-dialog-header d-flex justify-content-between border-bottom pe-0">
        <span mat-dialog-title class="mb-0 align-self-center">{{ this.checkboxGroup?.value.checkboxesValues ===
            undefined || this.checkboxGroup?.value.checkboxesValues === null ? 0 :
            this.checkboxGroup?.value?.checkboxesValues.length}} out
            of {{getCount()}} selected</span>
        <button mat-dialog-close aria-label="Personalize Result"
            class="btn smart-btn smart-btn-link d-flex align-items-center px-3">
            <span class="smart-icon-gear me-2"></span>Personalize Result</button>
    </div>
    <mat-dialog-content class="schedule-task-content m-0 p-0">
        <form [formGroup]="checkboxGroup" *ngIf="checkboxGroup">
            <div
                class="custom-overflow-scroll d-flex flex-wrap flex-column grid-list text-capitalize mat-dialog-block mt-2" #scrollContainer >
                <ng-container *ngFor="let item of data.columnDef; let i = index;">
                    <div class="grid-item mb-2 px-1" *ngIf="!item.isHidePersonalized" formArrayName="checkboxes">
                        <mat-checkbox [value]="item.key" [formControlName]="i" (change)="onCheckboxChange($event)"
                            type="checkbox">
                            {{item.title}}
                        </mat-checkbox>
                    </div>
                </ng-container>

            </div>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions class="justify-content-between border-top mb-0">
        <mat-checkbox [(ngModel)]="setDefault">Set Default</mat-checkbox>
        <div>
            <button class="btn btn-link text-uppercase text-decoration-none me-3 smart-btn smart-btn-link"
                mat-dialog-close>Cancel</button>
            <button class="btn btn-link text-uppercase text-decoration-none me-3 smart-btn smart-btn-link"
                (click)="resetForm()">Reset</button>
            <button cdkFocusInitial class="btn smart-btn smart-primary-btn text-uppercase" mat-dialog-close
                (click)="applyForm()" [disabled]="this.checkboxGroup?.value.checkboxesValues === undefined || 
                (this.checkboxGroup?.value.checkboxesValues !== null &&
                (this.checkboxGroup?.value?.checkboxesValues.length < 1 || this.checkboxGroup?.value?.checkboxesValues.length > this.maxCheckboxCount))
                ">Apply</button>
        </div>
    </mat-dialog-actions>
</div>