import { APP_INITIALIZER, ModuleWithProviders, NgModule, Provider } from '@angular/core';
import {
  MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor,
  MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG
} from '@azure/msal-angular';
import { environment, protectedResourceMap } from 'src/environments/environment';
import {
  BrowserAuthOptions, BrowserCacheLocation, InteractionType,
  IPublicClientApplication, LogLevel, PublicClientApplication
} from '@azure/msal-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppConfigService } from '../services/app-config.service';

export function loggerCallback(logLevel: LogLevel, message: string): any {
  return logLevel || message ? true : false;
}

export function MSALInstanceFactory(): IPublicClientApplication {
  const msal = environment.msalConfig;
  let msalAuth;
  if (msal) {
    msalAuth = msal['auth'];
  } else {
    console.error('Configure msal in ' + 'AppConfigService.configPath');
  }
  return new PublicClientApplication({
    auth: msalAuth as BrowserAuthOptions,
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: true
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const resourceMap = new Map(
    protectedResourceMap as Iterable<readonly [string, string[]]>
  );

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: resourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      // scopes: ['user.read'],
      scopes: [environment.msalConfig.auth.clientId + '/.default', 'openid']
    },
    loginFailedRoute: '/login-failed'
  };
}

export const PROVIDERS = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true,
  },
  {
    provide: MSAL_INSTANCE,
    useFactory: MSALInstanceFactory
  },
  {
    provide: MSAL_GUARD_CONFIG,
    useFactory: MSALGuardConfigFactory,
  },
  {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useFactory: MSALInterceptorConfigFactory,
  },
  { // extra in our app
    provide: APP_INITIALIZER,
    useFactory: (config: AppConfigService) => () => config.load(),
    deps: [AppConfigService],
    multi: true
  },
  MsalService,
  MsalGuard,
  MsalBroadcastService, // extra in auth module
] as Provider[];

@NgModule({
  imports: [MsalModule],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: PROVIDERS,
    };
  }
}
