import { Injectable, ErrorHandler } from '@angular/core';
import { LoggerService } from './logger.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {

  constructor(private logger: LoggerService) {
  }

  handleError(error: any): void {
    if (environment.env === 'stage' || environment.env === 'prod') {
      this.logger.error(error);
    } else {
      console.error('ERROR LOG : ', error);
    }
  }
}
