import { Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  public initialized = false;
  constructor() {
    if (environment.env === 'stage' || environment.env === 'prod') {
      datadogRum.init({
        clientToken: environment.dataDogConfig.clientToken,
        site: environment.dataDogConfig.site,
        service: environment.dataDogConfig.service,
        applicationId: environment.dataDogConfig.applicationId,
        sampleRate: 100,
        trackInteractions: true
      });
      this.initialized = true;
    }
  }

  public error(message: string, context?: { [x: string]: any }): void {
    if (this.initialized) {
      datadogRum.addError(message, context);
    } else {
      console.error('Error: ', message);
    }
  }
}
