<ng-container *ngIf="isUserLoggedIn;else loginLoader">

  <button type="button" role="link" class="skip-main d-block position-absolute" (click)="scroll(main)">Skip to main
    content</button>
  <app-header *ngIf="!hideHeader && checkAccessFlag()"></app-header>

  <div role="main" class="main-container" #main tabindex="-1" [ngSwitch]="isAccess">
    <ng-container *ngSwitchCase="true">
      <router-outlet></router-outlet>
    </ng-container>

    <ng-container *ngSwitchCase="false">
      <app-access-request (emitRaiseRequest)="raiseRequestEvent($event)"
        *ngIf="isRaiseRequest; else noAccess"></app-access-request>
      <ng-template #noAccess>
        <div
          class="smart-container noaccess-container d-flex flex-column flex-md-row min-vh-100 text-center text-md-start align-items-center justify-content-center py-3">
          <div class="container-1 order-1 order-md-0">
            <h1 class="label-heading1 graphik-semi-bold mt-4 mt-md-0">
              SMART is a digital ecosystem that empowers business talent by deploying best-in-class people at the
              right time
            </h1>
            <p class="label-heading2 graphik-regular mt-4 pt-lg-3 pb-lg-3">
              To access this application, raise a request here.
            </p>
            <button type="button" id="btnRequest"
              class="btn smart-primary-btn btn-request graphik-regular px-4 py-2 text-white mt-3"
              (click)="raiseRequest()">RAISE ACCESS REQUEST</button>
          </div>
          <div class="container-2 order-0 order-md-1">
            <img class="img-fluid" src="assets/images/new-user.png" alt="new user request">
          </div>
        </div>
      </ng-template>
    </ng-container>
  </div>
</ng-container>

<ng-template #loginLoader>
  <div *ngIf="!isLoginFailed;else loginFailed" class="spinner-border text-secondary d-flex mx-auto mt-5" role="status">
    <span class="visually-hidden">Page Loading..</span>
  </div>
</ng-template>
<ng-template #loginFailed>
  <div
    class="d-flex flex-column justify-content-center align-items-center noaccess-container text-center min-vh-100 py-3 bg-smart">
    <h1 class="label-heading1 text-white">Look's like, Something went wrong !</h1>
  </div>
</ng-template>
<ngx-spinner type="ball-spin-fade" size="medium" color="#ffffff" bdColor="rgba(51, 51, 51, 0.7)">
  <span class="visually-hidden" role="alert"> loading</span>
</ngx-spinner>