import {
    createFeatureSelector,
    createSelector
} from '@ngrx/store';
import * as sharedReducers from 'src/app/store/reducers/shared.reducers';

export const getSharedState = createFeatureSelector<sharedReducers.State>('sharedStore');

export const getLDClientFlag = createSelector(
    getSharedState,
    (state: sharedReducers.State) => {
        return state.ldClientFlags;
    }
);

export const getAllState = createSelector(
    getSharedState,
    (state: sharedReducers.State) => {
        return state;
    }
);
