import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appSpecialCharacter]'
})
export class SpecialCharacterDirective {

  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event: any): any {
    const initialValue = this._el.nativeElement.value;
    this._el.nativeElement.value = initialValue.replace(/[^\w\s]/gi, '');

    if (initialValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
