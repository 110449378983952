import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
  {
    path: '', canActivate: [MsalGuard],
    loadChildren: () => import('./feature/home/home.module').then((m) => m.HomeModule)
  },
  {
    path: 'audit-trail', canActivate: [MsalGuard],
    loadChildren: () => import('./feature/audit-trail/audit-trail.module').then((m) => m.AuditTrailModule)
  },
  {
    path: 'maintain-master', canActivate: [MsalGuard],
    loadChildren: () => import('./feature/maintain-master/maintain-master.module').then((m) => m.MaintainMasterModule)
  },
  {
    path: 'queued-tasks', canActivate: [MsalGuard],
    loadChildren: () => import('./feature/queued-tasks/queued-tasks.module').then((m) => m.QueuedTasksModule)
  },
  {
    path: 'resource-details/:resourceID/:callfrom', canActivate: [MsalGuard],
    loadChildren: () => import('./feature/resource-detail/resource-detail.module').then((m) => m.ResourceDetailModule)
  },
  {
    path: 'resource-management', canActivate: [MsalGuard],
    loadChildren: () => import('./feature/resource-management/resource-management.module').then((m) => m.ResourceManagementModule)
  },
  {
    path: 'project', canActivate: [MsalGuard],
    loadChildren: () => import('./feature/project/project.module').then((m) => m.ProjectModule)
  },
  {
    path: 'my-access', canActivate: [MsalGuard],
    loadChildren: () => import('./feature/my-access/my-access.module').then((m) => m.MyAccessModule)
  },
  {
    path: 'administration', canActivate: [MsalGuard],
    loadChildren: () => import('./feature/administration/administration.module').then((m) => m.AdministrationModule)
  },
  {
    path: 'scheduled-tasks', canActivate: [MsalGuard],
    loadChildren: () => import('./feature/scheduled-tasks/scheduled-tasks.module').then((m) => m.ScheduledTasksModule)
  },
  {
    path: 'contents', canActivate: [MsalGuard],
    loadChildren: () => import('./feature/static-contents/static-contents.module').then((m) => m.StaticContentsModule)
  },
  {
    path: 'bulk-upload', canActivate: [MsalGuard],
    loadChildren: () => import('./feature/bulk-upload/bulk-upload.module').then((m) => m.BulkUploadModule)
  },
  {
    path: 'request', canActivate: [MsalGuard],
    loadChildren: () => import('./feature/request/request.module').then((m) => m.RequestModule)
  },
  {
    path: 'create-demand', canActivate: [MsalGuard],
    loadChildren: () => import('./feature/create-demand/create-demand.module').then((m) => m.CreateDemandModule)
  },
  {
    path: 'source-requisition', canActivate: [MsalGuard],
    loadChildren: () => import('./feature/source-requisition/source-requisition.module').then((m) => m.SourceRequisitionModule)
  },
  {
    path: 'project-detail/:id', canActivate: [MsalGuard],
    loadChildren: () => import('./feature/project-detail/project-detail.module').then((m) => m.ProjectDetailModule)
  },
  { path: '**', redirectTo: '' }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
