<div class="card text-left filter-card-container">
    <div class="card-header d-flex filter-card-header">
        <span class="me-auto">Filter By</span>
        <button id="filterPanelClose" aria-label="Close confirmation popup"
            class="smart-icon-close smart-btn btn closebtn" (click)="close()"></button>
    </div>
    <div class="card-body filter-card-body p-0 custom-overflow-scroll">
        <div class="search-container" *ngIf="isGlobalSearch">
            <input placeholder="Search..." aria-label="Filter Search" [(ngModel)]="mainSearchInput" type="text"
                (input)="searchFilter($event)" class="form-control" />
        </div>
        <mat-accordion class="custom-mat-accordion" multi *ngIf="searchData && searchData.length > 0">
            <mat-expansion-panel class="mb-0" *ngFor="let item of searchData;let mainIndex = index;">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div class="title w-100">
                            <span class="expand-icon me-2"></span> {{item.title}}
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="panel-body">
                    <mat-accordion class="sub-custom-mat-accordion" multi>
                        <mat-expansion-panel *ngFor="let innerItem of item.subFilters;let secondIndex = index;">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <div class="sub-title w-100"
                                        [ngClass]="(innerItem.titleClass) ? innerItem.titleClass : ''"> <span
                                            class="expand-icon d-inline-block"></span>{{innerItem.title}}</div>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <!-- checkbox -->
                            <div class="inner-panel-body" *ngIf="innerItem.type === 'list'">
                                <input #innerSearch placeholder="Search..."
                                    [attr.aria-label]="'Search '+innerItem.title"
                                    (input)="searchInnerFilter($event, item.id, innerItem.id )" type="text"
                                    class="form-control mb-4" />

                                <ng-container *ngIf="subFilterData && innerItem.key && subFilterData[innerItem.key]">
                                    <div *ngFor="let secondinnerItem of 
                                    $any(subFilterData[innerItem.key] | 
                                    slice:0:dynamicVar[mainIndex+'-'+secondIndex]?dynamicVar[mainIndex+'-'+secondIndex]:initialTotalItems);let thirdIndex = index;"
                                        class="checkbox-label-container custom-checkbox ms-2 mb-2 d-flex col-sm-12">
                                        <input type="checkbox"
                                            (change)="changeSelection(item, innerItem ,secondinnerItem);innerSearch.value = ''"
                                            id="checkbox-{{secondinnerItem?.label}}-{{mainIndex+'-'+secondIndex+'-'+thirdIndex}}"
                                            class="custom-control-input" name="checkbox"
                                            [(ngModel)]="secondinnerItem.checked" [disabled]="secondinnerItem.disabled">
                                        <label
                                            for="checkbox-{{secondinnerItem?.label}}-{{mainIndex+'-'+secondIndex+'-'+thirdIndex}}"
                                            class="custom-control-label">{{secondinnerItem?.label}}</label>
                                    </div>
                                </ng-container>
                                <button
                                    *ngIf="subFilterData && subFilterData[innerItem.key]?.length > (dynamicVar[mainIndex+'-'+secondIndex]?dynamicVar[mainIndex+'-'+secondIndex]:initialTotalItems)"
                                    (click)="showMore(mainIndex,secondIndex)"
                                    class="btn btn-link smart-btn smart-btn-link show-more">Show
                                    More...</button>
                            </div>
                            <!-- checkbox -->
                            <!-- SearchTextBox -->
                            <div class="inner-panel-body" *ngIf="innerItem.type === 'textbox'">
                                <label class="form-label"
                                    [ngClass]="innerItem.innerTitle === undefined ? 'visually-hidden' : ''">{{innerItem.title}}</label>
                                <input type=" text" [(ngModel)]="innerItem.value" class="form-control">
                            </div>

                            <div class="inner-panel-body" *ngIf="innerItem.type === 'autocomplete'">
                                <input class="form-control mb-4" type="text" placeholder="Search..." aria-label="Search"
                                    [matAutocomplete]="autoCompleteFilterSearch" id="autoCompleteSearchFilter"
                                    [(ngModel)]="innerItem.value" (keyup)="changeSelection(item, innerItem ,null)">
                                <mat-autocomplete autoActiveFirstOption #autoCompleteFilterSearch="matAutocomplete">
                                    <mat-option *ngFor="let option of filteredOptions" [value]="option.value">
                                        {{option.value}}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                            <!-- SearchTextBox -->
                            <!-- datepicker -->
                            <div class="inner-panel-body date-picker-container position-relative"
                                *ngIf="innerItem.type === 'datepicker'">
                                <label class="form-label">{{innerItem.title}}</label>
                                <input class="form-control smart-form-control" placeholder="Date" matInput
                                    [(ngModel)]="innerItem.value" [matDatepicker]="pickerDate"
                                    (click)="pickerDate.open()">
                                <span class="smart-icon-calendar position-absolute filter-date-icon"
                                    (click)="pickerDate.open()"></span>
                                <mat-datepicker #pickerDate></mat-datepicker>
                            </div>
                            <!-- datepicker -->
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div class="card-footer filter-card-footer py-3">
        <ng-container *ngIf="filterFooterTemplate">
            <ng-container *ngTemplateOutlet="filterFooterTemplate"></ng-container>
        </ng-container>
        <div class="d-flex justify-content-between">
            <button class="btn smart-btn flex-1 btn-outline-primary me-2 py-2" (click)="resetAll()">RESET ALL</button>
            <button class="btn smart-btn  flex-1 smart-primary-btn py-2" (click)="onApply()">APPLY</button>
        </div>
    </div>
</div>