import { ActionReducerMap } from '@ngrx/store';
import * as sharedReducers from 'src/app/store/reducers/shared.reducers';

export interface State {
    sharedStore: sharedReducers.State;
}

export const reducers: ActionReducerMap<State> = {
    sharedStore: sharedReducers.reducer,
};


