import { Component, OnInit, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/core/services/common.service';
import { Subscription, debounceTime } from 'rxjs';
import { GlobalErrorHandlerService } from 'src/app/core/services/global-error-handler.service';
import { ToastComponent } from 'src/app/shared/components/toast/toast.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import * as fromRoot from 'src/app/store/selectors/shared.selectors';
import { RaiseRequest } from 'src/app/feature/my-access/config/my-access.config';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'app-access-request',
  templateUrl: './access-request.component.html',
  styleUrls: ['./access-request.component.scss']
})

export class AccessRequestComponent implements OnInit, OnDestroy {

  @ViewChild('stepper', { static: false }) stepper: any;
  @ViewChild('autoCompleteInput', { static: false, read: MatAutocompleteTrigger }) nameInput: MatAutocompleteTrigger | undefined;
  @Output() emitRaiseRequest = new EventEmitter();
  roleList: Array<any>;
  cpyRoleList: Array<any>;
  selectedRole: any;
  isDrawerToogle: boolean;
  isNewUser: boolean;
  maxLength: number;
  roleFormGroup!: FormGroup;
  teamFormGroup!: FormGroup;
  subTeamFormGroup!: FormGroup;
  accessReasonFormGroup!: FormGroup;
  raiseRequestStep: any = RaiseRequest;
  errorMessage: string;
  searchText: string;
  userConfigName: string;
  teamUserGroupList: Array<object>;
  roleSubscription: Subscription | undefined;
  teamSubscription: Subscription | undefined;
  subTeamSubscription: Subscription | undefined;
  raiseRequestSubscription: Subscription | undefined;
  storeSubscription: Subscription | undefined;
  userDetails: any;
  selectedTrainingValue: any;
  maxSelectionLimit: number;
  selectedSubTeamList: Array<any>;
  filteredOptions: Array<any>;
  autoCompleteSubTeamLoader: boolean;

  constructor(private fb: FormBuilder,
    public spinner: NgxSpinnerService,
    private commonService: CommonService,
    private globalErrorHandler: GlobalErrorHandlerService,
    private snackBar: MatSnackBar,
    private readonly store: Store
  ) {
    this.errorMessage = this.searchText = this.userConfigName = '';
    this.isDrawerToogle = true;
    this.isNewUser = true;
    this.maxLength = 500;
    this.roleList = this.teamUserGroupList = this.cpyRoleList = this.selectedSubTeamList = this.filteredOptions = [];
    this.maxSelectionLimit = 20;
    this.selectedTrainingValue = null;
    this.autoCompleteSubTeamLoader = false;
  }

  ngOnInit(): void {
    this.setForms();
    this.getRoleDetails();
    this.getStoreData();
    this.subTeamFormGroup.controls['subTeam'].valueChanges.pipe(debounceTime(500)).subscribe((res: string) => {
      if (res && res !== '') {
        this.getSubTeamSuggestion(res);
      } else {
        this.getSubTeamSuggestion('', 'Y');
      }
    });
  }

  setForms(): void {
    this.roleFormGroup = this.fb.group({
      role: ['', Validators.required],
    });
    this.teamFormGroup = this.fb.group({
      team: [null, Validators.required],
    });
    this.subTeamFormGroup = this.fb.group({
      subTeam: [null, Validators.required],
    });
    this.accessReasonFormGroup = this.fb.group({
      accessReason: [null, Validators.required],
    });
  }

  getRoleDetails(): void {
    this.spinner.show();

    const serviceParam = {
      parameter: '',
      environmentVariable: 'accessManagementAPIUrl',
      constantVariable: 'getActiveRoleDetails'
    };
    this.roleSubscription = this.commonService.postApi(serviceParam).subscribe((res: any) => {
      if (res.responseResult === 'Success') {
        this.cpyRoleList = this.roleList = res.result.getRoleDetailsList;
      } else {
        this.cpyRoleList = this.roleList = [];
      }
      this.spinner.hide();
    }, (error) => {
      this.spinner.hide();
      this.globalErrorHandler.handleError(error);
      this.showToastMsg('Something went wrong', 'danger');
    });
  }

  getStoreData(): void {
    this.storeSubscription = this.store.select(fromRoot.getAllState).subscribe((data: any) => {
      this.userDetails = data.userdDetails;
      if (data.userdDetails && !this.userConfigName) {
        this.userConfigName = data.userdDetails.aU_USER_NAME;
      }
    });
  }

  onRoleSelection(event: any): void {
    this.selectedRole = event.value;
    this.roleFormGroup?.controls['role'].setValue(this.selectedRole.roleAcronym);
    this.onChangeEvent(this.stepper, RaiseRequest.Role);
  }

  onChangeEvent(stepper: any, stepperIdx: number): void {
    stepper.selectedIndex = stepperIdx;
    this.subTeamFormGroup.controls['subTeam'].addValidators(Validators.required);
    switch (stepperIdx) {
      case RaiseRequest.Role: this.roleChange(); break;
      case RaiseRequest.Team: this.teamChange(); break;
      case RaiseRequest.Subteam: this.subTeamChange(); break;
      case RaiseRequest.AccessReason: break;
    }
    stepper.next();
  }

  roleChange(): void {
    this.teamFormGroup.reset();
    this.subTeamFormGroup.reset();
    this.accessReasonFormGroup.reset();
    this.userGroupList();
  }

  teamChange(): void {
    this.subTeamFormGroup.reset();
    this.accessReasonFormGroup.reset();
    this.selectedSubTeamList = [];
    this.getSubTeamSuggestion('', 'Y');
  }

  subTeamChange(): void {
    if (this.selectedSubTeamList.length > 0) {
      this.subTeamFormGroup.controls['subTeam'].clearValidators();
    } else {
      this.subTeamFormGroup.controls['subTeam'].addValidators(Validators.required);
      this.subTeamFormGroup.reset();
    }
    this.subTeamFormGroup.controls['subTeam'].updateValueAndValidity();
    this.accessReasonFormGroup.reset();
  }

  stateChange(stepper: any, stepperIdx: number, selectedFormGroup: FormGroup): string {
    let state = '';
    if (stepper.selectedIndex === stepperIdx) {
      state = 'edit';
    } else {
      state = (selectedFormGroup?.valid) ? 'done' : 'number';
    }
    return state;
  }

  userGroupList(): void {
    this.errorMessage = '';
    const params = {
      Role_id: this.selectedRole.roleId
    };
    const teamsDetailsConfig = {
      parameter: params,
      environmentVariable: 'accessManagementAPIUrl',
      constantVariable: 'getUserGroupForRole'
    };
    this.teamSubscription = this.commonService.postApi(teamsDetailsConfig).subscribe((res: any) => {
      if (res && res.responseResult === 'Success' && res.result.userGroupDetails && res.result.userGroupDetails.length > 0) {
        this.teamUserGroupList = res.result.userGroupDetails;
        if (res.result.userGroupDetails.length === 1) {
          this.teamFormGroup?.controls['team'].setValue(res.result.userGroupDetails[0]);
          this.onChangeEvent(this.stepper, RaiseRequest.Team);
        }
      }
    }, (error) => {
      this.globalErrorHandler.handleError(error);
      this.showToastMsg('Something went wrong', 'danger');
    });
  }

  getSelectedValues(items: any, property: string): string {
    return items.map((t: any) => { return t[property]; }).join(',');
  }

  onSearchRole(): void {
    this.roleList = this.commonService.sanitizeJSON(this.cpyRoleList);
    if (this.searchText && this.searchText !== '') {
      const roleList = this.roleList.filter((t: any) => { return t.roleAcronym.toLowerCase().includes(this.searchText.toLowerCase()); });
      this.roleList = roleList;
    }
  }

  showToastMsg(msg: string, type: string): void {
    this.snackBar.openFromComponent(ToastComponent, {
      panelClass: type,
      data: {
        label: msg
      },
    });
  }

  onClose(): void {
    this.emitRaiseRequest.emit({ success: false });
  }

  onReset(): void {
    this.teamChange();
    this.subTeamChange();
    this.stepper.reset();
    this.selectedRole = null;
    this.searchText = '';
    this.selectedTrainingValue = null;
    this.onSearchRole();
    this.errorMessage = '';
  }

  optionSelected(): void {
    const idx = this.selectedSubTeamList.findIndex((t: any) => t.ugmId === this.subTeamFormGroup?.get('subTeam')?.value.ugmId);
    if (idx === -1) {
      this.selectedSubTeamList.push(this.subTeamFormGroup?.get('subTeam')?.value);
      this.onChangeEvent(this.stepper, RaiseRequest.Subteam);
    }
    this.subTeamFormGroup.controls['subTeam'].setValue(null);
  }

  getSubTeamSuggestion(event: any, topRecords = 'N'): void {
    this.filteredOptions = [];
    this.autoCompleteSubTeamLoader = true;
    const params = {
      roleId: this.selectedRole?.roleId,
      enterpriseId: this.userConfigName,
      ugID: this.teamFormGroup?.get('team')?.value.ugId,
      UGMSearchText: event,
      retrieveTop20_YN: topRecords
    };
    const config = {
      parameter: params,
      environmentVariable: 'accessManagementAPIUrl',
      constantVariable: 'getUGMDetails'
    };
    this.subTeamSubscription = this.commonService.postApi(config).subscribe((res: any) => {
      if (res && res.responseResult === 'Success') {
        this.filteredOptions = res.result.getUGMDetailsList;
      } else {
        this.showToastMsg('Something went wrong', 'danger');
      }
      this.autoCompleteSubTeamLoader = false;
    }, (error) => {
      this.globalErrorHandler.handleError(error);
      this.showToastMsg('Something went wrong', 'danger');
    });
  }

  removeSelections(selectedRecord: any): void {
    const removeIdx = this.selectedSubTeamList.findIndex((t: any) => t.ugmId === selectedRecord.ugmId);
    this.selectedSubTeamList.splice(removeIdx, 1);
    if (this.selectedSubTeamList.length === 0) {
      this.onChangeEvent(this.stepper, RaiseRequest.Subteam);
    }
  }

  raiseAccessRequest(): void {
    this.errorMessage = '';
    this.spinner.show();
    const params = {
      'ENTERPRISE_ID': this.userConfigName,
      'User_Name': this.userConfigName,
      'UG_ID': this.teamFormGroup?.get('team')?.value.ugId,
      'ROLE_ID': this.selectedRole.roleId,
      'UGM_IDS': this.getSelectedValues(this.selectedSubTeamList, 'ugmId'),
      'Comments': this.accessReasonFormGroup?.get('accessReason')?.value,
      'Training_YN': this.selectedTrainingValue,
      'AccessRequestType': 'Raise'
    };
    const raiseRequestConfig = {
      parameter: params,
      environmentVariable: 'accessManagementAPIUrl',
      constantVariable: 'raiseAccessRequest'
    };
    this.raiseRequestSubscription = this.commonService.postApi(raiseRequestConfig)
      .subscribe((res: any) => {
        this.spinner.hide();
        if (res.responseResult === 'Success') {
          if (res.successMessage) {
            this.showToastMsg(res.successMessage, 'success');
            this.emitRaiseRequest.emit({ success: true, message: '' });
          } else if (res.errorMessage === 'Validation Error') {
            this.errorMessage = res.raiseAccessRequestOutput.validation.validationErrors[0].message;
          }
        } else {
          this.errorMessage = 'Something went wrong';
        }
      }, (error: any) => {
        this.spinner.hide();
        this.globalErrorHandler.handleError(error);
        this.showToastMsg('Something went wrong', 'danger');
      });
  }

  ngOnDestroy(): void {
    this.roleSubscription?.unsubscribe();
    this.teamSubscription?.unsubscribe();
    this.subTeamSubscription?.unsubscribe();
    this.raiseRequestSubscription?.unsubscribe();
    this.storeSubscription?.unsubscribe();
  }
}
