import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef } from '@angular/core';
import { environment } from '../../../environments/environment';

@Directive({
  selector: '[appFeatureHide]',
  exportAs: 'appFeatureHide',
})
export class FeatureHideDirective implements AfterViewInit {
  env: any = environment;
  isVisible = false;
  constructor(private el: ElementRef, private cdRef: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    if (this.env.env !== 'local') {
      this.el.nativeElement?.remove();
    } else {
      this.isVisible = true;
    }
    this.cdRef.detectChanges();
  }
}
