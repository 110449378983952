import { Component, OnInit, AfterViewInit, Input, ViewChild, OnChanges, SimpleChanges, HostListener } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() chartData: any;
  svg: any;
  @ViewChild('barChart', { static: false }) barChart: any;
  private margin = { top: 20, right: 10, bottom: 20, left: 10 };
  width: any;
  height: any;

  constructor() {
  }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    this.createSvg();

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['chartData'].firstChange) {
      d3.select(this.barChart.nativeElement).select('svg').remove();
      this.createSvg();
    }

  }
  @HostListener('window:resize', ['$event'])

  createSvg(): void {
    const chartContainer = this.barChart.nativeElement;
    d3.select(this.barChart.nativeElement).select('svg').remove();
    this.width = chartContainer.offsetWidth;
    this.height = chartContainer.offsetHeight;
    if (this.barChart) {
      this.svg = d3
        .select(this.barChart.nativeElement)
        .append('svg')
        .attr(
          'viewBox',
          `0 0 ${this.width + this.margin.left + this.margin.right} ${this.height + + this.margin.top + this.margin.bottom}`
        )

        .append('g')
        .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');
      this.drawBars(this.chartData);
    }
  }
  drawBars(data: any[]): void {
    const x: any = d3
      .scaleBand()
      .range([0, this.width - this.margin.left - this.margin.right])
      .domain(data.map((d) => d.name))
      .padding(0.2);

    this.svg
      .append('g')
      .attr('transform', 'translate(0,' + (this.height - this.margin.top - this.margin.bottom) + ')')
      .call(d3.axisBottom(x).tickSize(0).tickPadding(10))
      .selectAll('text')
      .style('font-size', '14px')
      .attr('aria-hidden', true)
      .style('font-family', 'Graphik Regular');

    const y = d3
      .scaleLinear();
    y.domain([0, d3.max(data, d => d.value)])
      .range([(this.height - this.margin.top - this.margin.bottom), 0]);
    this.svg
      .selectAll('bars')
      .data(data)
      .enter()
      .append('rect')
      .attr('x', (d: any) => x(d.name))
      .attr('y', (d: any) => y(d.value))
      .attr('width', x.bandwidth())
      .attr('height', (d: any) =>
        d.value !== 0 ? (this.height - this.margin.top - this.margin.bottom) - y(d.value) : d.value
      )
      .attr('fill', (d: any) => d.color);

    this.svg
      .selectAll('text.bar')
      .data(data)
      .enter()
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('fill', '#70747a')
      .attr('x', (d: any) => x(d.name) + (x.bandwidth() / 2))
      .attr('y', (d: any) => d.value !== 0 ? y(d.value) - 5 : (this.height - this.margin.top - this.margin.bottom) - 5)
      .text('')
      .append('tspan')
      .attr('x', (d: any) => x(d.name) + (x.bandwidth() / 2))
      .html((d: any) => '<tspan class="hide-content">' + d.name + ' </tspan>' + Math.round(d.value * 100) / 100);

    const tooltip = d3
      .select(this.barChart.nativeElement)
      .append('div')
      .style('opacity', 0)
      .attr('class', 'tooltip-barcart')
      .style('background-color', '#ffffff')
      .style('position', 'absolute')
      .style('color', '#000000')
      .style('font-size', '12px')
      .style('border-radius', '4px')
      .style('padding', '4px 6px')
      .style('text-align', 'center');

    this.svg
      .on('mouseover', (event: any) => {
        if (event.target.__data__ !== null) {
          if (event.target.__data__.value !== undefined) {
            tooltip.style('opacity', 1);
            tooltip.html(`${event.target.__data__.name} <br> Data <br>${event.target.__data__.value}`) // Customize the tooltip content
              .style('left', `${event.pageX}px`)
              .style('top', `${event.pageY - 28}px`);
          }
        }
      })

      .on('mouseleave', () => {
        tooltip.style('opacity', 0);
      });

  }
}
