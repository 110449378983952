<button mat-dialog-close aria-label="Close confirmation popup"
  class="smart-icon-close btn position-absolute close-btn"></button>
<h1 mat-dialog-title [ngClass]="data.title && data.description ? 'mb-3' : 'mb-0'"
  *ngIf="data.title && data.title !== ''">{{data.title}}</h1>
<div mat-dialog-content [innerHTML]="data.description" class="custom-overflow-scroll"></div>
<div mat-dialog-actions class="mt-5 pt-md-2 float-end">
  <button *ngIf="!data.isConfirmType" class="btn smart-btn smart-primary-btn text-uppercase" mat-button
    [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
  <ng-container *ngIf="data.isConfirmType">
    <button class="btn btn-link text-uppercase text-decoration-none me-3 smart-btn smart-btn-link" mat-button
      [mat-dialog-close]="false">{{data.primaryButton ? data.primaryButton : 'NO'}} </button>
    <button class="btn smart-btn smart-primary-btn text-uppercase" mat-button [mat-dialog-close]="true"
      cdkFocusInitial>{{data.secondaryButton ? data.secondaryButton : 'Yes'}}</button>
  </ng-container>
</div>