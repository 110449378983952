import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ConfirmPopupComponent } from './components/confirm-popup/confirm-popup.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DataGridComponent } from './components/data-grid/data-grid.component';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AccessRequestComponent } from './components/access-request/access-request.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DonutchartComponent } from './components/donutchart/donutchart.component';
import { MatSortModule } from '@angular/material/sort';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { ToastComponent } from './components/toast/toast.component';
import { FilterPanelComponent } from './components/filter-panel/filter-panel.component';
import { RouterModule } from '@angular/router';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { NgSelectDirective } from './directives/ng-select.directive';
import { MatInputModule } from '@angular/material/input';
import { DynamicDataGridComponent } from './components/dynamic-table/dynamic-table.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { ScheduleTaskComponent } from './components/schedule-task/schedule-task.component';
import { FeatureHideDirective } from './directives/feature-hide.directive';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSidenavModule } from '@angular/material/sidenav';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { PyramidChartComponent } from './components/pyramid-chart/pyramid-chart.component';
import { DateFormatDirective } from './directives/date-format.directive';
import { NumberDirective } from './directives/number.directive';
import { PersonalizationComponent } from './components/personalization/personalization.component';
import { HtmlToContentPipe } from './pipes/html-to-content.pipe';
import { SpecialCharacterDirective } from './directives/special-character.directive';


@NgModule({
  declarations: [
    HeaderComponent,
    BreadcrumbComponent,
    ConfirmPopupComponent,
    DataGridComponent,
    AccessRequestComponent,
    DonutchartComponent,
    ToastComponent,
    FilterPanelComponent,
    NgSelectDirective,
    FeatureHideDirective,
    DynamicDataGridComponent,
    ScheduleTaskComponent,
    BarChartComponent,
    PyramidChartComponent,
    DateFormatDirective,
    NumberDirective,
    PersonalizationComponent,
    HtmlToContentPipe,
    SpecialCharacterDirective
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    FormsModule,
    MatTooltipModule,
    MatDialogModule,
    MatTableModule,
    MatCheckboxModule,
    NgSelectModule,
    MatSortModule,
    MatSnackBarModule,
    RouterModule,
    MatExpansionModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    MatSelectModule,
    MatStepperModule,
    MatSidenavModule
  ],
  exports: [
    HeaderComponent,
    BreadcrumbComponent,
    DataGridComponent,
    AccessRequestComponent,
    DonutchartComponent,
    FilterPanelComponent,
    NgSelectDirective,
    FeatureHideDirective,
    DynamicDataGridComponent,
    ScheduleTaskComponent,
    BarChartComponent,
    PyramidChartComponent,
    DateFormatDirective,
    NumberDirective,
    PersonalizationComponent,
    HtmlToContentPipe,
    SpecialCharacterDirective
  ],
  providers: [
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 10000 } },
    {
      provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
    },
    DatePipe
  ]
})

export class SharedModule { }
