import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromRoot from 'src/app/store/selectors/shared.selectors';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CommonService } from 'src/app/core/services/common.service';
import headerSearchOptions from 'src/assets/data/header-data.json';
import { searchOption } from './model/header.model';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import * as sharedActions from 'src/app/store/actions/shared.actions';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  loggedIn: string;
  showNavbarSearch: boolean;
  showSearch: boolean;
  searchOptionCtrl = new FormControl('');
  filteredsearchoption: Observable<searchOption[]>;
  searchOption: searchOption[];
  pageName: string;
  isExpansionEnabled: boolean;
  showHeaderIcons: boolean;
  isNewUser: boolean | undefined;
  userAccessData: any;
  storeSubscription: Subscription | undefined;
  ldFlags: any | undefined;
  showUploadTab: boolean;
  uploadSubscription: Subscription | undefined;
  aseUrl: string;
  toolTipMsg: string;
  headerType: any;
  headerTypeSubscription: Subscription | undefined;
  isDisplayProfile: boolean;

  constructor(
    private router: Router, private commonService: CommonService, private readonly store: Store, private authService: MsalService,
    private cdr: ChangeDetectorRef
  ) {
    this.searchOption = [];
    this.pageName = '';
    this.toolTipMsg = '';
    this.loggedIn = 'TFS';
    this.showSearch = false;
    this.showNavbarSearch = false;
    this.isExpansionEnabled = false;
    this.filteredsearchoption = this.searchOptionCtrl.valueChanges.pipe(
      startWith(''),
      map((option) =>
        option ? this._filterStates(option) : this.searchOption.slice()
      )
    );
    this.showHeaderIcons = false;
    this.commonService.isExpandEnabled.subscribe(value => {
      this.isExpansionEnabled = value;
    });
    this.isNewUser = true;
    this.showUploadTab = true;
    this.aseUrl = environment.aseUrl;
    this.isDisplayProfile = false;
  }

  ngOnInit(): void {
    this.searchOption = headerSearchOptions.headerSearchTypeAheadDropdownData;

    this.storeSubscription = this.store.select(fromRoot.getAllState).subscribe((data: any) => {
      if (data.userdDetails && !this.userAccessData) {
        this.userAccessData = data.userdDetails;
        if (this.userAccessData.aU_ID > 0) {
          this.isNewUser = (this.userAccessData.isDefaultUGRole_Incorrect) ? true : false;
        } else {
          this.isNewUser = true;
        }
      }
      this.isDisplayProfile = this.userAccessData.aU_ID > 0 ? true :
        (this.userAccessData.isNewUser && this.userAccessData.isRequestPending) ? true : false;
      if (data.ldClientFlags && data.ldClientFlags.length > 0) {
        this.ldFlags = data.ldClientFlags;
        this.cdr.detectChanges();
      }
      if (this.userAccessData && this.ldFlags) {
        this.displayToolTip();
        this.showUploadTab = true;
        if (this.userAccessData.action && this.userAccessData.action.length > 0 &&
          (this.userAccessData.action.findIndex((ele: any) => ele.key === 'EarlyDemandVisibility') !== -1)
          && (this.userAccessData.get_Roles.length === 1)) {
          this.showUploadTab = false;
        }
        this.getTemplateList();
      }
    });
    this.headerTypeSubscription = this.commonService.headerTypeObservable.subscribe(val => this.headerType = val);
  }

  checkLdFlag(flag: string): boolean {
    return (this.ldFlags && this.ldFlags.length > 0) ? this.ldFlags.findIndex((t: any) => t.key === flag) > -1 : false;
  }

  getTemplateList(): void {
    const config = {
      parameter: {
        'UserGroupID': this.userAccessData.aU_DEFAULT_UG_ID
      },
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'data': '{ "ui" : ' + this.userAccessData.aU_ID + ' , "ur" : ' + this.userAccessData.defaulT_UG_NAME + ' , "aid" : '
          + 'SmartApp' + ' }'
      },
      headerType: 2,
      environmentVariable: 'resourceDetailUrl',
      constantVariable: 'getTemplateList'
    };

    this.uploadSubscription = this.commonService.getApi(config)
      .subscribe((response: any) => {
        if (!response.isAccess)
          this.showUploadTab = false;
      });
  }

  _filterStates(value: string): searchOption[] {
    const filterValue = value.toLowerCase();

    return this.searchOption.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  routeTo(page: string): void {
    this.router.navigate([page]);
    this.store.dispatch(sharedActions.updateProjectDetails({ previousPagePath: '', projectFilter: null }));
  }
  ngOnDestroy(): void {
    this.storeSubscription?.unsubscribe();
    this.uploadSubscription?.unsubscribe();
    this.headerTypeSubscription?.unsubscribe();

  }

  isMenuTrigger(): boolean {
    if (this.isNewUser) {
      return true;
    } else if (this.checkLdFlag('Navigation_My_Access_Page') ||
      (this.checkLdFlag('navigate_to_Admin') && this.checkRights('requestDashboard'))) {
      return false;
    }
    return true;
  }
  isAdminRole(): boolean {
    if (this.ldFlags &&
      this.checkLdFlag('navigate_to_Admin') && this.checkRights('requestDashboard')) {
      return true;
    }
    return false;
  }
  checkRights(role: string): boolean {
    if (this.userAccessData.action && this.userAccessData.action.length > 0) {
      return this.userAccessData.action.findIndex((t: any) => t.key === role) > -1;
    }
    return false;
  }

  signOut(): void {
    sessionStorage.clear();
    this.authService.logout();
  }

  displayToolTip(): void {
    if (this.checkLdFlag('Default_Access_BasedOnRole') && !this.isNewUser)
      this.toolTipMsg = `Team: ${this.userAccessData.default_UG_ACRONYM} | Role: ${this.userAccessData.get_Roles[0].roleAcronym}`;
    else
      this.toolTipMsg = `${this.userAccessData.aU_USER_NAME} logged in as ${this.userAccessData.default_UG_ACRONYM}`;
  }
}
