import {
  Component, EventEmitter, Input, OnChanges, OnInit,
  Output, SimpleChange, SimpleChanges, TemplateRef, ViewChild
} from '@angular/core';
import { Observable } from 'rxjs';
import { MatAccordion } from '@angular/material/expansion';
import { filterDataModel } from '../../model/common.model';
import { CommonService } from 'src/app/core/services/common.service';
@Component({
  selector: 'app-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss']
})
export class FilterPanelComponent implements OnInit, OnChanges {
  @Output() showFilter = new EventEmitter<boolean>();
  @Output() filteredInfo = new EventEmitter<any>();
  @Output() changeSelectionEmit = new EventEmitter<any>();
  @Output() resetAllEmit = new EventEmitter<any>();
  @Input() filterData: Array<filterDataModel> | undefined;
  @Input() selectedData: Array<any> | undefined;
  @Input() filterFooterTemplate: TemplateRef<any> | undefined;
  @Input() subFilterData: any;
  @Input() isGlobalSearch = true;
  searchData: Array<filterDataModel> | undefined;
  dynamicVar: any;
  initialTotalItems: number;
  @ViewChild(MatAccordion) accordion: MatAccordion | undefined;
  subFilterTempData: any;
  mainSearchInput: any;
  filterSerachData: any = [];
  options: string[] | undefined;
  filteredOptions: Observable<{ value: string }> | any;

  constructor(public commonService: CommonService) {
    this.dynamicVar = {};
    this.initialTotalItems = 20;

  }
  ngOnInit(): void {
    this.subFilterTempData = this.commonService.sanitizeJSON(this.subFilterData);
    if (this.selectedData && this.selectedData.length > 0) {
      this.selectedData.forEach(dt => {
        this.updateFilterData(dt, 'SET');
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const dataVal: SimpleChange = changes['subFilterData'];
    const filterDataVal: SimpleChange = changes['filterData'];

    if (filterDataVal !== undefined && filterDataVal.currentValue !== null
      && JSON.stringify(filterDataVal.currentValue) !== JSON.stringify(filterDataVal.previousValue)) {
      if (this.searchData && this.searchData.length > 0) {
        this.searchData.forEach((item: any, i: number) => {
          const autoTypeIdx = item.subFilters.findIndex((t: any) => t.type === 'autocomplete');
          if ((item.type === 'autocomplete') || (item.subFilters.findIndex((t: any) => t.type === 'autocomplete') >= 1)) {
            if (autoTypeIdx !== -1) {
              this.filteredOptions = filterDataVal.currentValue[i].subFilters[autoTypeIdx].options;
            } else {
              this.searchData = this.commonService.sanitizeJSON(this.filterData);
            }
          }
        });
      } else {
        this.searchData = this.commonService.sanitizeJSON(this.filterData);

      }
    }

    if (dataVal !== undefined && dataVal.currentValue !== null
      && JSON.stringify(dataVal.currentValue) !== JSON.stringify(dataVal.previousValue)) {
      this.subFilterTempData = this.commonService.sanitizeJSON(dataVal.currentValue);
      this.filterSerachData = [];

      this.searchData?.forEach(element => {
        element.subFilters.forEach((item: any) => {
          if (item.type === 'list') {
            const selectedFilterData = this.subFilterTempData[item.key]?.filter((x: any) => x.checked === true);
            if (selectedFilterData && selectedFilterData.length !== 0) {
              selectedFilterData.forEach((x: any) => {

                const index = this.filterSerachData.findIndex((d: any) => d.key === x.key && d.apiKey === x.apiKey);
                if (index < 0) {
                  this.filterSerachData.push({
                    FilterName: element.title, FilterTitle: item.title, key: x.key, type: item.type
                    , listKey: item.key, apiKey: item.apiKey, label: x.label
                  });
                }
              });
            }
          }
        });
      });
    }
  }

  updateFilterData(item: any, type: string): void {
    const findItemFirstLevel1 = this.searchData?.find(d1 => d1.title === item.FilterName);
    const findItemFirstLevel2 = findItemFirstLevel1?.subFilters.find(d1 => d1.title === item.FilterTitle);
    const findItemFirstLevel3 = findItemFirstLevel2?.filters.find(d1 => d1.key === item.key);

    switch (findItemFirstLevel2?.type) {
      case 'list':
        if (findItemFirstLevel3) {
          findItemFirstLevel3.checked = type === 'SET' ? true : false;
          findItemFirstLevel3.disabled = type === 'SET' ? true : false;
        }
        break;
      case 'textbox' || 'datepicker':
        findItemFirstLevel2.value = type === 'SET' ? item.value : null;
        break;
      default:
        break;
    }
  }

  searchFilter(event: any): void {
    this.searchData = this.commonService.sanitizeJSON(this.filterData);
    const temp: any = [];
    this.searchData?.forEach((element: any) => {
      const subfilter = element?.subFilters?.filter((dt: any) => dt.title.toLowerCase().includes(event.target.value.toLowerCase()));
      if (subfilter.length > 0) {
        element.subFilters = subfilter;
        temp.push(element);
      }
    });
    this.searchData = temp;
    setTimeout(() => {
      if (event.target.value === '' || event.target.value === null) {
        this.accordion?.closeAll();
      } else {
        this.accordion?.openAll();
      }
    }, 100);

  }

  showMore(mainIndex: any, secondIndex: any): void {
    if (!this.dynamicVar[mainIndex + '-' + secondIndex]) {
      this.dynamicVar[mainIndex + '-' + secondIndex] = this.initialTotalItems;
    }
    this.dynamicVar[mainIndex + '-' + secondIndex] += 10;
  }

  searchInnerFilter(event: any, mainId: any, innerId: any): void {
    const maindata = this.searchData?.find((d: any) => d.id === mainId);
    const secondaryData = maindata?.subFilters.find((d: any) => d.id === innerId);

    if (secondaryData) {
      const tempData1 = this.commonService.sanitizeJSON(this.subFilterTempData[secondaryData.key]);
      const tempData = tempData1.filter((dt: any) => dt.label.toLowerCase().includes(event.target.value.toLowerCase()));
      this.subFilterData[secondaryData.key] = tempData;
    }
  }

  close(): void {
    this.showFilter.emit(false);
  }
  onApply(): void {
    let filterSerachData: any = [];
    this.searchData?.forEach(element => {
      element.subFilters.forEach((item: any) => {
        if (item.type === 'list') {
          filterSerachData = this.filterSerachData;
        }
        else if (item.type === 'textbox' || item.type === 'datepicker' || item.type === 'autocomplete') {
          if (item.value !== null) {
            filterSerachData.push({ FilterName: element.title, FilterTitle: item.title, value: item.value, type: item.type });
          }
        }
      });
    });
    this.filteredInfo.emit(filterSerachData);
    this.showFilter.emit(false);
  }


  changeSelection(item: any, innerItem: any, secondinnerItem: any): void {
    if (innerItem.type === 'list') {
      if (secondinnerItem.checked) {
        if (innerItem.singleSelect) {
          const index = this.filterSerachData.findIndex((d: any) => d.apiKey === innerItem.apiKey);
          if (index !== -1) {
            this.filterSerachData.splice(index, 1);
          }

        }
        this.filterSerachData.push({
          FilterName: item.title, FilterTitle: innerItem.title, key: secondinnerItem.key, type: innerItem.type
          , listKey: innerItem.key, apiKey: innerItem.apiKey, label: secondinnerItem.label
        });
      } else {
        const index = this.filterSerachData.findIndex((d: any) => d.key === secondinnerItem.key && d.apiKey === innerItem.apiKey);
        this.filterSerachData.splice(index, 1);
      }
      this.changeSelectionEmit.emit(this.filterSerachData);
    }
    else if (innerItem.type === 'autocomplete') {
      let autoCompleteValue: any;
      this.searchData?.forEach(element => {
        if (element.subFilters && element.subFilters.length > 0) {
          const idx = element.subFilters.findIndex((t: any) => t.type === innerItem.type);
          autoCompleteValue = (idx > -1) ? element.subFilters[idx].value : null;
        }
      });
      this.changeSelectionEmit.emit(autoCompleteValue);
    }
  }

  resetAll(): void {
    this.resetAllEmit.emit();
    this.searchData = [];

    setTimeout(() => {
      this.searchData = this.commonService.sanitizeJSON(this.filterData);
    }, 100);
  }
  resetAuto(): void {
    if (this.searchData?.length) {
      this.searchData[0].subFilters[0].value = '';
    }
  }

  resetSearch(): void {
    this.mainSearchInput = '';
    this.searchFilter({ target: { value: this.mainSearchInput } });
  }
}
