import { Action, createReducer, on } from '@ngrx/store';
import { LdFlag } from 'src/app/shared/model/common.model';
import * as sharedActions from 'src/app/store/actions/shared.actions';

export interface State {
    ldClientFlags: LdFlag[] | null,
    userdDetails: any,
    resourceDetails: any,
    projectDetails?: any
}

export const initialState: State = {
    ldClientFlags: null,
    userdDetails: null,
    resourceDetails: null,
    projectDetails: null
};

const sharedReducer = createReducer(
    initialState,
    on(sharedActions.getLDClientFlag, (state) => ({ ...state })),
    on(sharedActions.updateLDClientFlags, (state, action) => ({ ...state, ldClientFlags: action.data })),
    on(sharedActions.updateUserDetails, (state, action) => ({ ...state, userdDetails: action })),
    on(sharedActions.updateResourceDetails, (state, action) => ({ ...state, resourceDetails: action })),
    on(sharedActions.updateProjectDetails, (state, action) => ({ ...state, projectDetails: action })),
);

export function reducer(state: State | undefined, action: Action): any {
    return sharedReducer(state, action);
}
