import { createAction, props } from '@ngrx/store';
import { LdFlag } from 'src/app/shared/model/common.model';

export const GET_LD_CLIENT_ACTION = '[Shared] Get LD Cient';
export const UPDATE_LD_CLIENT_ACTION = '[Shared] Update LD Cient';
export const UPDATE_LD_USER_DETAILS_ACTION = '[Shared] Update user Details';
export const UPDATE_RESOURCE_DETAILS_ACTION = '[Shared] Update resource Details';
export const UPDATE_PROJECT_DETAILS_ACTION = '[Shared] Update project Details';


export const getLDClientFlag = createAction(
    GET_LD_CLIENT_ACTION
);

export const updateLDClientFlags = createAction(
    UPDATE_LD_CLIENT_ACTION,
    props<{ data: LdFlag[] }>()
);

export const updateUserDetails = createAction(
    UPDATE_LD_USER_DETAILS_ACTION,
    props<any>()
);

export const updateResourceDetails = createAction(
    UPDATE_RESOURCE_DETAILS_ACTION,
    props<{ rrd_No: number, previousPagePath: string, lockType: string, talentFilter: any, personalizationData: any }>()
);

export const updateProjectDetails = createAction(
    UPDATE_PROJECT_DETAILS_ACTION,
    props<{ previousPagePath: string, projectFilter: any }>()
);
