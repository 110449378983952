import { Component, OnInit, Inject, OnDestroy, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Frequency, Weeks } from '../../model/common.model';
import { DatePipe, formatDate } from '@angular/common';
import { CommonService } from 'src/app/core/services/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ToastComponent } from '../toast/toast.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-schedule-task',
  templateUrl: './schedule-task.component.html',
  styleUrls: ['./schedule-task.component.scss']
})
export class ScheduleTaskComponent implements OnInit, OnDestroy, AfterContentChecked {
  minFromDate: object | null;
  minToDate: object | null;
  selectedFromDate: object | null;
  selectedToDate: object | null;
  scheduleTaskFormGroup: FormGroup | undefined;
  weekdays: string[];
  occurrenceCount: number;
  formInitialValues: any;
  scheduledTaskSubscription: Subscription | undefined;
  frequency: any;
  hours: any;
  minutes: any;
  counter: number;
  isSubmit: boolean;

  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<ScheduleTaskComponent>, private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any, public spinner: NgxSpinnerService, private commonService: CommonService,
    private datePipe: DatePipe, private cdr: ChangeDetectorRef) {
    this.minFromDate = new Date();
    this.minToDate = new Date();
    this.selectedFromDate = null;
    this.selectedToDate = null;
    this.occurrenceCount = 366;
    this.weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    this.hours = [];
    this.minutes = [];
    this.setTime();
    this.formInitialValues = {
      taskName: this.data.scheduleTaskName ? this.data.scheduleTaskName : 'Talent',
      scheduleTaskOption: Frequency.oneTime,
      selectedFromDate: new Date(),
      startTime: this.setDefaultHour(),
      startMinute: this.setDefaultMin(),
      selectedToDate: new Date(),
      occursEvery: 1,
      repeatOn: this.setDefaultWeekDay(),
      taskDescription: null
    };
    this.frequency = Frequency;
    this.counter = 0;
    this.isSubmit = false;
  }

  ngOnInit(): void {
    if (this.data && this.data.details) {
      const fromDate = this.datePipe.transform(this.data.details.startDate, 'MM/dd/yyyy');
      const endDate = this.datePipe.transform(this.data.details.endDate ? this.data.details.endDate : new Date(), 'MM/dd/yyyy');
      this.formInitialValues = {
        taskName: this.data.details.taskName,
        scheduleTaskOption: this.data.details.freqType,
        selectedFromDate:
          this.getDayDifference(endDate, fromDate) < 0 ? new Date() : new Date(this.data.details.startDate),
        startTime: this.data.details.startDate ? formatDate(new Date(this.data.details.startDate), 'HH', 'en-US') : '00',
        startMinute: this.data.details.startDate ? formatDate(new Date(this.data.details.startDate), 'mm', 'en-US') : '00',
        selectedToDate: (this.data.details.endDate !== '' && this.data.details.endDate) ? new Date(this.data.details.endDate) : new Date(),
        occursEvery: this.setOccurence(),
        repeatOn: this.data.details.freqType === Frequency.weekly && this.data.details.freqInterval ?
          this.setRepeatOn() : this.setDefaultWeekDay(),
        taskDescription: this.data.details.description
      };
    }
    this.minToDate = this.formInitialValues.formDate;
    this.setForm();
    this.cdr.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  setTime(): void {
    for (let i = 0; i < 60; i++) {
      if (i < 24) {
        this.setHoursMinutes(i, this.hours);
      }
      this.setHoursMinutes(i, this.minutes);
    }
  }

  setHoursMinutes(i: number, data: any): void {
    if (i < 10) {
      data.push(`0${i}`);
    } else {
      data.push(i.toString());
    }
  }

  getDayDifference(endDate: any, fromDate: any): number {
    const date1 = new Date(fromDate);
    const date2 = new Date(endDate);
    const oneDay = 1000 * 60 * 60 * 24;
    const diffInTime = date2.getTime() - date1.getTime();
    const diffInDays = Math.round(diffInTime / oneDay);
    return diffInDays;
  }
  setOccurence(): number {
    if (this.data.details.freqType === Frequency.daily || this.data.details.freqType === Frequency.monthly) {
      return +this.data.details.freqInterval;
    }
    return 1;
  }
  setRepeatOn(): Array<string> {
    const weekNumbers = this.data.details.freqInterval.split(',');
    const repeatedOn: any = [];
    weekNumbers.forEach((item: string) => {
      repeatedOn.push(Weeks[+item]);
    });
    return repeatedOn;
  }
  setDefaultHour(date = new Date()): any {
    const minutes = 30;
    const ms = 1000 * 60 * minutes;

    const time = new Date(Math.ceil(date.getTime() / ms) * ms);
    const hrs = time.getHours() < 10 ? '0' + time.getHours() : time.getHours();
    return hrs;
  }
  setDefaultMin(date = new Date()): any {
    const minutes = 30;
    const ms = 1000 * 60 * minutes;

    const time = new Date(Math.ceil(date.getTime() / ms) * ms);
    const min = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes();
    return min;
  }
  setDefaultTime(date = new Date()): string {
    const minutes = 30;
    const ms = 1000 * 60 * minutes;

    const time = new Date(Math.ceil(date.getTime() / ms) * ms);
    const hrs = time.getHours() < 10 ? '0' + time.getHours() : time.getHours();
    const min = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes();
    return hrs + ':' + min;
  }
  setDefaultWeekDay(): any {
    const tempAry = [];
    const day = new Date().getDay();
    tempAry.push(this.weekdays[day]);
    return tempAry;
  }

  setForm(): void {
    this.scheduleTaskFormGroup = this.fb.group({
      taskName: [{ value: this.formInitialValues.taskName, disabled: true }, Validators.required],
      taskDescription: [this.formInitialValues.taskDescription],
      scheduleTaskOption: [this.formInitialValues.scheduleTaskOption, Validators.required],
      selectedFromDate: [this.formInitialValues.selectedFromDate, Validators.required],
      startTime: [this.formInitialValues.startTime, Validators.required],
      startMinute: [this.formInitialValues.startMinute, Validators.required]
    });
    this.onScheduleChange({ value: this.formInitialValues.scheduleTaskOption });
  }

  onScheduleChange(event: any): void {
    this.counter += 1;
    switch (this.scheduleTaskFormGroup?.value.scheduleTaskOption) {
      case Frequency.daily:
      case Frequency.monthly: {
        this.occurrenceCount = event.value === Frequency.daily ? 366 : 31;
        this.scheduleTaskFormGroup.removeControl('repeatOn');
        this.scheduleTaskFormGroup.addControl('occursEvery', this.fb.control(this.formInitialValues.occursEvery, [Validators.required]));
        this.scheduleTaskFormGroup.addControl('selectedToDate', this.fb.control(this.formInitialValues.selectedToDate));
        this.setEndDate(this.scheduleTaskFormGroup?.value.scheduleTaskOption);
        break;
      }
      case Frequency.oneTime: {
        this.scheduleTaskFormGroup.removeControl('repeatOn');
        this.scheduleTaskFormGroup.removeControl('selectedToDate');
        this.scheduleTaskFormGroup.removeControl('occursEvery');
        break;
      }
      case Frequency.weekly: {
        this.scheduleTaskFormGroup.removeControl('occursEvery');
        this.scheduleTaskFormGroup.addControl('repeatOn', this.fb.array(this.formInitialValues.repeatOn, [Validators.required]));
        this.scheduleTaskFormGroup.addControl('selectedToDate', this.fb.control(this.formInitialValues.selectedToDate));
        this.setEndDate(this.scheduleTaskFormGroup?.value.scheduleTaskOption);
        break;
      }
      default: break;
    }
  }
  setEndDate(option: Frequency): any {
    const date = this.scheduleTaskFormGroup?.get('selectedFromDate')?.value;
    const toDate = this.scheduleTaskFormGroup?.get('selectedToDate')?.value;
    const fromDate = this.datePipe.transform(this.scheduleTaskFormGroup?.get('selectedFromDate')?.value, 'MM/dd/yyyy');
    const endDate = this.datePipe.transform(this.scheduleTaskFormGroup?.get('selectedToDate')?.value, 'MM/dd/yyyy');
    if (option === Frequency.monthly) {
      const dateVal = new Date(date.getFullYear(), date.getMonth() + 1, date.getDate());
      this.formInitialValues.selectedToDate = dateVal ? dateVal : this.formInitialValues.selectedToDate;
      this.minToDate = this.formInitialValues.selectedToDate;
      if (this.getDayDifference(endDate, fromDate) <= 30) {
        const dateVal = new Date(date.getFullYear(), date.getMonth() + 1, date.getDate());
        this.formInitialValues.selectedToDate = dateVal ? dateVal : this.formInitialValues.selectedToDate;
        this.minToDate = this.formInitialValues.selectedToDate;
        this.scheduleTaskFormGroup?.controls['selectedToDate'].setValue(this.formInitialValues.selectedToDate);
      }
    } else if (option === Frequency.weekly) {
      const dateVal = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7);
      this.formInitialValues.selectedToDate = (this.data && this.data.details) ? (this.counter < 2) ? toDate : dateVal :
        (this.getDayDifference(endDate, fromDate) >= 7 && this.counter < 1 && !this.isSubmit) ? toDate : dateVal;
      this.minToDate = this.formInitialValues.selectedToDate;
      if (!this.isSubmit)
        this.scheduleTaskFormGroup?.controls['selectedToDate'].setValue(this.formInitialValues.selectedToDate);
    } else if (option === Frequency.daily) {
      this.formInitialValues.selectedToDate = (this.data && this.data.details && this.counter < 2) ? toDate : date;
      this.scheduleTaskFormGroup?.controls['selectedToDate'].setValue(this.formInitialValues.selectedToDate);
      this.minToDate = this.formInitialValues.selectedToDate;
    }
  }
  nth(d: number): string {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  }

  scheduleTaskCheck(values: Array<string>): boolean {
    const task = this.scheduleTaskFormGroup?.value.scheduleTaskOption;
    return values.includes(task);
  }

  onWeekdayChange(checked: boolean, value: string): void {
    const chkArray = this.scheduleTaskFormGroup?.get('repeatOn') as FormArray;
    if (checked) {
      chkArray.push(new FormControl(value));
    } else {
      const idx = chkArray.controls.findIndex(x => x.value === value);
      chkArray.removeAt(idx);
    }
  }

  checkboxWeekDaySelection(day: string): boolean {
    const chkArray = this.scheduleTaskFormGroup?.get('repeatOn') as FormArray;
    const idx = chkArray.controls.findIndex(x => x.value === day);
    return idx > -1 ? true : false;
  }
  validTime(timeStr: string): boolean {
    if (!timeStr || timeStr.length < 1) { return false; }
    const time: any = timeStr.split(':');
    const now = new Date();
    const nowTime = now.getHours() * 60 + now.getMinutes();
    const dateTime = Number(time[0]) * 60 + (Number(time[1]));
    return (nowTime >= dateTime) ? false : true;
  }

  startTimeValidation(): boolean {
    const fromdate = this.scheduleTaskFormGroup?.get('selectedFromDate')?.value;
    if (!fromdate || fromdate === null) { return false; }
    const today = new Date().toLocaleDateString();
    const startTime = this.scheduleTaskFormGroup?.get('startTime')?.value + ':' + this.scheduleTaskFormGroup?.get('startMinute')?.value;
    const isValid = (fromdate.toLocaleDateString() === today) ? this.validTime(startTime) : true;
    if (!isValid) {
      this.scheduleTaskFormGroup?.controls['startTime'].setErrors({ 'invalid': true });
    } else {
      this.scheduleTaskFormGroup?.controls['startTime'].updateValueAndValidity();
      if (this.scheduleTaskFormGroup?.value.scheduleTaskOption === 8 || this.scheduleTaskFormGroup?.value.scheduleTaskOption === 16) {
        this.setEndDate(this.scheduleTaskFormGroup?.value.scheduleTaskOption);
      } else {
        this.minToDate = this.scheduleTaskFormGroup?.get('selectedFromDate')?.value;
      }
    }
    return isValid;
  }

  onFormSubmit(): void {
    this.isSubmit = true;
    const isValid = this.startTimeValidation();
    if (isValid) {
      document.getElementsByTagName('app-root')[0]?.removeAttribute('aria-hidden');
      this.spinner.show();
      let config: any;
      const formDate = this.datePipe.transform(this.scheduleTaskFormGroup?.get('selectedFromDate')?.value, 'yyyy-MM-dd');
      const endDate = this.datePipe.transform(this.scheduleTaskFormGroup?.get('selectedToDate')?.value, 'yyyy-MM-dd');
      const time = this.scheduleTaskFormGroup?.get('startTime')?.value + ':' + this.scheduleTaskFormGroup?.get('startMinute')?.value;
      const startDate = `${formDate} ${time}:00`;
      if (this.data && this.data.payload) {
        config = this.data.payload;
        config.parameter.flag = 'Scheduledtask';
        config.parameter.schtDescription = this.scheduleTaskFormGroup?.get('taskDescription')?.value;
        config.parameter.schtStartDate = startDate;
        config.parameter.schtEndDate = endDate ? endDate : null;
        config.parameter.schtFreqType = this.scheduleTaskFormGroup?.get('scheduleTaskOption')?.value;
        config.parameter.schtFreqInterval = this.getFreqInterval();
      } else {
        const params = {
          'taskID': this.data.details.schtId,
          'taskDescription': this.scheduleTaskFormGroup?.get('taskDescription')?.value,
          'startDate': startDate,
          'endDate': endDate,
          'intFreqType': this.scheduleTaskFormGroup?.get('scheduleTaskOption')?.value,
          'freqInterval': this.getFreqInterval(),
          'taskName': this.data.details.taskName,
        };
        config = {
          parameter: params,
          environmentVariable: this.data.environmentVariable,
          constantVariable: this.data.constantVariable,
          headerType: 2
        };
      }
      this.onSchedule(config);
      this.isSubmit = false;
    }
  }

  getFreqInterval(): string {
    const selectedOption: any = +this.scheduleTaskFormGroup?.get('scheduleTaskOption')?.value;
    if (selectedOption === Frequency.daily || selectedOption === Frequency.monthly) {
      return this.scheduleTaskFormGroup?.get('occursEvery')?.value.toString();
    } else if (selectedOption === Frequency.weekly) {
      const repeatedOn: any = [];
      this.scheduleTaskFormGroup?.get('repeatOn')?.value.forEach((item: any) => {
        repeatedOn.push(Weeks[item]);
      });
      return repeatedOn.join(',');
    } else {
      return '';
    }
  }
  onSchedule(config: any): void {
    this.scheduledTaskSubscription = this.commonService.postApi(config).subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        this.dialogRef.close('success');
      }
    }, () => {
      this.spinner.hide();
      document.getElementsByTagName('app-root')[0]?.setAttribute('aria-hidden', 'true');
      this.showToast('Something went wrong', 'danger');
    });
  }

  showToast(msg: string, type: string): void {
    this.snackBar.openFromComponent(ToastComponent, {
      panelClass: type,
      data: { label: msg },
    });
  }

  ngOnDestroy(): void {
    if (this.scheduledTaskSubscription) {
      this.scheduledTaskSubscription.unsubscribe();
    }
  }
}
