import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'htmlToContent'
})
export class HtmlToContentPipe implements PipeTransform {

  transform(value: any): any {
    return (typeof value === 'string' && value) ? value.replace(/<[^>]*>/g, ' ').trim() : '';

  }

}
