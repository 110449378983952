<ng-container *ngIf="!isExpansionEnabled">
  <header *ngIf="!showNavbarSearch && headerType===''" [ngClass]="{'new-user-container': isNewUser}">
    <div class="d-flex w-100 justify-content-between align-items-center top-nav-container position-fixed">
      <div class="d-flex">
        <ng-container>
          <!-- <button class="d-md-none navbar-toggler-btn bg-transparent border-0 ps-0"
            [matMenuTriggerFor]="toggleBtnMenulist" aria-label="page menu set">
            <span class="smart-icon-hamburger-menu"></span>
          </button> -->

          <mat-menu #toggleBtnMenulist="matMenu" class="toggleBtnMenu">
            <!-- <button mat-menu-item class="tab-name text-uppercase">Demand
            </button>
            <button mat-menu-item class="tab-name text-uppercase">Team
            </button>
            <button mat-menu-item class="tab-name text-uppercase">Request
            </button>
            <button mat-menu-item class="tab-name text-uppercase">Reports
            </button>
            <button mat-menu-item class="tab-name text-uppercase">ASE Forecasting
            </button> -->
          </mat-menu>
        </ng-container>

        <a routerLink="/" class="text-decoration-none navbar-brand application-name">
          SMART
        </a>
      </div>
      <div class="navigation-menu-center d-none d-md-block">
        <ul class="navmenu h-100 d-flex m-0 p-0 flex-wrap">
          <li appFeatureHide class="h-100"><a routerLinkActive="active-link"
              class="h-100 nav-link d-flex align-items-center px-2 px-lg-3 text-uppercase"
              routerLink="/resource-management">
              Talent</a></li>
          <li appFeatureHide class="h-100"><a routerLinkActive="active-link"
              class="h-100 nav-link d-flex align-items-center px-2 px-lg-3 text-uppercase" routerLink="/request">
              Request</a></li>
          <li appFeatureHide class="h-100" *ngIf="checkLdFlag('Ase_Forecast_Access')"><a
              class="h-100 nav-link d-flex align-items-center px-2 px-lg-3 text-uppercase" [attr.href]="aseUrl"
              target="_blank">
              ASE STAFFING</a></li>
          <!--    <li class="h-100" [ngClass]="pageName==='demand'?'tab-active':''"><a href=" #"
              class="h-100 nav-link d-flex align-items-center px-2 px-lg-3 text-uppercase" (click)="pageName='demand'">
              Demand</a></li>
           <li class="h-100" [ngClass]="pageName==='team'?'tab-active':''"><a href="#"
              class="h-100 nav-link d-flex align-items-center px-2 px-lg-3 text-uppercase"
              (click)="pageName='team'">Team</a></li>
          <li class="h-100" [ngClass]="pageName==='request'?'tab-active':''"><a href="#"
              class="h-100 nav-link d-flex align-items-center px-2 px-lg-3 text-uppercase"
              (click)="pageName='request'">Request</a></li>
          <li class="h-100" [ngClass]="pageName==='report'?'tab-active':''"><a href="#"
              class="h-100 nav-link d-flex align-items-center px-2 px-lg-3 text-uppercase"
              (click)="pageName='report'">Reports</a></li>
          -->
        </ul>
      </div>
      <div class="navigation-menu-right">
        <ul class="d-flex navbar-right-section-btns p-0 m-0 list-unstyled">
          <!-- <li type="button" class="navbar-buttons py-0" aria-label="search">
            <button aria-label="Search"
              class="smart-btn smart-btn-link bg-transparent border-0 smart-icon-search-lens h-100"
              (click)="showNavbarSearch = true"></button>
          </li>
          <li type="button" class="navbar-buttons  py-0">
            <button aria-label="Settings"
              class="smart-btn smart-btn-link bg-transparent border-0 smart-icon-gear h-100"></button>
          </li> -->
          <!-- <li type="button" class="navbar-buttons  py-0">
            <button aria-label="Tools"
              class="smart-btn smart-btn-link bg-transparent border-0 smart-icon-spanner h-100"></button>
          </li> -->
          <li type="button" class="navbar-buttons navbar-button-dropdown">
            <button class="smart-btn smart-btn-link border-0 h-100" #dotMenu="matMenuTrigger"
              [matMenuTriggerFor]="dotmenulist" aria-label="page menu set"><span
                class="smart-icon-menu"></span></button>
          </li>
          <mat-menu #dotmenulist="matMenu" class="dotmenulist">
            <button *ngIf="ldFlags && checkLdFlag('New_Bulk_Upload_UI') && showUploadTab" mat-menu-item
              class="overflow-visible justify-content-center align-items-center align-self-stretch"
              (click)="routeTo('/bulk-upload')">
              <span class="smart-icon-upload"></span>
              <span class="icon-label mb-3">Upload</span>
            </button>
            <ng-container appFeatureHide #ref="appFeatureHide">
              <button *ngIf="ref.isVisible" mat-menu-item
                class="overflow-visible justify-content-center align-items-center align-self-stretch">
                <span class="smart-icon-library"></span>
                <span class="icon-label mb-3">Library</span>
              </button>
            </ng-container>
            <button mat-menu-item class="overflow-visible justify-content-center align-items-center align-self-stretch"
              (click)="routeTo('/audit-trail')" *ngIf="userAccessData.auditTrailIconFlag">
              <span class="smart-icon-audit-trail"></span>
              <span class="icon-label mb-3 text-center">Audit Trail</span>
            </button>
            <button mat-menu-item class="overflow-visible justify-content-center align-items-center align-self-stretch"
              (click)="routeTo('/maintain-master')" *ngIf="userAccessData.maintainMastersIconFlag">
              <span class="smart-icon-maintain-masters"></span>
              <span class="icon-label mb-3 text-center">Maintain Masters</span>
            </button>
            <button mat-menu-item class="overflow-visible justify-content-center align-items-center align-self-stretch"
              (click)="routeTo('/queued-tasks')">
              <span class="smart-icon-download"></span>
              <span class="icon-label mb-3 text-center">Queued Tasks</span>
            </button>
            <button mat-menu-item class="overflow-visible justify-content-center align-items-center align-self-stretch"
              (click)="routeTo('/scheduled-tasks')">
              <span class="smart-icon-scheduled-task"></span>
              <span class="icon-label mb-3 text-center">Scheduled Tasks</span>
            </button>
            <button *ngIf="ldFlags && checkLdFlag('ATCI_SearchEditProject_Icon_Upgrade')" mat-menu-item
              class="overflow-visible justify-content-center align-items-center align-self-stretch"
              (click)="routeTo('/project')">
              <span class="smart-icon-project"></span>
              <span class="icon-label mb-3 text-center">Project</span>
            </button>
            <button mat-menu-item class="overflow-visible justify-content-center align-items-center align-self-stretch"
              (click)="routeTo('/contents/more-actions')"
              *ngIf="ldFlags && checkLdFlag('More_Actions_UI') && checkRights('ViewMoreActionsPage')">
              <span class="smart-icon-more-action"></span>
              <span class="icon-label mb-3 text-center">More Actions</span>
            </button>
          </mat-menu>
          <li type="button" class="navbar-buttons navbar-button-dropdown align-items-center user-profile-menu"
            aria-label="profile" matTooltip="{{toolTipMsg}}" matTooltipClass="profile-tooltip"
            matTooltipPosition="below" *ngIf="isDisplayProfile">
            <button data-toggle="dropdown" class="smart-btn smart-btn-link border-0 h-100 prof-img" mat-icon-button
              [matMenuTriggerFor]="isMenuTrigger() ? null : menu" [disabled]="isMenuTrigger()">
              <img src="../../../../assets/images/profile-img.png" alt="{{toolTipMsg}}" class="img-circle" />
            </button>
          </li>
          <mat-menu #menu="matMenu" class="profile-menu-list">
            <button *ngIf="ldFlags && checkLdFlag('Navigation_My_Access_Page')" mat-menu-item
              (click)="routeTo('/my-access')">
              <span class="smart-icon-access profile-menu-icon ps-1 pe-2"></span>
              <span class="icon-label graphik-semi-bold">My Access</span>
            </button>
            <button *ngIf="isAdminRole()" mat-menu-item (click)="routeTo('/administration')">
              <span class="smart-icon-admin profile-menu-icon ps-1 pe-2"></span>
              <span class="icon-label graphik-semi-bold">Administration</span>
            </button>
            <button mat-menu-item (click)="signOut()">
              <span class="smart-icon-exit profile-menu-icon ps-1 pe-2"></span>
              <span class="icon-label graphik-semi-bold">Sign Out</span>
            </button>
          </mat-menu>
        </ul>
      </div>
    </div>
  </header>


  <header *ngIf="showNavbarSearch">
    <div class="container-fluid position-fixed top-nav-container">
      <div class="search-header row align-items-center px-3">
        <label for="search" class="col-2 m-0 p-0 search-label text-center">SEARCH</label>
        <div class="col-8 h-100 search-input-field-container position-relative">
          <input matInput class="w-100 h-100 search-input-field border-0" aria-label="State" [matAutocomplete]="auto"
            [formControl]="searchOptionCtrl">
          <mat-autocomplete #auto="matAutocomplete" class="header-search-dropdown-options">
            <mat-option *ngFor="let option of filteredsearchoption | async" [value]="option.name">
              <div class="dropdown-options d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <a href="" class="m-0">{{option.name}}</a>
                </div>
              </div>
            </mat-option>
          </mat-autocomplete>
          <button aria-label="search arrow button"
            class="smart-icon-arrow search-btn-icon bg-transparent border-0 position-absolute"></button>
        </div>
        <div class="col-2 d-flex justify-content-center">
          <button aria-label="close search" (click)="showNavbarSearch=false"
            class="close-search-btn bg-transparent border-0 smart-icon-add"></button>
        </div>

      </div>
    </div>
  </header>

  <header class="create-demand-header" *ngIf="headerType==='CREATE_DEMAND'">
    <div class="d-flex align-items-center top-nav-container position-fixed w-100">
      <a routerLink="/" aria-label="Back Button"
        class="text-decoration-none navbar-brand application-name back-icon me-3">
        <span class="smart-icon-arrow-down"></span> </a>
      <h1 class="m-0">Create Demand</h1>
    </div>
  </header>
</ng-container>