import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConstantData } from 'src/app/shared/constant';
import { environment } from '../../../environments/environment';
import { ToastComponent } from 'src/app/shared/components/toast/toast.component';
import { MatSnackBar } from '@angular/material/snack-bar';

interface ApiConfig {
  parameter?: any,
  environmentVariable: string,
  constantVariable: string,
  headerType?: number,
  headers?: any,
  responseType?: any,
  params?: any
}
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private hideHeader = new BehaviorSubject(false);
  hideHeaderObservable = this.hideHeader.asObservable();
  private headerType = new BehaviorSubject('');
  headerTypeObservable = this.headerType.asObservable();
  private stepperData = new BehaviorSubject('');
  headersType1 = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: '*/*'
  });

  headersType2 = new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  });
  envData: any = environment;
  constantData: any = ConstantData;
  public isExpandEnabled: EventEmitter<any> = new EventEmitter();
  public pageName: string | undefined;
  constructor(private httpClient: HttpClient, private snackBar: MatSnackBar) { }

  updateHideHeader(value: boolean): void {
    this.hideHeader.next(value);
  }
  updateHeaderType(value: string): void {
    this.headerType.next(value);
  }
  updateStepperData(value: any): void {
    this.stepperData.next(value);
  }
  public isExpandable(value: any): void {
    this.isExpandEnabled.emit(value);
  }

  public postApi(config: ApiConfig): Observable<any> {
    const url: any = this.envData[config.environmentVariable] + this.constantData[config.constantVariable];
    if (config.params) {
      return this.httpClient.post<any>(url, config.parameter, {
        params: config.params
      });
    }
    else if (config.headers) {
      return this.httpClient.post<any>(url, config.parameter, {
        headers: config.headers,
        responseType: config.responseType ? config.responseType : undefined
      });
    } else {
      return this.httpClient.post<any>(url, config.parameter, { headers: config.headerType === 2 ? this.headersType2 : this.headersType1 });
    }
  }

  public getApi(config: ApiConfig): Observable<any> {
    const url = this.envData[config.environmentVariable] + this.constantData[config.constantVariable];
    if (config.headers) {
      return this.httpClient.get<any>(url,
        {
          headers: config.headers,
          params: config.parameter
        });
    } else if (config.parameter) {
      return this.httpClient.get<any>(url,
        {
          headers: config.headerType === 2 ? this.headersType2 : this.headersType1,
          params: config.parameter,
          responseType: config.responseType ? config.responseType : undefined
        });
    } else {
      return this.httpClient.get<any>(url);
    }
  }
  public setPageName(value: string): any {
    this.pageName = value;
  }
  public getPageName(): any {
    return this.pageName;
  }

  public getJSONData(url: string): Observable<any> {
    return this.httpClient.get<any>(url);
  }

  public getListWidth(filteredTags: any): number {
    let totalListWidth = 0;
    let hideCount = 0;
    if (filteredTags) {
      const containerWidth = filteredTags.nativeElement.offsetWidth;
      const elements = filteredTags.nativeElement.querySelectorAll('li');

      for (const element of elements) {
        totalListWidth = totalListWidth + (element.offsetWidth + 10);
      }
      if (containerWidth < totalListWidth) {
        let visibleWidth = 0;
        for (const element of elements) {
          visibleWidth = visibleWidth + (element.offsetWidth + 10);
          if (visibleWidth > containerWidth) {
            hideCount = hideCount + 1;
          }
        }
      }
    }
    return hideCount;
  }

  showToastMsg(msg: string, type: string): void {
    this.snackBar.openFromComponent(ToastComponent, {
      panelClass: type,
      data: {
        label: msg
      },
    });
  }
  sanitizeJSON(json: any, param1: any = undefined): any {
    if (json === undefined || json === null || json === '') {
      return json;
    }
    const sanitizedJSON = JSON.parse(param1 !== undefined ? JSON.stringify(json, param1)
      : typeof json === 'string' ? json : JSON.stringify(json));
    Object.keys(sanitizedJSON).forEach(key => {
      if (sanitizedJSON[key] === null || sanitizedJSON[key] === undefined) {
        delete sanitizedJSON[key];
      } else if (typeof sanitizedJSON[key] === 'string') {
        sanitizedJSON[key] = sanitizedJSON[key]
          .replace(/&/g, '&amp;')
          .replace(/</g, '&lt;')
          .replace(/>/g, '&gt;')
          .replace(/"/g, '&quot;')
          .replace(/'/g, '&#039;');
      }
    });
    return sanitizedJSON;
  }
}  
