<div class="smart-container">
    <section class="header-section d-flex justify-content-between pt-4">
        <h1 class="label-heading mb-0" title="New Access Request">New Access Request</h1>
        <button aria-label="Close New request" class="smart-icon-close btn-close opacity-100"
            (click)="onClose()"></button>
    </section>
</div>
<div class="new-request-container">
    <mat-drawer-container autosizes>
        <button type="button" class="btn-collapse text-white position-fixed border-0 mt-4" aria-label="Toggle Side Menu"
            (click)="drawer.toggle(); isDrawerToogle = !isDrawerToogle"
            [ngClass]="isDrawerToogle ? 'smart-icon-close-double-arrow' : 'smart-icon-open-double-arrow'">
        </button>
        <mat-drawer #drawer mode="side" opened class="mt-4">

            <div class="search-input-block">
                <input type="text" id="roleSearch" class="form-control" aria-label="search roles"
                    placeholder="Search the required role..." (input)="onSearchRole()" [(ngModel)]="searchText">
            </div>
            <div class="custom-overflow-scroll overflow-auto accordion-container">
                <mat-accordion hideToggle="false">
                    <mat-expansion-panel class="m-0" *ngFor="let role of roleList;let i = index">
                        <mat-expansion-panel-header role="presentation">
                            <mat-panel-description>
                                <mat-radio-group [(ngModel)]="selectedRole">
                                    <mat-radio-button [value]="role" id="input{{role.roleName}}{{i}}"
                                        (change)="onRoleSelection($event)">
                                        <mat-panel-title [attr.aria-description]="role.roleComment"
                                            class="text-black graphik-medium mx-2 text-wrap">{{role.roleAcronym}}</mat-panel-title>
                                    </mat-radio-button>
                                </mat-radio-group>
                                <span class="smart-icon-moreInfo info-icon" [attr.aria-label]="role.roleComment"
                                    matTooltipClass="offwhite-tooltip" matTooltipPosition="below"
                                    [matTooltip]="role.roleComment"></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <ul class="list-group overflow-hidden rounded-0 text-black">
                            <li class="ps-5 graphik-regular" *ngFor="let roleAccess of role.rights">
                                {{roleAccess}}
                            </li>
                        </ul>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </mat-drawer>

        <div class="ms-5 me-3 me-xl-5 pt-4">
            <!--STEPPER HERE-->
            <mat-stepper orientation="vertical" linear #stepper class="smart-vertical-stepper mb-0">
                <mat-step [stepControl]="roleFormGroup"
                    [state]="stateChange(stepper, raiseRequestStep.Role, roleFormGroup)">
                    <form *ngIf="roleFormGroup" [formGroup]="roleFormGroup">
                        <ng-template matStepLabel>
                            1. Select role
                        </ng-template>
                        <div class="row">
                            <div class="col-12 col-lg stepper-form-content d-none d-lg-block"></div>
                            <div class="col-12 col-lg stepper-form stepper-form-block" *ngIf="selectedRole">
                                <label for="userRoleSelection" class="form-label m-0">Role <span
                                        class="mandatory-field">*</span>
                                </label>
                                <input type="text" id="userRoleSelection" class="form-control py-2"
                                    aria-label="search roles" formControlName="role" readonly>
                            </div>
                        </div>
                    </form>
                </mat-step>

                <mat-step [stepControl]="teamFormGroup"
                    [state]="stateChange(stepper, raiseRequestStep.Team, teamFormGroup)">
                    <form *ngIf="teamFormGroup" [formGroup]="teamFormGroup">
                        <ng-template matStepLabel>
                            2. Select team
                        </ng-template>
                        <div class="row">
                            <div class="col-12 col-lg stepper-form-content d-none d-lg-block"></div>
                            <div class="col-lg col-12 stepper-form stepper-form-block">
                                <label for="teamUserGroup" class="form-label m-0">Team
                                    <span class="mandatory-field">*</span>
                                </label>
                                <ng-select labelForId="teamUserGroup" [clearable]="false" placeholder="Select Team"
                                    class="smart-ng-select filter-dropdown-selection" [items]="teamUserGroupList"
                                    formControlName="team" appendTo="body" appNgSelect="team User Group"
                                    bindLabel="ugAcronym" (change)="onChangeEvent(stepper, raiseRequestStep.Team)">
                                </ng-select>
                            </div>
                        </div>
                    </form>
                </mat-step>

                <mat-step [stepControl]="subTeamFormGroup"
                    [state]="stateChange(stepper, raiseRequestStep.Subteam, subTeamFormGroup)">
                    <form *ngIf="subTeamFormGroup" [formGroup]="subTeamFormGroup">
                        <ng-template matStepLabel>
                            3. Select Sub-Team
                        </ng-template>
                        <div class="row">
                            <div class="col-12 col-lg stepper-form-content d-none d-lg-block"></div>
                            <div class="col-12 col-lg stepper-form stepper-form-block">
                                <label for="subTeamUserGroup" class="form-label m-0">Sub-Team
                                    <span class="mandatory-field">*</span>
                                </label>
                                <input #autoCompleteInput="matAutocompleteTrigger" class="form-control" type="text"
                                    placeholder="Type minimum 2 characters or select below" name="subTeamUserGroup"
                                    formControlName="subTeam" [matAutocomplete]="autoCompleteSubTeam"
                                    id="subTeamUserGroup">
                                <mat-autocomplete #autoCompleteSubTeam="matAutocomplete" autoActiveFirstOption
                                    (optionSelected)="optionSelected()" class="auto-complete-search">
                                    <mat-option disabled class="loading" *ngIf="autoCompleteSubTeamLoader">
                                        Loading...
                                    </mat-option>
                                    <ng-container *ngIf="!autoCompleteSubTeamLoader">
                                        <ng-container
                                            *ngIf="selectedSubTeamList.length === maxSelectionLimit; else maximumSelection">
                                            <mat-option disabled>
                                                Maximum 20 sub-teams can be selected
                                            </mat-option>
                                        </ng-container>
                                    </ng-container>
                                    <ng-template #maximumSelection>
                                        <mat-option *ngFor="let option of filteredOptions" [value]="option">
                                            {{option?.ugmName}}
                                        </mat-option>
                                    </ng-template>
                                </mat-autocomplete>
                                <div *ngIf="selectedSubTeamList.length > 0"
                                    class="d-flex flex-wrap my-3 mb-4 custom-overflow-scroll tag-container overflow-auto">
                                    <div *ngFor="let subTeam of selectedSubTeamList;"
                                        class="dropdown-tags tag-block d-flex align-items-center justify-content-between me-2 mb-2">
                                        <span class="py-1 ps-2 text-truncate tag-content"
                                            title="{{subTeam.ugmName}}">{{subTeam.ugmName}}</span>
                                        <button class="smart-icon-close smart-btn btn py-1" aria-label="close button"
                                            (click)="removeSelections(subTeam)"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </mat-step>

                <mat-step [stepControl]="accessReasonFormGroup"
                    [state]="stateChange(stepper, raiseRequestStep.AccessReason, accessReasonFormGroup)">
                    <form *ngIf="accessReasonFormGroup" [formGroup]="accessReasonFormGroup">
                        <ng-template matStepLabel>
                            4. Reason for Access
                        </ng-template>
                        <div class="row">
                            <div class="col-12 col-lg stepper-form-content d-none d-lg-block"></div>
                            <div class="col-12 col-lg stepper-form stepper-form-block">
                                <div class="d-flex justify-content-between">
                                    <label for="accessReason" class="form-label m-0">Reason for Access
                                        <span class="mandatory-field">*</span>
                                    </label>
                                    <span class="form-label">{{maxLength - textLength.value.length}} characters
                                        only</span>
                                </div>
                                <textarea id="accessReason" rows="3" class="w-100 form-control"
                                    formControlName="accessReason" #textLength maxlength="500"></textarea>
                            </div>
                        </div>
                    </form>
                </mat-step>
            </mat-stepper>
            <div class="mb-5 pb-xl-4 mt-2">
                <span class="radio-label" id="trainingLabel">Have you completed the Mandatory Training from Guide Me >
                    Teach Me?
                    <span class="mandatory-field">*</span></span>
                <div class="mt-2">
                    <mat-radio-group aria-labelledby="trainingLabel" [(ngModel)]="selectedTrainingValue">
                        <mat-radio-button class="pe-4" value="Y"> Yes</mat-radio-button>
                        <mat-radio-button value="N"> No </mat-radio-button>
                    </mat-radio-group>
                    <div class="mandatory-field graphik-semi-bold mt-2" *ngIf="selectedTrainingValue === 'N'">
                        Complete the mandatory SMART training using the “Guide Me” option on the right to receive access
                        to SMART.
                    </div>
                </div>
            </div>

            <div class="d-flex align-item-center mb-xl-1 action-container flex-wrap">
                <span class="error-validation-msg graphik-semi-bold mb-3 w-100 text-end" *ngIf="errorMessage!== ''">
                    {{errorMessage}}
                </span>
                <div class="pb-4">
                    <p class="mb-0">Further queries, please reach out to</p>
                    <a href="mailto:smartsupply.support@accenture.com">smartsupply.support&#64;accenture.com</a>
                </div>
                <div class="ms-auto py-2 py-md-0">
                    <button class="btn btn-link text-decoration-none me-3 smart-btn smart-btn-link"
                        (click)="onReset()">RESET</button>
                    <button id="btnRaiseRequest" class="btn smart-btn smart-primary-btn"
                        [disabled]="!accessReasonFormGroup.valid || selectedTrainingValue === null"
                        (click)="raiseAccessRequest()">RAISE REQUEST
                    </button>
                </div>
            </div>
            <!--STEPPER END-->
        </div>

    </mat-drawer-container>
</div>