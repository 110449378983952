import { Component, EventEmitter, Input, Output, ViewChild, OnInit, SimpleChanges, SimpleChange, OnChanges } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { dynamicColumnType } from '../../model/common.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastComponent } from '../toast/toast.component';
import { MaintainMasterService } from '../../services/maintain-master.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { CommonService } from 'src/app/core/services/common.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-dynamic-data-grid',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss']
})
export class DynamicDataGridComponent implements OnInit, OnChanges {
  @Input() columnDef: Array<dynamicColumnType> | undefined;
  @Input() data: any;
  @Input() masterDetails: any;
  @ViewChild(MatTable, { static: true }) table: MatTable<any> | undefined;
  @ViewChild(MatSort) sort?: MatSort | any;
  @ViewChild(MatPaginator) paginator?: MatPaginator | any;
  @Output() masterCode = new EventEmitter();
  @Output() addBtn = new EventEmitter();
  displayedColumns: any[] = [];
  dataSource: MatTableDataSource<object> | any = [];
  crossIconDisable = false;
  confirmIconDisable = false;
  displayNameDropdown = '';
  editBtnHide = true;
  confirmFlag = false;
  tableDropdwnDataArray: any = [];
  toggleDisable = false;
  PopupDescToggle: any;
  pageLength: any;
  submitted: Boolean = false;
  validationField = true;
  dropdownArray: any = {};
  addBtnHide = true;
  disableSlide = true;
  columNameSort: any;
  sortOrder: any;
  pageNumber: any;
  dropDownDataArray: any = [];
  dataArrayList: any = [];
  dataTypeInput: any;
  resetAddflag: boolean;
  careerData: Array<any>;
  careerFlag: number;
  validationArray: any;
  validArray: any = {};
  dateFacility: any;
  minDate: Date | undefined;
  repDropdownArray: any = {};
  projectMasterArray: any;
  submitError: boolean;
  requiredFieldArray: Array<any>;
  disableDate: boolean;
  enableRepLocName: boolean;
  locEnable: boolean;
  dateFlag: number;
  eraserHide: boolean;
  dateErrorValidation: boolean;
  cityEditAction: boolean;
  gridDataTemp: Array<any>;
  approvergridDataTemp: Array<any>;
  submitApprover: boolean;
  activateColumn: number;
  approverID: number;
  constructor(public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private masterService: MaintainMasterService,
    public spinner: NgxSpinnerService,
    public datepipe: DatePipe,
    private commonService: CommonService
  ) {
    this.careerFlag = 1;
    this.resetAddflag = false;
    this.careerData = [];
    this.validationArray = [];
    this.projectMasterArray = [];
    this.submitError = false;
    this.requiredFieldArray = [];
    this.disableDate = false;
    this.enableRepLocName = true;
    this.locEnable = false;
    this.dateFlag = 0;
    this.eraserHide = false;
    this.dateErrorValidation = true;
    this.cityEditAction = false;
    this.gridDataTemp = [];
    this.approvergridDataTemp = [];
    this.submitApprover = false;
    this.activateColumn = 1;
    this.approverID = 1;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const dataVal: SimpleChange = changes['data'];
    this.data = dataVal.currentValue;
    this.approvergridDataTemp = this.commonService.sanitizeJSON(dataVal.currentValue);
    this.tableDisplayDetails();
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource([]);
    this.columnDef?.forEach((d) => {
      if (d.MMC_HIDDEN === 'N') {
        this.displayedColumns.push(d.MMC_COLNAME);
      }
    });
    this.tableDisplayDetails();
  }

  tableDisplayDetails(): void {
    this.dropdownArray = {};
    this.columnDef?.sort((a: any, b: any) => a.MMC_COLINDEX - b.MMC_COLINDEX);
    if (this.masterDetails.masterEdit === 'N' && this.masterDetails.masterName === 'City') {
      this.cityEditAction = true;
    } else {
      this.cityEditAction = false;
    }
    this.columnDef?.forEach((d) => {
      for (let i = 0; i < this.data.length; i++) {
        if (d.MMC_ELEMENTTYPE === 'toggle') {
          if (this.data[i][d.MMC_COLNAME] === 'N' || this.data[i][d.MMC_COLNAME] === false) {
            this.data[i][d.MMC_COLNAME] = false;
          } else {
            this.data[i][d.MMC_COLNAME] = true;
          }
        } else if (d.MMC_ELEMENTTYPE === 'datepicker' && this.careerFlag !== 2) {
          this.data[i][d.MMC_COLNAME] = this.datepipe.transform(this.data[i][d.MMC_COLNAME], 'dd-MMM-yyyy');
        } else if (this.careerFlag === 2) {
          this.editBtnHide = false;
        }
      }
    });
    this.toggleDisable = true;
    if (this.data[0].isEdit && this.resetAddflag === true) {
      this.dataSource.data.splice(this.data[0], 0);
      this.data = this.dataSource.data;
      this.dataSource = new MatTableDataSource(this.data);
    } else {
      this.dataSource = new MatTableDataSource(this.data);
    }
    this.tableDropdwnDataArray = this.columnDef?.filter(s => s.MMC_ELEMENTTYPE === 'dropdown');
    if (this.tableDropdwnDataArray.length !== 0) {
      this.tableDropdwnDataArray.forEach((element: any) => {
        this.dropdownArray[element.MMC_COLNAME] = element.MMC_PREFILLDATA;
      });
    }
    this.validationArray = this.columnDef?.filter(s => s.MMC_VALIDATIONLIST !== null && s.MMC_COLNAME !== 'isEdit'
      && s.MMC_ELEMENTTYPE === 'textbox');
    if (this.validationArray.length !== 0) {
      this.validationArray.forEach((element: any) => {
        this.requiredFieldArray = element.MMC_VALIDATIONLIST?.filter((s: any) => s.TriggerEvent === 'CHANGE');
        this.validArray[element.MMC_COLNAME] = this.requiredFieldArray[0]?.EventValidationInfo[0];
      });
    }
    this.pageLength = this.masterDetails.pageLength;
  }

  addNewRow(): void {
    this.submitted = false;
    this.resetAddflag = false;
    const columnNames: any = {};
    this.careerFlag = 1;
    this.columnDef?.forEach((d) => {
      if (d.MMC_HIDDEN === 'N') {
        columnNames[d.MMC_COLNAME] = '';
        columnNames.isEdit = true;
      }
      if (d.MMC_ELEMENTTYPE === 'toggle') {
        columnNames[d.MMC_COLNAME] = true;
        this.disableSlide = true;
      }
    });
    this.data.unshift(columnNames);
    if (this.data[1].isEdit) {
      this.dataSource.data.splice(this.data[0], 1);
      this.dataSource._updateChangeSubscription();
    } else if (this.data[0].isEdit) {
      this.dataSource = new MatTableDataSource(this.data);
      this.editBtnHide = false;
      this.crossIconDisable = true;
      this.confirmIconDisable = true;
      this.confirmFlag = true;
    }
  }
  validationError(data: any): void {
    this.dateFlag = 1;
    if (this.projectMasterArray === null || this.projectMasterArray.length === 0) {
      this.submitError = true;
      data.PWL_REP_LOC_NAME = '';
      this.eraserHide = true;
    }
  }
  dateRepLocEnable(): void {
    if (this.disableDate) {
      this.enableRepLocName = true;
    } else {
      this.enableRepLocName = false;
      this.dateFacility = '29-Feb-2023';
    }
  }

  editRow(ele: any, i: any): void {
    this.disableSlide = false;
    this.addBtnHide = false;
    this.addBtn.emit({ addBtnStatus: this.addBtnHide });
    this.columnDef?.forEach((d) => {
      if (ele[d.MMC_COLNAME] === false) {
        this.confirmIconDisable = false;
        this.crossIconDisable = true;
      } else if (ele[d.MMC_COLNAME] === true) {
        this.crossIconDisable = true;
        this.confirmIconDisable = true;
      } else if (ele.PWL_FAC_END_DATE !== '' || ele.PWL_FAC_END_DATE === null) {
        this.careerFlag = 2;
        if (ele.PWL_FAC_END_DATE !== null) {
          ele['PWL_FAC_END_DATE'] = new Date(ele.PWL_FAC_END_DATE);
        }
        this.dateFacility = ele.PWL_FAC_END_DATE;
      }
    });
    this.editBtnHide = false;
    this.confirmFlag = false;
    if (this.masterDetails.masterName === 'Project/Work Location') {
      const param = {
        'MasterCode': this.masterDetails.mastercode,
        'Action': 'E',
        'MastersData': [
          {
            'RowIndex': i + 1,
            'RowData': {
              'PWL_ID': ele.PWL_ID,
              'PWL_NAME': ele.PWL_NAME,
              'TZM_OL_ID': ele.TZM_OL_ID
            }
          }
        ],
        'UserDetails': this.masterDetails.userAccessDetails
      };
      this.spinner.show();

      const config = {
        parameter: param,
        params: { code: environment.getMasterGridRowData },
        environmentVariable: 'MasterbasePublicUrl',
        constantVariable: 'getMasterGridRowData'
      };

      this.commonService.postApi(config).subscribe((response: any) => {
        this.spinner.hide();
        this.projectMasterArray = response.MastersData;
        if (this.projectMasterArray.length !== 0) {
          this.projectMasterArray.forEach((element: any) => {
            this.repDropdownArray[element.CUSTOM_ROWDATA.FILLDATA[0].COLNAME] = element.CUSTOM_ROWDATA.FILLDATA[0].DATA;
          });
        }
      });
      const params = {
        'MasterCode': this.masterDetails.mastercode,
        'Export': 1,
        'GridOptions': {
          'FilterData': null,
          'SortCol': '',
          'SortOrder': ''
        },
        'UserDetails': this.masterDetails.userAccessDetails
      };
      this.spinner.show();
      const configs = {
        parameter: params,
        params: { code: environment.exportMasterByMasterID },
        environmentVariable: 'MasterbasePublicUrl',
        constantVariable: 'exportMasterByMasterID'
      };

      this.commonService.postApi(configs).subscribe((res: any) => {
        this.spinner.hide();
        this.careerData = res.MastersData;
        this.eraserHide = true;
        const projectLocArray = this.careerData?.filter((s: any) => (s.OL_NAME === ele.OL_NAME));
        this.disableDate = false;
        if (projectLocArray.length === 1) {
          this.disableDate = true;
        } else if (ele.PWL_FAC_END_DATE !== null || ele.PWL_FAC_END_DATE === null) {
          const date: Date = new Date();
          this.minDate = new Date(date.setDate(date.getDate() + 7));
          const currentDate: Date = new Date(ele.PWL_FAC_END_DATE);
          if (currentDate < this.minDate && ele.PWL_FAC_END_DATE !== null) {
            this.disableDate = true;
            this.eraserHide = false;
          }
        }
      });
    }
  }
  approverMatrixData(selectdata: any, i: any): void {
    this.gridDataTemp = Object.assign([], this.approvergridDataTemp);
    if ((selectdata.USER_ROLE === 'Role' && this.gridDataTemp[i].USER_ROLE === 'User') ||
      (selectdata.USER_ROLE === 'User' && this.gridDataTemp[i].USER_ROLE === 'Role') ||
      (selectdata.USER_ROLE === 'User' && this.gridDataTemp[i].USER_ROLE === 'User') ||
      (selectdata.USER_ROLE === 'Role' && this.gridDataTemp[i].USER_ROLE === 'Role')) {
      if (selectdata.USER_ROLE === 'Role' && this.gridDataTemp[i].P_APPR_ENTERPRISE_ID !== null
        && selectdata.P_ACCESS_APPROVER_TYPE === null) {
        selectdata.P_ACCESS_APPROVER_TYPE = undefined;
        selectdata.P_ACCESS_APPROVER_ROLE = undefined;
        selectdata.P_APPR_ENTERPRISE_ID = null;
      } else if (selectdata.USER_ROLE === 'User' && (this.gridDataTemp[i].P_APPR_ENTERPRISE_ID !== null ||
        this.gridDataTemp[i].S_APPR_ENTERPRISE_ID !== null)) {
        selectdata.P_APPR_ENTERPRISE_ID = this.gridDataTemp[i].P_APPR_ENTERPRISE_ID;
        selectdata.S_APPR_ENTERPRISE_ID = this.gridDataTemp[i].S_APPR_ENTERPRISE_ID;
      } else if (selectdata.USER_ROLE === 'User' && (this.gridDataTemp[i].P_ACCESS_APPROVER_TYPE !== null ||
        this.gridDataTemp[i].P_ACCESS_APPROVER_ROLE !== null)) {
        selectdata.P_APPR_ENTERPRISE_ID = undefined;
        selectdata.P_ACCESS_APPROVER_TYPE = null;
        selectdata.P_ACCESS_APPROVER_ROLE = null;
      } else if ((selectdata.USER_ROLE === 'Role' && (this.gridDataTemp[i].P_ACCESS_APPROVER_TYPE !== null ||
        this.gridDataTemp[i].P_ACCESS_APPROVER_ROLE !== null
        || this.gridDataTemp[i].S_ACCESS_APPROVER_TYPE !== null || this.gridDataTemp[i].S_ACCESS_APPROVER_ROLE) &&
        (selectdata.P_ACCESS_APPROVER_TYPE === null || selectdata.S_ACCESS_APPROVER_TYPE === null))) {
        selectdata.P_ACCESS_APPROVER_TYPE = this.gridDataTemp[i].P_ACCESS_APPROVER_TYPE;
        selectdata.P_ACCESS_APPROVER_ROLE = this.gridDataTemp[i].P_ACCESS_APPROVER_ROLE;
        selectdata.S_ACCESS_APPROVER_TYPE = this.gridDataTemp[i].S_ACCESS_APPROVER_TYPE;
        selectdata.S_ACCESS_APPROVER_ROLE = this.gridDataTemp[i].S_ACCESS_APPROVER_ROLE;
      }
      if (selectdata.USER_ROLE === 'User' && (this.gridDataTemp[i].S_ACCESS_APPROVER_TYPE !== null ||
        this.gridDataTemp[i].S_ACCESS_APPROVER_ROLE !== null)) {
        selectdata.S_APPR_ENTERPRISE_ID = undefined;
        selectdata.S_ACCESS_APPROVER_TYPE = null;
        selectdata.S_ACCESS_APPROVER_ROLE = null;
      } else if (selectdata.USER_ROLE === 'Role' && this.gridDataTemp[i].S_APPR_ENTERPRISE_ID !== null
        && selectdata.S_ACCESS_APPROVER_TYPE === null) {
        selectdata.S_ACCESS_APPROVER_TYPE = undefined;
        selectdata.S_ACCESS_APPROVER_ROLE = undefined;
        selectdata.S_APPR_ENTERPRISE_ID = null;
      } else if (selectdata.USER_ROLE === 'Role' && this.approverID === 2 && selectdata.UG_ACRONYM !== 'Central Team' &&
        (selectdata.P_ACCESS_APPROVER_TYPE === null || selectdata.P_ACCESS_APPROVER_ROLE === null ||
          selectdata.S_ACCESS_APPROVER_ROLE === null || selectdata.S_ACCESS_APPROVER_TYPE === null)) {
        selectdata.S_ACCESS_APPROVER_TYPE = undefined;
        selectdata.S_ACCESS_APPROVER_ROLE = undefined;
        selectdata.P_ACCESS_APPROVER_TYPE = undefined;
        selectdata.P_ACCESS_APPROVER_ROLE = undefined;
      } else if (selectdata.USER_ROLE === 'Role' && this.approverID === 2 && selectdata.UG_ACRONYM === 'Central Team'
        && (selectdata.P_ACCESS_APPROVER_TYPE === null || selectdata.P_ACCESS_APPROVER_ROLE === null)) {
        selectdata.P_ACCESS_APPROVER_TYPE = undefined;
        selectdata.P_ACCESS_APPROVER_ROLE = undefined;
      }
    }
  }
  crossDelete(values: any): void {
    if (this.confirmFlag === true) {
      this.cancelEdit(values);
    } else {
      this.masterCode.emit(this.masterDetails.mastercode);
    }
  }
  cancelEdit(i: any): void {
    this.editBtnHide = true;
    this.dataSource.data.splice(i, 1);
    this.dataSource._updateChangeSubscription();
  }

  activateAction(state: any, i: any, ele: any, selecteddata: any): void {
    if (this.masterDetails.masterName === 'Maintain Approver Matrix' && state === true) {
      delete selecteddata.PWL_FAC_END_DATE;
      this.approverID = 2;
      if ((selecteddata.USER_ROLE === 'User' && selecteddata.UG_ACRONYM === 'Central Team') ||
        (selecteddata.USER_ROLE === 'User' && selecteddata.UG_ACRONYM !== 'Central Team') ||
        (selecteddata.USER_ROLE === 'Role' && selecteddata.UG_ACRONYM === 'Central Team') ||
        (selecteddata.USER_ROLE === 'Role' && selecteddata.UG_ACRONYM !== 'Central Team')) {
        this.confirmIconDisable = true;
        this.activateColumn = 2;
      }
    } else {
      if (state === true || state === 'Y') {
        this.PopupDescToggle = 'Do you want to Activate the Details?';
      }
      else {
        this.PopupDescToggle = 'Do you want to Deactivate the Details?';
      }
      const dialogRef = this.dialog.open(ConfirmPopupComponent, {
        width: '500px',
        panelClass: 'confirm-popup',
        data: {
          title: 'Please note',
          description: this.PopupDescToggle,
          isConfirmType: true
        }
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result === true) {
          if (this.data[i][ele] === false) {
            this.confirmIconDisable = false;
            this.crossIconDisable = false;
            this.editBtnHide = true;
            delete this.data[i].isEdit;
            this.deletePayload(i, selecteddata);
          } else {
            this.deletePayload(i, selecteddata);
            this.editBtnHide = true;
          }
        } else if (result === false) {
          if (state === true) {
            this.data[i][ele] = false;
          } else {
            this.data[i][ele] = true;
          }
          this.dataSource.data = this.data;
          this.dataSource._updateChangeSubscription();
        }
      });
    }
  }

  deletePayload(i: any, selecteddata: any): void {
    this.columnDef?.forEach((d) => {
      if (d.MMC_ELEMENTTYPE === 'toggle') {
        if (selecteddata[d.MMC_COLNAME] === true) {
          selecteddata[d.MMC_COLNAME] = 'Y';
        } else {
          selecteddata[d.MMC_COLNAME] = 'N';
        }
      }
    });
    const param = {
      'MasterCode': this.masterDetails.mastercode,
      'Action': 'D',
      'MastersData': [{
        'RowIndex': i + 1,
        'RowData':
          selecteddata
      }
      ],
      'UserDetails': this.masterDetails.userAccessDetails
    };
    this.spinner.show();
    this.masterService.updateMaster(param).subscribe((response: any) => {
      if (response.Message === 'Success') {
        this.spinner.hide();
        this.masterCode.emit(this.masterDetails.mastercode);
      } else {
        this.spinner.hide();
      }
    });
  }
  eraseFutureDate(data: any): void {
    const date: Date = new Date();
    this.minDate = new Date(date.setDate(date.getDate() + 7));
    if (data.PWL_FAC_END_DATE > this.minDate) {
      data.PWL_FAC_END_DATE = undefined;
      data.PWL_REP_LOC_NAME = '';
      this.enableRepLocName = true;
    }
  }

  confirmRowData(selecteddata: any, i: any): void {
    if (this.masterDetails.masterName !== 'Project/Work Location') {
      delete selecteddata.PWL_FAC_END_DATE;
    } else if (selecteddata.PWL_FAC_END_DATE === null && this.dateFlag === 1 &&
      this.masterDetails.masterName === 'Project/Work Location') {
      this.locEnable = true;
      return;
    }
    if (((this.masterDetails.masterName === 'Maintain Approver Matrix' && selecteddata.USER_ROLE === 'User' &&
      (selecteddata.UG_ACRONYM !== 'Central Team' && (selecteddata.P_APPR_ENTERPRISE_ID === null ||
        selecteddata.S_APPR_ENTERPRISE_ID === null)) ||
      (selecteddata.USER_ROLE === 'User' && selecteddata.UG_ACRONYM === 'Central Team' && selecteddata.P_APPR_ENTERPRISE_ID === null)) ||
      (this.activateColumn === 2 && selecteddata.USER_ROLE === 'User' && (selecteddata.P_APPR_ENTERPRISE_ID === '' ||
        selecteddata.S_APPR_ENTERPRISE_ID === ''))) || (this.activateColumn === 2 && selecteddata.USER_ROLE === 'Role' &&
          (selecteddata.P_ACCESS_APPROVER_TYPE === undefined || selecteddata.S_ACCESS_APPROVER_TYPE === undefined ||
            selecteddata.P_ACCESS_APPROVER_ROLE === undefined
            || selecteddata.S_ACCESS_APPROVER_ROLE === undefined)) || (selecteddata.USER_ROLE === 'Role' &&
              selecteddata.UG_ACRONYM !== 'Central Team' && (selecteddata.P_ACCESS_APPROVER_TYPE === undefined ||
                selecteddata.S_ACCESS_APPROVER_ROLE === undefined || selecteddata.P_ACCESS_APPROVER_ROLE === undefined ||
                selecteddata.S_ACCESS_APPROVER_TYPE === undefined || selecteddata.P_ACCESS_APPROVER_TYPE === null ||
                selecteddata.S_ACCESS_APPROVER_TYPE === null || selecteddata.S_ACCESS_APPROVER_ROLE === null ||
                selecteddata.P_ACCESS_APPROVER_ROLE === null)) || (selecteddata.UG_ACRONYM === 'Central Team' &&
                  selecteddata.USER_ROLE === 'Role' && (selecteddata.P_ACCESS_APPROVER_TYPE === null ||
                    selecteddata.P_ACCESS_APPROVER_ROLE === null))) {
      this.submitApprover = true;
      return;
    }
    if (this.masterDetails.masterName === 'Primary Restricted Recruiter' && selecteddata.WIPRM_RECRUITER_PERSONNEL_NO !== '') {
      const validationArray = this.requiredFieldArray[0]?.EventValidationInfo[0];
      const regExp = new RegExp(validationArray.VALIDPATTERN);
      this.submitError = regExp.test(selecteddata.WIPRM_RECRUITER_PERSONNEL_NO) ? false : true;
      if (this.submitError) {
        return;
      }
    }
    const requiredField = this.columnDef?.filter(s => s.MMC_REQUIRED === 'Y' && s.MMC_HIDDEN !== 'Y');
    const requiredKeys = requiredField?.map(function (value) {
      return value.MMC_COLNAME;
    });
    this.submitted = false;
    requiredKeys?.forEach((key) => {
      if (selecteddata.hasOwnProperty(key) && (selecteddata[key] === '' || selecteddata[key] === null)) {
        this.validationField = false;
        this.submitted = true;
        return;
      }
    });
    if (this.submitted) return;
    this.validationField = true;

    if (selecteddata.CSTM_DEFAULT === 'Yes' || selecteddata.CTM_DEFAULT === 'Yes') {
      const param = {
        'MasterCode': this.masterDetails.mastercode,
        'Export': 1,
        'GridOptions': {
          'FilterData': null,
          'SortCol': '',
          'SortOrder': ''
        },
        'UserDetails': this.masterDetails.userAccessDetails
      };
      this.spinner.show();
      const config = {
        parameter: param,
        params: { code: environment.exportMasterByMasterID },
        environmentVariable: 'MasterbasePublicUrl',
        constantVariable: 'exportMasterByMasterID'
      };

      this.commonService.postApi(config).subscribe((response: any) => {
        this.spinner.hide();
        this.careerData = response.MastersData;
        if (this.careerData.length !== 0) {
          const careerArray = this.careerData?.filter((s: any) => (s.CSTM_DEFAULT === 'Yes' &&
            s.CSTM_ELEMENT !== selecteddata.CSTM_ELEMENT) || (s.CTM_DEFAULT === 'Yes' && s.CTM_ELEMENT !== selecteddata.CTM_ELEMENT));
          if (careerArray.length !== 0 && (selecteddata.CSTM_DEFAULT === 'Yes' || selecteddata.CTM_DEFAULT === 'Yes')) {
            this.careerGroupPopup(selecteddata, i);
          } else {
            this.careerFlag === 1 ? this.addMaster(selecteddata, i) : this.editMaster(selecteddata, i);
          }
        }
      });
    } else {
      this.confirmFlag ? this.addMaster(selecteddata, i) : this.editMaster(selecteddata, i);
    }
  }

  careerGroupPopup(selecteddata: any, i: any): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: '500px',
      panelClass: 'confirm-popup',
      data: {
        title: 'Please note',
        description: 'There is already one Career Group set as default. Would you like to make this Career Group as Default Career Group?',
        isConfirmType: true
      }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && this.careerFlag === 1) {
        this.addMaster(selecteddata, i);
      } else {
        this.editMaster(selecteddata, i);
      }
    });
  }
  addMaster(selecteddata: any, i: any): void {
    this.masterPayload(selecteddata);
    const param = {
      'MasterCode': this.masterDetails.mastercode,
      'Action': 'A',
      'MastersData': [{
        'RowIndex': i + 1,
        'RowData':
          selecteddata
      }
      ],
      'UserDetails': this.masterDetails.userAccessDetails
    };
    this.spinner.show();
    this.masterService.createNewMaster(param).subscribe((response: any) => {
      if (response.SaveResponse[0].Validation.IsValid === true) {
        this.spinner.hide();
        delete selecteddata.isEdit;
        this.masterCode.emit(this.masterDetails.mastercode);
        this._snackBar.openFromComponent(ToastComponent, {
          panelClass: ['success'],
          data: {
            label: 'Record saved successfully.'
          },
        });
        this.editBtnHide = true;
      } else {
        this.spinner.hide();
        this.columnDef?.forEach((d) => {
          if (d.MMC_ELEMENTTYPE === 'toggle') {
            if (selecteddata[d.MMC_COLNAME] === 'Y') {
              selecteddata[d.MMC_COLNAME] = true;
            } else {
              selecteddata[d.MMC_COLNAME] = false;
            }
          }
          else if (selecteddata.CSTM_DEFAULT === 'Y' || selecteddata.CSTM_DEFAULT === 'N') {
            selecteddata['CSTM_DEFAULT'] = selecteddata.CSTM_DEFAULT === 'Y' ? 'Yes' : 'No';
          } else if (selecteddata.CTM_DEFAULT === 'Y' || selecteddata.CTM_DEFAULT === 'N') {
            selecteddata['CTM_DEFAULT'] = selecteddata['CTM_DEFAULT'] === 'N' ? 'No' : 'Yes';
          }
        });
        const warningData = response.SaveResponse[0].Validation.ValidationErrors[0].Message;
        this._snackBar.openFromComponent(ToastComponent, {
          panelClass: ['warning'],
          data: {
            label: warningData
          },
        });
        this.editBtnHide = false;
      }
    });
  }

  editMaster(selecteddata: any, i: any): void {
    const tempData = this.commonService.sanitizeJSON(selecteddata);
    this.masterPayload(tempData);
    const param = {
      'MasterCode': this.masterDetails.mastercode,
      'Action': 'E',
      'MastersData': [{
        'RowIndex': i + 1,
        'RowData':
          tempData
      }
      ],
      'UserDetails': this.masterDetails.userAccessDetails
    };
    this.spinner.show();
    this.masterService.updateMaster(param).subscribe((response: any) => {
      if (response.SaveResponse[0].Validation.IsValid === true) {
        this.spinner.hide();
        delete tempData.isEdit;
        this.masterCode.emit(this.masterDetails.mastercode);
        this._snackBar.openFromComponent(ToastComponent, {
          panelClass: ['success'],
          data: {
            label: 'Record saved successfully.'
          },
        });
        this.editBtnHide = true;
      } else {
        this.spinner.hide();
        this.columnDef?.forEach((d) => {
          if (d.MMC_ELEMENTTYPE === 'toggle') {
            if (tempData[d.MMC_COLNAME] === 'Y') {
              tempData[d.MMC_COLNAME] = true;
            } else {
              tempData[d.MMC_COLNAME] = false;
            }
          }
        });
        const warningData = response.SaveResponse[0].Validation.ValidationErrors[0].Message;
        this._snackBar.openFromComponent(ToastComponent, {
          panelClass: ['warning'],
          data: {
            label: warningData
          },
        });
        this.editBtnHide = false;
      }
    });
  }
  masterPayload(selecteddata: any): void {
    if (selecteddata.RT_NOTICE_PERIOD === '') {
      selecteddata.RT_NOTICE_PERIOD = 0;
    } else if ((this.masterDetails.masterName === 'Project/Work Location' &&
      (selecteddata.PWL_FAC_END_DATE === '' || selecteddata.PWL_FAC_END_DATE !== ''))) {
      selecteddata.PWL_FAC_END_DATE = selecteddata.PWL_FAC_END_DATE === '' ?
        null : this.datepipe.transform(selecteddata.PWL_FAC_END_DATE, 'dd/MM/yyyy');
      selecteddata.PWL_TZM_ID = 0;
      this.dateErrorValidation = false;
    }
    this.columnDef?.forEach((d) => {
      if (d.MMC_ELEMENTTYPE === 'toggle') {
        if (selecteddata[d.MMC_COLNAME] === true) {
          selecteddata[d.MMC_COLNAME] = 'Y';
        } else {
          selecteddata[d.MMC_COLNAME] = 'N';
        }
      }
      if (d.MMC_ELEMENTTYPE === 'dropdown') {
        this.dataArrayList = [];
        for (let i = 0; i < this.tableDropdwnDataArray.length; i++) {
          const dropdownValues = this.dataArrayList.find((x: any) => x.Id === this.dropDownDataArray.Id);
          if (!dropdownValues && d.MMC_COLNAME === this.tableDropdwnDataArray[i].MMC_COLNAME
            && selecteddata[d.MMC_COLNAME] !== '' && selecteddata[d.MMC_COLNAME] !== null && selecteddata[d.MMC_COLNAME] !== undefined
            && this.tableDropdwnDataArray[i].MMC_PREFILLDATA !== null) {
            this.dropDownDataArray = this.tableDropdwnDataArray[i].MMC_PREFILLDATA.find((s: any) =>
              s.Id === selecteddata[d.MMC_COLNAME] || s.Name === selecteddata[d.MMC_COLNAME]);
            selecteddata[d.MMC_MAPPEDCOL] = this.dropDownDataArray.Id;
            this.dataArrayList.push(this.dropDownDataArray);
          }
        }
      }
    });
  }
  pageChange(pageno: any): void {
    this.pageNumber = pageno.pageIndex + 1;
    const param = {
      MasterCode: this.masterDetails.mastercode,
      PageLoad: 0,
      GridOptions: {
        Page: pageno.pageIndex + 1,
        PageSize: 40,
        SearchChanged: 0,
        FilterData: this.masterDetails.dynamicGridData ? this.masterDetails.dynamicGridData : null,
        SortCol: this.columNameSort ? this.columNameSort : '',
        SortOrder: this.sortOrder ? this.sortOrder : ''
      },
      'UserDetails': this.masterDetails.userAccessDetails
    };
    this.spinner.show();
    this.masterService.tableDetails(param).subscribe((response: any) => {
      this.spinner.hide();
      this.data = response.MastersData;
      this.columnDef?.forEach((d) => {
        if (d.MMC_ELEMENTTYPE === 'toggle') {
          for (let i = 0; i < this.data.length; i++) {
            if (this.data[i][d.MMC_COLNAME] === 'N' || this.data[i][d.MMC_COLNAME] === false) {
              this.data[i][d.MMC_COLNAME] = false;
            } else {
              this.data[i][d.MMC_COLNAME] = true;
            }
          }
        }
        this.dataSource.data = this.data;
        this.dataSource._updateChangeSubscription();
      });
    });
  }
  sortDataTable(data: any): void {
    this.columNameSort = data.active;
    this.sortOrder = data.direction;
    const param = {
      'MasterCode': this.masterDetails.mastercode,
      'PageLoad': 0,
      'GridOptions': {
        'Page': this.pageNumber ? this.pageNumber : 1,
        'PageSize': 40,
        'SearchChanged': 0,
        'FilterData': this.masterDetails.dynamicGridData ? this.masterDetails.dynamicGridData : null,
        'SortCol': data.active,
        'SortOrder': data.direction
      },
      'UserDetails': this.masterDetails.userAccessDetails
    };
    this.masterService.tableDetails(param).subscribe((response: any) => {
      this.data = response.MastersData;
      if (data.active !== undefined) {
        this.columnDef?.forEach((d) => {
          if (d.MMC_ELEMENTTYPE === 'toggle') {
            for (let i = 0; i < this.data.length; i++) {
              if (this.data[i][d.MMC_COLNAME] === 'N' || this.data[i][d.MMC_COLNAME] === false) {
                this.data[i][d.MMC_COLNAME] = false;
              } else {
                this.data[i][d.MMC_COLNAME] = true;
              }
            }
          }
          this.dataSource.data = this.data;
          this.dataSource._updateChangeSubscription();
          this.editBtnHide = true;
        });
      }
    });
  }
  resetTable(): void {
    this.columNameSort = '';
    this.sortOrder = '';
    this.masterDetails.dynamicGridData = null;
    this.editBtnHide = true;
    this.paginator.firstPage();
    this.resetAddflag = true;
    if (this.addBtnHide === false) {
      this.addBtn.emit({ addBtnStatus: this.addBtnHide });
    }
  }
}
