import { Component, Input } from '@angular/core';
import { breadcrumbtype } from '../../model/common.model';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
  @Input() data: Array<breadcrumbtype> = [];
   url:string = environment.redirectOldUrl;
}
