import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild, HostListener } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-pyramid-chart',
  templateUrl: './pyramid-chart.component.html',
  styleUrls: ['./pyramid-chart.component.scss']
})
export class PyramidChartComponent implements OnInit, OnChanges {

  @ViewChild('chart', { static: true }) chartContainer: ElementRef | any;
  @Input() data: { label: string, count: number }[] | undefined;
  margin = { top: 20, right: 20, bottom: 20, left: 180 };

  constructor() {
  }
  ngOnInit(): void {
    this.createChart();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['data'].firstChange) {
      d3.select(this.chartContainer.nativeElement)?.select('svg').remove();
      this.createChart();
    }

  }
  @HostListener('window:resize', ['$event'])

  createChart(): void {
    if (this.data) {
      const chartContainer = this.chartContainer.nativeElement;
      d3.select(this.chartContainer.nativeElement)?.select('svg').remove();
      const width = chartContainer.offsetWidth;
      const height = chartContainer.offsetHeight;
      const centerX = (width - this.margin.left) / 2;
      const barHeight = (height - (this.margin.top + this.margin.bottom)) / this.data.length;//35;
      const barMargin = 5;
      const maxValue: any = d3.max(this.data, d => d.count);
      const barOffset = this.margin.left; // offset value to have space between bars and lables

      const tooltip = d3.select(chartContainer)
        .append('div')
        .style('opacity', 0)
        .attr('class', 'tooltip-barcart')
        .style('background-color', '#000000')
        .style('position', 'absolute')
        .style('color', '#ffffff')
        .style('font-size', '12px')
        .style('border-radius', '4px')
        .style('padding', '4px 8px');

      // Three function that change the tooltip when user hover / move / leave a cell
      const mouseover = (event: any, d: any): void => {
        tooltip
          .html(d['label'] + ':' + d['count'])
          .style('left', `${event.pageX}px`)
          .style('top', `${event.pageY - 28}px`)
          .style('opacity', 1);
      };

      const mouseleave = (): void => {
        tooltip
          .style('opacity', 0);
      };

      const xScale = d3.scaleLinear()
        .domain([0, maxValue])
        .range([0, centerX]);

      const svg = d3.select(chartContainer)
        .append('svg')
        .attr(
          'viewBox',
          `0 0 ${width} ${height}`
        );

      const labelsContainer = svg.append('g')
        .attr('class', 'labels-container')
        .attr('transform', `translate(0)`);

      const bars = svg.selectAll('.bar')
        .data(this.data)
        .enter()
        .append('g')
        .attr('class', 'bar')
        .attr('transform', (d, i) => `translate(${centerX - xScale(d.count) + barOffset},
      ${i * (barHeight + barMargin)})`); // applying offset to the bars

      bars.append('rect')
        .attr('width', d => xScale(d.count) * 2)
        .attr('height', barHeight)
        .attr('fill', (d: any, i: any) => {
          return i % 2 === 0 ? 'rgb(0, 161, 204)' : 'rgb(43, 165, 153)';
        })
        .on('mouseleave', mouseleave)
        .on('mouseover', mouseover);

      labelsContainer.selectAll('.label')
        .data(this.data)
        .enter()
        .append('text')
        .attr('class', 'label')
        .attr('x', 0)
        .attr('y', (_d, i) => i * (barHeight + barMargin) + barHeight / 2)
        .attr('dy', '0.35em')
        .text(d => d.label)
        .attr('aria-hidden', true)
        .attr('fill', '#000000')
        .style('font-size', '12px')
        .style('font-weight', 'bold')
        .attr('text-anchor', 'start');

      bars.append('text') // Count
        .attr('x', d => xScale(d.count))
        .attr('y', barHeight / 2)
        .attr('dy', '0.35em')
        .text('')
        .append('tspan')
        .html((d: any) => '<tspan class="hide-content"> ' + d.label + ' </tspan>' + d.count)
        .attr('fill', '#000000')
        .attr('text-anchor', 'middle')
        .style('font-size', '14px');
    }
  }

}
