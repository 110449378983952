<div class="customised-toast" role="alert">
    <span matSnackBarLabel [innerHTML]="data.label">
    </span>
    <span matSnackBarActions class="d-inline-flex">
        <button mat-button matSnackBarAction class="btn-undo">Undo</button>
        <button matSnackBarAction class="btn btn-link text-white ms-1 py-0" [attr.aria-label]="data.actionLabel"
            *ngIf="data.routeUrl && data.routeUrl !== ''" (click)="routeTo(data.routeUrl)">{{data.actionLabel}}</button>
        <button mat-button-close matSnackBarClose aria-label="Close confirmation popup"
            (click)="snackBarRef.dismissWithAction()"
            class="smart-icon-close btn position-absolute close-toast"></button>
    </span>
</div>