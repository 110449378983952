import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MaintainMasterService {
  constructor(private httpClient: HttpClient) {
  }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  public getUserDetailsForMasters(param: any): Observable<any> {
    return this.httpClient.post<any>(environment.MasterbasePublicUrl + environment.getUserDetailsForMasters, param);
  }

  public mastersList(param: any): Observable<any> {
    return this.httpClient.post<any>(environment.MasterbasePublicUrl + environment.maintainMasterData, param);
  }

  public tableDetails(param: any): Observable<any> {
    return this.httpClient.post<any>(environment.MasterbasePublicUrl + environment.getMasterById, param);
  }

  public createNewMaster(param: any): Observable<any> {
    return this.httpClient.post<any>(environment.MasterbasePublicUrl + environment.createMasterByMasterID, param);
  }

  public updateMaster(param: any): Observable<any> {
    return this.httpClient.post<any>(environment.MasterbasePublicUrl + environment.updateMasterByMasterID, param);
  }
}
