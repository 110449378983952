<section class="overflow-auto smart-table custom-overflow-scroll table-verticalsroll">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 w-100 " matSort
    (matSortChange)="sortDataTable($event)">
    <ng-container [matColumnDef]="col.MMC_COLNAME" *ngFor="let col of columnDef"
      [stickyEnd]="col.MMC_COLNAME === 'isEdit'">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="col.MMC_HIDDEN==='Y'"
        [disabled]="col.MMC_COLDISPLAYNAME ==='Activate' || col.MMC_COLDISPLAYNAME ==='Action'">
        {{col.MMC_COLDISPLAYNAME }}
      </th>

      <td mat-cell *matCellDef="let data; let i=index ">
        <div [ngSwitch]="col.MMC_COLDISPLAYNAME" *ngIf="!data.isEdit " role="none">
          <div *ngSwitchCase="'Activate'" role="none">
            <mat-slide-toggle class="smart-slide" labelPosition="before" [disabled]="toggleDisable"
              [hidden]="col.MMC_HIDDEN==='Y'" [ngModel]="data[col.MMC_COLNAME]" name="activate">
              {{data[col.MMC_COLNAME] ===true ? 'Yes' : 'No' }}
            </mat-slide-toggle>
          </div>
          <span *ngSwitchDefault [hidden]="col.MMC_HIDDEN==='Y'" role="none">
            {{ data[col.MMC_COLNAME] }}
          </span>
          <button *ngIf="col.MMC_COLNAME === 'isEdit' && editBtnHide " aria-label="Edit row data"
            (click)="editRow(data,i);  data.isEdit = !data.isEdit; " [disabled]="cityEditAction"
            class="btn table-action-btn smart-btn smart-btn-link smart-icon-edit d-flex mx-auto">
          </button>
        </div>
        <div *ngIf="data.isEdit;" role="none">
          <div class="btn-edit" *ngIf="col.MMC_COLNAME === 'Delete'; else dataField" role="none">
            <button mat-button>Done</button>
          </div>

          <span *ngIf="col.MMC_ELEMENTTYPE === 'textbox' || col.MMC_ELEMENTTYPE === 'textarea' "
            class="form-field-container input-field" role="none">
            <input [(ngModel)]="data[col.MMC_COLNAME]" [type]="col.MMC_COLNAME"
              [disabled]=" col.MMC_COLDISPLAYNAME==='Career Group Element Description'"
              class=" inputbox form-control smart-form-control" name="mdcddecode" #inputData="ngModel"
              autocomplete="off" pattern="{{validArray[col.MMC_COLNAME]?.VALIDPATTERN}}" [disabled]="(data['UG_ACRONYM'] === 'Central Team' && col.MMC_COLNAME === 'S_APPR_ENTERPRISE_ID') || (data['USER_ROLE'] === 'Role' && (col.MMC_COLNAME === 'P_APPR_ENTERPRISE_ID' || col.MMC_COLNAME === 'S_APPR_ENTERPRISE_ID'))
     || (masterDetails.masterName === 'Maintain Approver Matrix' && col.MMC_COLNAME === 'UG_ACRONYM')" required />
            <span class="error-validation-msg"
              *ngIf="inputData.errors?.['required'] && col.MMC_REQUIRED === 'N' && submitApprover">
              {{col.MMC_COLDISPLAYNAME}} is required
            </span>
            <span class="error-validation-msg"
              *ngIf="inputData.errors?.['required'] && col.MMC_REQUIRED === 'Y' && submitted">
              {{col.MMC_COLDISPLAYNAME}} is required
            </span>
            <div class="error-validation-msg" *ngIf="inputData.touched && inputData.errors?.['pattern']"
              [ngSwitch]="col.MMC_COLNAME">
              <span *ngSwitchCase="'PWL_MRDR_CODE'">
                The facility code should be in the format IND-Facility Code(XX)-Facility Number(XX)
              </span>
              <span class="error-validation-msg" *ngSwitchCase="'PWL_NAME'">
                Location added is not valid. Please make sure that nomenclature is in valid format.For example
                if you are adding for BDC6, Tower A, then it should be in format BDC6A (in upper cases).
              </span>
              <span class="error-validation-msg" *ngSwitchCase="'TZM_COMPANY_CODE'">
                Company Code should be AlphaNumeric Value
              </span>
              <span class="error-validation-msg" *ngSwitchCase="'P_APPR_ENTERPRISE_ID' || 'S_APPR_ENTERPRISE_ID'">
                Invalid Characters or blank spaces are present
              </span>
              <span *ngSwitchCase="'WIPRM_RECRUITER_PERSONNEL_NO'">
                <span class="error-validation-msg" *ngIf='submitError'>
                  Recruiter Personnel number is invalid
                </span>
              </span>
            </div>
            <label for="show-six-month" class="show-six-label" *ngIf="col.MMC_COLNAME ==='P_APPR_ENTERPRISE_ID' ||
              col.MMC_COLNAME ==='S_APPR_ENTERPRISE_ID'">
              (Enter multiple approver enterprise Id separated by comma). For Example - john.doe,clark.gray
            </label>
          </span>
          <div [ngSwitch]="col.MMC_ELEMENTTYPE" class="pe-2" role="none">
            <div class="date-picker-container position-relative" *ngSwitchCase="'datepicker'" role="none">
              <input aria-describedby="selectDateRange" class="inputbox form-control smart-form-control" matInput
                [matDatepicker]="pickerStartDate" (click)="pickerStartDate.open(); dateRepLocEnable()"
                [(ngModel)]="data[col.MMC_COLNAME]" (dateInput)="validationError(data)" #dateData="ngModel"
                [disabled]="data[col.MMC_COLNAME] === '' || disableDate" [min]="minDate">
              <span class="smart-icon-calendar position-absolute"
                (click)="pickerStartDate.open(); dateRepLocEnable()"></span>
              <mat-datepicker #pickerStartDate></mat-datepicker>
              <button class="smart-icon-erase border-0 bg-transparent position-absolute" aria-label="reset date"
                (click)="eraseFutureDate(data)" *ngIf="eraserHide"></button>
              <span class="error-validation-msg"
                *ngIf="dateData.touched && dateData.errors && col.MMC_COLNAME === 'PWL_FAC_END_DATE' && dateErrorValidation">
                Add proper date format eg: dd/mm/yyyy</span>
            </div>
          </div>
          <ng-template #dataField>
            <span *ngIf="col.MMC_ELEMENTTYPE === 'dropdown' && col.MMC_COLNAME !== 'PWL_REP_LOC_NAME'" role="none">
              <ng-select [(ngModel)]="data[col.MMC_COLNAME]" #dropdownData="ngModel"
                class="smart-ng-select filter-dropdown-selection" [items]=" dropdownArray[col.MMC_COLNAME]"
                bindLabel="Name" bindValue="Name" appendTo="body" [disabled]="(data['USER_ROLE'] === 'User'&& (col.MMC_COLNAME === 'P_ACCESS_APPROVER_TYPE' || col.MMC_COLNAME === 'P_ACCESS_APPROVER_ROLE' || col.MMC_COLNAME === 'S_ACCESS_APPROVER_ROLE' || col.MMC_COLNAME === 'S_ACCESS_APPROVER_TYPE' )) || 
                ((col.MMC_COLNAME === 'S_ACCESS_APPROVER_TYPE' || col.MMC_COLNAME === 'S_ACCESS_APPROVER_ROLE') && data['UG_ACRONYM'] === 'Central Team' )  ||
                (masterDetails.masterName === 'Maintain Approver Matrix' && col.MMC_COLNAME === 'AR_ACRONYM')"
                (change)="approverMatrixData(data, i)" required>
                <ng-template ng-option-tmp let-item="item">
                  <span title="{{item.Name}}">{{item.Name}}</span>
                </ng-template>
              </ng-select>
              <span class="error-validation-msg" *ngIf="dropdownData.invalid  && col.MMC_REQUIRED === 'Y' && submitted">
                {{col.MMC_COLDISPLAYNAME}} is
                required </span>
              <span class="error-validation-msg"
                *ngIf="dropdownData.invalid  && col.MMC_REQUIRED === 'N' && submitApprover">
                {{col.MMC_COLDISPLAYNAME}} is
                required </span>
              <span class="error-validation-msg" *ngIf="dropdownData.touched && col.MMC_REQUIRED === 'Y'
                && (col.MMC_COLNAME === 'CIT_NAME' && (data['OL_NAME'] !== data['CIT_NAME']))">
                City and Office Location cannot be different.</span>
            </span>
            <span *ngIf="col.MMC_ELEMENTTYPE === 'dropdown' && col.MMC_COLNAME === 'PWL_REP_LOC_NAME'" role="none">
              <ng-select [(ngModel)]="data['PWL_REP_LOC_NAME']" #dropdownDatas="ngModel" appendTo="body"
                [items]="repDropdownArray[col.MMC_COLNAME]" class="smart-ng-select filter-dropdown-selection"
                [disabled]="(col.MMC_COLNAME === 'PWL_REP_LOC_NAME' && (data.PWL_FAC_END_DATE === '' || data.PWL_FAC_END_DATE === null || data.PWL_FAC_END_DATE === dateFacility || enableRepLocName))"
                bindLabel="Name" bindValue="Name" required>
                <ng-template ng-option-tmp let-item="item">
                  <span title="{{item.Name}}">{{item.Name}}</span>
                </ng-template>
              </ng-select>
              <span class="error-validation-msg"
                *ngIf="dropdownDatas.invalid  && col.MMC_REQUIRED === 'Y' && submitted">
                {{col.MMC_COLDISPLAYNAME}} is
                required </span>
              <span class="error-validation-msg" *ngIf=" col.MMC_REQUIRED === 'N' && submitError">
                No replacement facility is available for the selected city to add</span>
            </span>
            <span class="error-validation-msg" *ngIf="locEnable">
              Replacement Location Name is required</span>
          </ng-template>
          <div *ngIf="col.MMC_ELEMENTTYPE === 'toggle'" role="none">
            <mat-slide-toggle class="smart-slide" [(ngModel)]="data[col.MMC_COLNAME]" name="activate"
              labelPosition="before" [disabled]="disableSlide"
              (change)="activateAction(data[col.MMC_COLNAME],i, col.MMC_COLNAME,data)">
              {{data[col.MMC_COLNAME] ===true ? 'Yes' : 'No' }}
            </mat-slide-toggle>
          </div>
        </div>
        <div *ngIf="data.isEdit" class="d-flex" role="none">
          <button aria-label="Save row data" *ngIf="col.MMC_COLNAME === 'isEdit' && confirmIconDisable"
            class="btn smart-btn-link smart-btn table-action-btn smart-icon-checkmark"
            (click)="confirmRowData(data,i)"></button>
          <button aria-label="cancel edit row data" (click)="crossDelete(data);"
            *ngIf="col.MMC_COLNAME === 'isEdit' && crossIconDisable "
            class="btn smart-btn-link smart-btn table-action-btn smart-icon-close"></button>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</section>
<mat-paginator (page)="pageChange($event)" [pageSize]=40 [length]="pageLength" [attr.aria-label]="0"
  aria-label="Select Page of this Grid">
</mat-paginator>