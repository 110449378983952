export const environment = {
  env: 'local',
  production: false,
  providers: 'app',
  tenantId: 'f3211d0e-125b-42c3-86db-322b19a65a22',
  ldcClientId: '642bcbd9202ff51235be447f',
  msalConfig: {
    isAuthenticationEnabled: true,
    auth: {
      authority: 'https://login.microsoftonline.com/f3211d0e-125b-42c3-86db-322b19a65a22',
      clientId: '45feede2-fdbb-43d2-b2b4-638f7e553ab5',
      redirectUri: 'https://smartaccess.ciodev.accenture.com/'
    }
  },
  dataDogConfig: {
    clientToken: '',
    site: '​',
    service: '',
    applicationId: ''
  },
  redirectapiUrl: 'https://smartaccess.ciodev.accenture.com/',
  aseUrl: 'https://aseforecast.ciostage.accenture.com/',
  MasterbasePublicUrl: 'https://smartaccess.ciodev.accenture.com/',
  rebarToken: 'https://login.microsoftonline.com/f3211d0e-125b-42c3-86db-322b19a65a22/oauth2/token',
  redirectOldUrl: 'https://smartaccess.ciodev.accenture.com/',

  //Audit Trail 
  getUserGroup: 'GetUserGroup?code=2l9YuGiZKg7RYeVNh1bXSHZCA_H7kkOV7K-rJQA36YasAzFujh56aw==',
  getUserRole: 'GetUserRole?code=bEPWs8Fhytay3mHVzIB7xlUq7qRcy_LrCM-0gVieHlYcAzFumRQNOA==',
  getQueuedList: 'GetQueuedList?code=JW-QIkgSOuv4pRv0k8_TfC7PXlfSZB-CNFYIkux-eZMRAzFuMm803Q==',
  deleteAuditTrail: 'DeleteAuditTrailRequest?code=0A7PJtz14CyRvMeOrw6v6elmGl2AQWp1rPVBgkpKBDrxAzFup4XtUg==',
  insertDemand: 'InsertDemandAuditTrailRequest?code=b2I-jVPJQyr2IK-o3X0YAIIHF0fXpxM-Wck-s6Mk7TbZAzFulxmpOA==',
  insertUserAccess: 'InsertUserAccessAuditTrailRequest?code=lxEP989C9hGhsQlgV1oPmoxYxR-iR_2Ox5mrRHMMd1yrAzFusy56Og==',
  getDownloadDetails: 'DownloadFile?code=XM9ETI_1qY5BmmB4lVyZdj042--IBKS369OC1Sk-SITWAzFuQ6zzig==',
  GetUserDetail: 'GetUserDetail?code=CWO6X-d249gG_SIpdxfzZ65VZb4XJFlDo4owKjxIIie0AzFuxwpzvg==',
  getProjectDetails: 'GetProjDetails?code=cBZdcM-OMO9Q-rRaiusLwpHTREi5-yT2EnV8yhPGoig_AzFuGdSbog==',
  GetModifiedByDetails: 'GetModifiedBy?code=iKGJFnyyCb9fCtklOrsn1rqOrIHrz_GjHqeaWzgNNZnQAzFuELdqyQ==',
  insertSRTrial: 'InsertRequisitionTrailRequest?code=tUYvKaa1IKjQi2hKtxzatuK5u4PWnjuplI7w1rScHRLjAzFuzJugrg==',
  insertTalentTrail: 'InsertTalentTrailRequest?code=y9NxJAOvFdHGQnec06-MxLTedEP4jKuY95Yn_cc75dLKAzFuDdZdCg==',

  resourceDetailUrl: 'https://atcissa-reqrouter-ops.ciostage.accenture.com/api/',
  resourceDetailUrlType3: 'https://smart-resourceAPI-preprod.ciostage.accenture.com/api/TalentDetails/',
  bulkUploadGatewayUrl: 'https://smart-gatewayapi-ind-ops.ciostage.accenture.com/api/',
  moreActionNavigationUrl: 'https://testsmartapplication-preprod.ciostage.accenture.com',

  //Maintain Master
  getUserDetailsForMasters: 'GetUserDetails?code=sPRP0RWkx-IUHRngBEIm3gEalegSOTk_8tavs7U6GtLQAzFud9Yb6w==',
  maintainMasterData: 'GetAllMasters?code=em9T-WIWw_GwUugHyb1TZZnMKf6w3pmFomlB8H7e6EreAzFuxWp66A==',
  getMasterById: 'GetMasterByMasterID?code=p_-eJXauD_08GZhDKsg1nRmw7DpFzar8EMthgv319EBgAzFuEMmYbg==',
  createMasterByMasterID: 'CreateMasterByMasterID?code=i17OE5GGH8RMmPNLxJgqclazwYD6AjlZLYjsq8dRmndLAzFukh9sDQ==',
  updateMasterByMasterID: 'UpdateMasterByMasterID?code=ofMc-ZssNaOJUqAe8Wj64wSx5loxr5ymDxzzxSel4qD1AzFuhODNYQ==',
  exportMasterByMasterID: 'zr4GlHKMcdRjJorUYtGNra8ma4b5yNluailVgMc12drLAzFudRPeSg==',
  getMasterGridRowData: 'AwLqYd0PN-zqPbcKh9oxjmLbXQ7lZPhiJSqWTYhAnK0bAzFuwDtRqQ==',

  accessManagementAPIUrl: 'https://smart-accessmanagement-ind-ops.ciostage.accenture.com/',
  createDemandUrl: 'https://smart-demandapi-ind-ops.ciostage.accenture.com/',
  openGenAIUrl: 'https://func-1005402genaijd.azurewebsites.net/api/',
};

export const protectedResourceMap = [
  [environment.redirectapiUrl, ['8634e9df-3acf-49c5-bac8-2185e42c235d/.default']],
  [environment.MasterbasePublicUrl, ['1d9a5996-db5b-437b-bc31-6ef280507333/.default']],
  [environment.resourceDetailUrl, ['e903a82e-5e89-4e09-ba57-35917ded6ab8/.default']],
  [environment.accessManagementAPIUrl, ['711d8a7d-38fd-44ae-bdb5-fa40801b2276/.default']],
  [environment.resourceDetailUrlType3, ['fccc8850-94a5-4ed9-8d54-9dc73849369b/.default']],
  [environment.bulkUploadGatewayUrl, ['732fe1be-7e5c-491d-a277-12f0381bc734/.default']],
  [environment.createDemandUrl, ['7ed50fe5-735a-46fb-845b-8d8b7e4fe4b2/.default']],
  [environment.openGenAIUrl, ['c6f28a00-a649-43c1-9dc3-543d5f2f1fd5/.default']]
];
