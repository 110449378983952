import { SelectionModel } from '@angular/cdk/collections';
import {
  Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges, ViewChild,
  TemplateRef, OnChanges, AfterViewInit
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { columnType, OperationType } from '../../model/common.model';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { ConstantData } from 'src/app/shared/constant';
import { CommonService } from 'src/app/core/services/common.service';
@Component({
  selector: 'app-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ]
})
export class DataGridComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() columnDef: Array<columnType> | any;
  @Input() isResetPage = true;
  @Input() data: any;
  @Input() filterTemplate: TemplateRef<any> | undefined;
  @Input() isReset = false;
  @Input() tableConfig: any | undefined;
  @Input() currentPage = 0;
  @Output() action = new EventEmitter();
  @Output() pageChange = new EventEmitter();
  @Output() pasteFromExcel = new EventEmitter();
  @Input() totalRows = 0;
  @Input() detailsTemplate: TemplateRef<any> | undefined;
  @Input() isResubmit = false;

  @ViewChild(MatSort) sort?: MatSort | any;
  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;
  @ViewChild(MatPaginator) paginator: MatPaginator | any;

  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource();
  filterArr: any[] = [];
  emptyfilterIcon: boolean;
  selectedRawIdx: any;
  expandedElement: any | null;
  expandConfig: any;
  selection = new SelectionModel<any>(true, []);
  showAction = false;
  operationType: any;
  pageSize = ConstantData.gridPageSize;
  editStatus: boolean;
  submitStatus: boolean;
  refreshStatus: boolean;

  constructor(public commonService: CommonService) {
    this.operationType = OperationType;
    this.emptyfilterIcon = true;
    this.editStatus = false;
    this.submitStatus = false;
    this.refreshStatus = false;

  }

  ngOnInit(): void {
    this.expandConfig = this.columnDef?.find((d: any) => d.displayType === 'Actions'
      || d.displayType === 'BulkUploadStatus')?.expandRowData;
    this.dataSource.filterPredicate = (data: any, filtersJson: string) => {
      const matchFilter: any = [];
      const filters = this.commonService.sanitizeJSON(filtersJson);
      filters.forEach((filter: any) => {
        const val = data[filter.id] === null ? '' : data[filter.id];
        matchFilter.push(filter.value.length > 0 ? filter.value.includes(val) : true);

      });
      return matchFilter.every(Boolean);
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.expandedElement = null;
    const dataVal: SimpleChange = changes['data'];
    const columnVal: SimpleChange = changes['columnDef'];
    const isReset: SimpleChange = changes['isReset'];
    const totalRows: SimpleChange = changes['totalRows'];
    if (dataVal !== undefined && JSON.stringify(dataVal.currentValue) !== JSON.stringify(dataVal.previousValue)) {
      if (this.columnDef?.findIndex((t: any) => t.displayType === 'RADIO') !== -1)
        this.selectedRawIdx = this.data.findIndex((t: any) => t.setDefault === true);
      this.getPremiumData();
    }
    if (columnVal !== undefined && JSON.stringify(columnVal.currentValue) !== JSON.stringify(columnVal.previousValue)) {
      this.getDisplayedColums();
      this.selection.clear();
    }
    if (isReset !== undefined && isReset.currentValue) {
      this.selection.clear();
    }

    if (totalRows !== undefined && JSON.stringify(totalRows.currentValue) !== JSON.stringify(totalRows.previousValue) && this.isResetPage) {
      if (this.paginator) {
        this.paginator.firstPage();
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
    let previous_width: number | undefined, previous_left: number | undefined;
    const element = document.getElementsByClassName('smart-table')[0];
    element?.addEventListener('keyup', (e: any) => {

      const sticky_ele = document.getElementsByTagName('th')[this.columnDef.filter((k: any) => k.sticky).length - 1].getClientRects()[0];
      const sticky = sticky_ele.x + sticky_ele.width + 20;

      if (e.shiftKey && e.which === 9) {
        if (previous_width && previous_width !== 0 &&
          previous_left === document.getElementsByClassName('smart-table')[0].scrollLeft) {
          document.getElementsByClassName('smart-table')[0].scrollLeft =
            document.getElementsByClassName('smart-table')[0].scrollLeft - previous_width;
        } else {
          document.getElementsByClassName('smart-table')[0].scrollLeft =
            document.getElementsByClassName('smart-table')[0].scrollLeft - e.target.closest('td').getClientRects()[0].width;
        }
        previous_width = e.target.closest('td').getClientRects()[0].width;
        previous_left = document.getElementsByClassName('smart-table')[0].scrollLeft;

        if (e.target.getClientRects()[0].x > sticky_ele.x || (previous_width + e.target.getClientRects()[0].x > sticky_ele.x)) {
          document.getElementsByClassName('smart-table')[0].scrollLeft =
            document.getElementsByClassName('smart-table')[0].scrollLeft - (sticky - e.target.getClientRects()[0].x);
        }
      }
    }, true);
  }

  getPremiumData(): void {
    this.dataSource.data = this.data;
    this.dataSource.sort = this.sort;

    this.dataSource.sortingDataAccessor = (item: any, property) => {
      const findItem = this.columnDef.find((d: any) => d.key === property);

      if (!isNaN(item[property]) && item[property] !== null && item[property] !== '') {
        return parseInt(item[property]);
      } else if (findItem
        && item[property] !== null && item[property] !== '' && (findItem.sortingType === 'date' || this.isValidDate(item[property]))) {
        return new Date(item[property]);
      } else if (item[property] && item[property] !== '') {
        return item[property].toLowerCase();
      }
    };
  }
  isValidDate(value: any): any {
    const dateReg = /\d{1,2}\/\d{1,2}\/\d{1,4}/;
    return value.match(dateReg);
  }

  statusCode(a: any): string {
    let statusColor: any;
    if (a === 'Pending') {
      statusColor = 'pendingColor';
    }
    else if (a === 'Approved') {
      statusColor = 'approvedColor';
    }
    else if (a === 'Deleted') {
      statusColor = 'deletedColor';
    }
    else {
      statusColor = 'rejectedColor';
    }
    return statusColor;
  }

  addFilter(event: MatCheckboxChange): void {
    if (event.checked) {
      this.filterArr.push(event.source.value);
    }
    else {
      const index: number = this.filterArr.indexOf(event.source.value);
      if (index !== -1) {
        this.filterArr.splice(index, 1);
      }
    }
  }

  applyModelFilter(key: any): void {
    const tableFilters = [{ id: key, value: this.filterArr }];
    this.dataSource.filter = JSON.stringify(tableFilters);
    if (tableFilters[0].value.length === 0) {
      this.emptyfilterIcon = true;
    }
    else {
      this.emptyfilterIcon = false;
    }
  }

  onEmitRadioAction(rowValue: any, index: number, actionName: string): void {
    this.selectedRawIdx = index;
    this.onActionClick(rowValue, index, actionName);
  }

  menuTrigger(data: any, datarow: any): void {
    const dataObj = { type: data, datarow: datarow };
    this.action.emit(dataObj);
  }


  onEmitCheckBoxAction(row: any): void {
    this.selection.toggle(row);
    this.action.emit({ value: this.selection.selected, operationType: OperationType.select });
  }

  isAllSelected(): any {
    if (this.tableConfig && this.tableConfig.maxCheckBoxLimit) {
      return this.selection.selected.length === this.tableConfig.maxCheckBoxLimit;
    }
    const disbaledCount = this.dataSource.data.filter((val: any) => val.disabled).length;
    return (this.selection.selected.length + disbaledCount) === this.dataSource.data.length;
  }
  toggleAll(event: any): void {
    if (!event.checked) {
      this.selection.deselect(...this.selection.selected);
    } else {
      if (this.tableConfig && this.tableConfig.maxCheckBoxLimit) {
        const data = this.selection.selected;
        this.selection.deselect(...data);
        this.selection.select(...this.data.slice(0, this.tableConfig.maxCheckBoxLimit));
      } else {
        const data = this.dataSource.data.filter((val: any) => !val.disabled);
        this.selection.select(...data);
      }
    }
    this.action.emit({ value: this.selection.selected, operationType: OperationType.select });
  }

  isCheckboxDisabled(rowValue: any): boolean {
    if (this.tableConfig && this.tableConfig.maxCheckBoxLimit && !rowValue.disabled) {
      return !this.selection.isSelected(rowValue) ? this.selection.selected.length >= this.tableConfig.maxCheckBoxLimit : false;
    }
    return (rowValue && rowValue.disabled) ? true : false;
  }
  getDisplayedColums(): void {
    this.displayedColumns = [];
    this.columnDef?.forEach((d: any) => {
      this.displayedColumns.push(d.key);
    });
    this.expandConfig = this.columnDef?.find((d: any) => d.displayType === 'Actions'
      || d.displayType === 'BulkUploadStatus')?.expandRowData;
    this.showAction = this.expandConfig && this.expandConfig.length > 0;
  }
  checkDisabledComment(rowValue: any): boolean {
    const item = this.selection.selected.filter(t => JSON.stringify(t) === JSON.stringify(rowValue));
    if (item.length > 0) {
      return false;
    }
    return true;
  }
  onInputComments(): void {
    this.action.emit({ value: this.selection.selected, operationType: OperationType.input });
  }
  actionEvent(rowVlaue: any): void {
    this.action.emit({ value: rowVlaue, operationType: OperationType.navigation });
  }
  isSelectAllDisable(): boolean {
    return this.dataSource.data.every(rowValue => this.isCheckboxDisabled(rowValue));
  }
  onActionClick(rowValue: any, index: any, actionName: string, buttonType?: any): void {
    this.action.emit({
      accessRecordArr: rowValue, operationType: actionName, index, ...buttonType && { buttonType }, pageIndex: this.currentPage
    });
  }
  pageChanged(event: any): void {
    this.currentPage = event.pageIndex;
    this.pageChange.emit(event);
  }

  pasteEvent(event: any): void {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData?.getData('text');
    let arr;

    if (pastedText) {
      if (event?.target.closest('td').children && event?.target.closest('td').children[0] instanceof HTMLTextAreaElement) {
        arr = [];
        arr.push([pastedText]);
      } else {
        arr = this.tsvStringToArray(pastedText).slice(0, -1);
      }

      this.pasteFromExcel.emit(
        {
          arr,
          index: event?.target.closest(`tr`).childNodes[0].textContent,
          className: event?.target.closest('td').className
        }
      );
    }
  }

  tsvStringToArray(data: any): any {
    const re = /(\t|\r?\n|\r|^)(?:"([^"]*(?:""[^"]*)*)"|([^\t\r\n]*))/gi;
    const result: any = [[]];
    let matches;
    while ((matches = re.exec(data))) {
      if (matches[1].length && matches[1] !== '\t') result.push([]);
      result[result.length - 1].push(
        matches[2] !== undefined ? matches[2].replace(/""/g, '"') : matches[3]
      );
    }
    return result;
  }
  emitExpandEvent(rowValue: any): void {
    if (this.detailsTemplate)
      this.action.emit({ value: rowValue, operationType: OperationType.expandableRow });
  }
  progressBarStatus(row: any): any {
    if (row && row.progress && row.progress !== '') {
      if (row.progress === 'success') {
        return 'progress-bar-success';
      }
      else if (row.progress === 'warning') {
        return 'progress-bar-warning';
      } else if (row.progress === 'danger') {
        return 'progress-bar-danger';
      }
    }
    return 'bar-filled';
  }
  getTrackerActionStatus(rowValue: any): string {
    let strLabel = '';
    this.editStatus = false;
    this.submitStatus = false;
    this.refreshStatus = false;
    if (rowValue && rowValue.failureCount > 0) {
      strLabel = 'Edit';
      this.editStatus = true;
    } else if (rowValue && rowValue.failureCount === 0 && rowValue.warningCount > 0) {
      strLabel = 'Re-Submit';
      this.submitStatus = true;
    } else if (rowValue && rowValue.isReload) {
      strLabel = 'Refresh';
      this.refreshStatus = true;
    }
    return strLabel;
  }
  uploadAction(rowValue: any, index: number, actionName: string): void {
    switch (actionName) {
      case 'Edit': this.onActionClick(rowValue, index, this.operationType.navigation); break;
      case 'Downlaod': this.onActionClick(rowValue, index, this.operationType.export); break;
      case 'Refresh': this.onActionClick(rowValue, index, this.operationType.reload); break;
      case 'Re-Submit': this.onActionClick(rowValue, index, this.operationType.submit); break;
    }
  }
}
