<section class="overflow-auto smart-table custom-overflow-scroll" [ngStyle]="tableConfig?.tableStyle">
    <table class="w-100" mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
        <ng-container *ngFor="let colDef of columnDef" [matColumnDef]="colDef.key"
            [sticky]="data.length > 0 ? colDef.sticky : false">
            <th mat-header-cell [disabled]="!colDef.isSortable" *matHeaderCellDef mat-sort-header class="text-nowrap"
                [ngClass]="colDef.cellHeaderClass && colDef.cellHeaderClass !== '' ? colDef.cellHeaderClass : ''"
                [ngStyle]="{'min-width': colDef.width, 'width': colDef.fixedWidth, 'max-width': colDef.maxWidth}">
                <span>
                    <span [innerHTML]="colDef.titleTemplate ? colDef.titleTemplate : colDef.title | uppercase"></span>
                    <span class="mandatory-field" *ngIf="colDef.isMandatory">*</span>
                </span>
                <ng-container *ngIf="colDef.displayType === 'CHECKBOX'">
                    <mat-checkbox [checked]="selection.hasValue() && isAllSelected()"
                        [ngClass]="colDef.cellHeaderClass && colDef.cellHeaderClass !== '' ? colDef.cellHeaderClass : ''"
                        aria-label="input{{colDef.displayType}}" (change)="$event ? toggleAll($event) : null"
                        [disabled]="isSelectAllDisable()">
                    </mat-checkbox>
                </ng-container>
                <ng-container *ngIf="colDef.filterable">
                    <button class="btn btn-link text-decoration-none py-0" mat-icon-button #filterMenu="matMenuTrigger"
                        [matMenuTriggerFor]="searchmenu" aria-label="Filter" (click)="$event.stopPropagation()">
                        <span
                            [ngClass]="emptyfilterIcon ?'smart-icon-filter-o filter-style' : 'smart-icon-filter filter-style'"></span>
                    </button>
                    <mat-menu #searchmenu xPosition="before" class="table-filter-menu">
                        <div (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
                            <span mat-menu-item>
                                <mat-checkbox class="pb-1" *ngFor="let option of colDef.filterOptions" [value]="option"
                                    (change)="addFilter($event)">
                                    {{option}}
                                </mat-checkbox>
                            </span>
                            <div class="pt-2">
                                <button mat-flat-button color="primary"
                                    (click)="applyModelFilter(colDef.key); filterMenu.closeMenu()">APPLY</button>
                            </div>
                        </div>
                    </mat-menu>
                </ng-container>
            </th>
            <td mat-cell *matCellDef="let row; let i = dataIndex" [class.active]="i === selectedRawIdx"
                [ngClass]="colDef.cellDataClass  && colDef.cellDataClass !== '' ? colDef.cellDataClass : ''">
                <ng-container [ngSwitch]="colDef.displayType">
                    <ng-container *ngSwitchCase="'DELETE'">
                        <button aria-label="delete record" mat-stroked-button
                            (click)="onActionClick(row,i,operationType.delete)"
                            class="smart-btn smart-icon-trash border-0 bg-transparent text-center"></button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'DELETEANDEDIT'">
                        <button aria-label="Edit record" mat-stroked-button
                            (click)="onActionClick(row,i,operationType.edit)"
                            class="smart-btn smart-icon-edit btn smart-btn-link me-2"></button>
                        <button aria-label="delete record" mat-stroked-button
                            (click)="onActionClick(row,i,operationType.delete)"
                            class="smart-btn smart-icon-trash btn smart-btn-link"></button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'EDIT'">
                        <button aria-label="Edit record" mat-stroked-button
                            (click)="onActionClick(row,i,operationType.edit)"
                            class="smart-btn smart-icon-edit btn smart-btn-link me-2"></button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'DELETEANDVIEW'">
                        <div class="d-flex justify-content-center">
                            <button aria-label="view record" mat-stroked-button
                                (click)="onActionClick(row,i,operationType.view)"
                                class=" btn smart-btn-link me-2 text-decoration-underline">View</button>
                            <button aria-label="delete record" mat-stroked-button
                                (click)="onActionClick(row,i,operationType.delete)"
                                class="smart-btn smart-icon-trash btn smart-btn-link"></button>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'VIEW'">
                        <button aria-label="view record" mat-stroked-button
                            (click)="onActionClick(row,i,operationType.view)"
                            class=" btn smart-btn-link me-2 text-decoration-underline">View</button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'ADDANDDELETE'">
                        <div [ngClass]="row.isAdd ?'justify-content-between' : 'justify-content-end'" class="d-flex">
                            <button *ngIf="row.isAdd" aria-label="Add Row" mat-stroked-button
                                (click)="onActionClick(row,i,operationType.add)"
                                [disabled]="row.IsSuccessRecord || isResubmit"
                                class="smart-btn smart-icon-add btn smart-btn-link m-0"></button>
                            <button aria-label="Delete Row" mat-stroked-button
                                [disabled]="row.IsSuccessRecord || isResubmit"
                                (click)="onActionClick(row,i,operationType.delete)"
                                [ngClass]="row.IsSuccessRecord || isResubmit ? 'trash-disable' : ''"
                                class="smart-btn btn smart-icon-trash smart-btn-link m-0"></button>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'DELETEORRELOAD'">
                        <div class="d-flex justify-content-center">
                            <button *ngIf="row.isDownload" aria-label="Download option" mat-stroked-button
                                (click)="onActionClick(row,i,operationType.export)"
                                class="smart-btn smart-icon-download btn smart-btn-link m-0"></button>
                            <button *ngIf="row.isReload" aria-label="Reload option" mat-stroked-button
                                (click)="onActionClick(row,i,operationType.reload)"
                                class="smart-btn smart-icon-reload btn smart-btn-link p-0 m-0"></button>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'BulkUploadTrackerExtractButton'">
                        <div class="d-flex justify-content-center" *ngIf="row.isDownload">

                            <button class="btn  smart-btn smart-tracker-btn"
                                (click)="uploadAction(row,i, 'Downlaod')"><span class="smart-icon-download"></span>
                                <span class="ms-2">Download</span>
                            </button>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'BulkUploadTrackerActionButton'">
                        <div class="d-flex justify-content-center" *ngIf="getTrackerActionStatus(row) !== ''">
                            <button class="btn smart-btn smart-tracker-btn"
                                (click)="uploadAction(row,i, getTrackerActionStatus(row))">
                                <span
                                    [ngClass]="(editStatus) ? 'smart-icon-edit' : (refreshStatus) ? 'smart-icon-reload' : (submitStatus) ? 'smart-icon-resubmit' : ''"></span>
                                <span class="ms-2">{{getTrackerActionStatus(row)}}</span>
                            </button>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'ErrorPopUp'">
                        <button *ngIf="row.isPopup && row[colDef.key]&& row[colDef.key] !==''; else dataTemplate"
                            [attr.aria-label]="colDef.title"
                            [ngClass]="colDef.cellbtnClass && colDef.cellbtnClass !== '' ? colDef.cellbtnClass : ''"
                            class="btn smart-btn"
                            (click)="onActionClick(row,i,operationType.popup, colDef.cellbtnClass)">{{row[colDef.key]}}
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'WarningPopUp'">
                        <button *ngIf="row.isWarningPopup && row[colDef.key]&& row[colDef.key] !==''; else dataTemplate"
                            [attr.aria-label]="colDef.title" class="btn smart-btn"
                            [ngClass]="colDef.cellPopUpClass && colDef.cellPopUpClass !== '' ? colDef.cellPopUpClass : 'link-danger'"
                            (click)="onActionClick(row,i,operationType.popup, colDef.cellPopUpClass)">
                            {{row[colDef.key]}}</button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'STATUS'">
                        <span [ngClass]="row[colDef.key] ? statusCode(row[colDef.key]) : ''"
                            class="d-flex flex-column justify-content-center align-items-center statusColorStyles">
                            {{row[colDef.key]}}</span>
                    </ng-container>
                    <ng-container *ngSwitchCase="'RADIO'">
                        <div class="radio-option form-check d-inline-block m-0">
                            <label for="input{{colDef.key}}{{i}}" class="visually-hidden">{{columnDef ?
                                row[columnDef[1].key] : ''}}</label>
                            <input id="input{{colDef.key}}{{i}}" type="radio" class="form-check-input mt-2"
                                name="{{colDef.key}}" [value]="row[colDef.key]" [checked]="row[colDef.key]"
                                (change)="onEmitRadioAction(row,i,operationType.select)">
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Actions'">
                        <div class="text-center">

                            <button
                                *ngIf="colDef.items && colDef.items.includes('expandablerow') && expandedElement!==row && showAction"
                                aria-label="Expand Table" mat-stroked-button
                                (click)="(expandedElement = expandedElement === row ? null : row); $event.stopPropagation(); emitExpandEvent(row);"
                                class="btn smart-btn smart-btn-link smart-icon-arrow-down m-0 px-1 px-md-2"></button>
                            <button
                                *ngIf="colDef.items && colDef.items.includes('menu') && expandedElement!==row && (row.menuItems === undefined || row.menuItems.length>0)"
                                aria-label="Open Options" mat-stroked-button
                                class="smart-icon-more btn smart-btn smart-btn-link px-1 px-md-2"
                                [matMenuTriggerFor]="menu"></button>

                            <button *ngIf="colDef.items && colDef.items.includes('expandablerow') && 
                        expandedElement===row" aria-label="Collapse Table" mat-stroked-button
                                (click)="expandedElement = null; $event.stopPropagation()"
                                class="btn smart-btn smart-btn-link p-0 m-0 smart-icon-arrow-down rotate-180"></button>
                        </div>
                        <!-- Menu View start -->
                        <mat-menu #menu="matMenu">
                            <ng-container *ngIf="row.menuItems === undefined">
                                <button (click)="menuTrigger(menuItem,row)" *ngFor="let menuItem of colDef.menuItems"
                                    mat-menu-item>
                                    <span>{{menuItem}}</span>
                                </button>
                            </ng-container>
                            <ng-container *ngIf="row.menuItems">
                                <button (click)="menuTrigger(menuItem,row)" *ngFor="let menuItem of row.menuItems"
                                    mat-menu-item>
                                    <span>{{menuItem}}</span>
                                </button>
                            </ng-container>
                        </mat-menu>
                        <!-- Menu View end -->

                    </ng-container>
                    <ng-container *ngSwitchCase="'BulkUploadStatus'">
                        <div class="text-center">
                            <span class="badge status-indicator in-progress graphik-semi-bold" *ngIf="row.isReload">
                                In Progress
                            </span>
                            <span class="badge status-indicator success graphik-semi-bold" *ngIf="row.successCount > 0">
                                Success ({{row.successCount}})
                            </span>
                            <span class="badge status-indicator error ms-2 graphik-semi-bold"
                                *ngIf="row.failureCount > 0">
                                Errors ({{row.failureCount}})</span>
                            <span *ngIf="row.warningCount > 0">
                                <span class="badge status-indicator warning ms-2 graphik-semi-bold">
                                    Warnings ({{row.warningCount}})</span>
                                <button
                                    *ngIf="colDef.items && colDef.items.includes('expandablerow') && expandedElement!==row && showAction"
                                    aria-label="Expand Table" mat-stroked-button
                                    (click)="(expandedElement = expandedElement === row ? null : row); $event.stopPropagation(); emitExpandEvent(row);"
                                    class="btn smart-btn smart-btn-link smart-icon-arrow-down m-0 px-1 px-md-2 ms-2"></button>
                                <button *ngIf="colDef.items && colDef.items.includes('expandablerow') && 
                                  expandedElement===row" aria-label="Collapse Table" mat-stroked-button
                                    (click)="expandedElement = null; $event.stopPropagation()"
                                    class="btn smart-btn smart-btn-link p-0 m-0 smart-icon-arrow-down rotate-180 ms-2"></button>
                            </span>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'CHECKBOX'">
                        <mat-checkbox id="input{{colDef.key}}{{i}}" (change)="onEmitCheckBoxAction(row)"
                            (click)="$event.stopPropagation()" aria-label="input{{colDef.key}}{{i}}"
                            [(ngModel)]="row[colDef.key]" [disabled]="isCheckboxDisabled(row)"
                            [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                    </ng-container>
                    <ng-container *ngSwitchCase="'SLNO'">
                        <span [attr.aria-label]="colDef.title">{{ (currentPage * pageSize) + (i + 1)}}</span>
                    </ng-container>
                    <ng-container *ngSwitchCase="'REJECTION_COMMENTS'">
                        <input matInput type="text" id="input{{colDef.key}}{{i}}" class="form-control"
                            [(ngModel)]="row[colDef.key]" [disabled]="checkDisabledComment(row)"
                            aria-label="Rejection Comments" (input)="onInputComments()">
                    </ng-container>
                    <ng-container *ngSwitchCase="'DATE_FORMAT'">
                        {{row[colDef.key] | date: colDef.dateFormat ? colDef.dateFormat : 'MM/dd/yyyy'}}
                    </ng-container>
                    <ng-container *ngSwitchCase="'LINK'">
                        <button class="btn p-0" (click)="actionEvent(row)"
                            [ngClass]="colDef.cellDataClass && colDef.cellDataClass !== '' ? colDef.cellDataClass : ''"
                            [innerHTML]="row[colDef.key]">
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'TEXTANDLINK'">
                        <ng-container *ngIf="row.isPopup; else dataTemplate">
                            <span class="p-0 text-break" [innerHTML]="row[colDef.key].split(',')[0] + ', '"></span>
                            <button class="btn p-0 btn-link" (click)="onActionClick(row,i,operationType.navigation)"
                                [ngClass]="colDef.cellDataClass && colDef.cellDataClass !== '' ? colDef.cellDataClass : ''"
                                [innerHTML]="row[colDef.key].split(',')[1]">
                            </button>
                        </ng-container>

                    </ng-container>
                    <ng-container *ngSwitchCase="'dropdown'">
                        <ng-select placeholder="Select" [clearable]="true" [attr.aria-label]="colDef.title"
                            [ngClass]="{'error-validation': row?.cssStyle?.includes(colDef.key)}"
                            class="filter-dropdown-selection smart-ng-select selection-control custom-overflow-scroll filter-clear-selection"
                            appendTo="body" appNgSelect="{{colDef.title}}" [items]="colDef?.menuItems"
                            bindLabel="select {{colDef.title}}" bindValue="templateId" [(ngModel)]="row[colDef.key]"
                            [disabled]="row.IsSuccessRecord">
                            <ng-template ng-option-tmp let-item="item">
                                <span class="text-truncate" title="{{item}}">{{item}}</span>
                            </ng-template>
                        </ng-select>
                    </ng-container>
                    <ng-container *ngSwitchCase="'string'">
                        <input [attr.aria-label]="colDef.title" type="text" value="{{row[colDef.key]}}"
                            class="text-input form-control" [(ngModel)]="row[colDef.key]"
                            [ngClass]="{'error-validation': row?.cssStyle?.includes(colDef.key)}"
                            [disabled]="row.IsSuccessRecord">
                    </ng-container>
                    <ng-container *ngSwitchCase="'textArea'">
                        <textarea [attr.aria-label]="colDef.title" class="text-input form-control"
                            [(ngModel)]="row[colDef.key]"
                            [ngClass]="{'error-validation': row?.cssStyle?.includes(colDef.key)}"
                            [disabled]="row.IsSuccessRecord">{{row[colDef.key]}}</textarea>
                    </ng-container>
                    <ng-container *ngSwitchCase="'date'">
                        <div class="date-picker-container position-relative">
                            <input class="form-control smart-form-control date-input" matInput
                                [disabled]="row.IsSuccessRecord" [attr.aria-label]="colDef.title"
                                [ngClass]="{'error-validation': row?.cssStyle?.includes(colDef.key)}"
                                [matDatepicker]="pickerDate" (click)="pickerDate.open()" [(ngModel)]="row[colDef.key]"
                                name="endDate" required>
                            <span class="smart-icon-calendar position-absolute filter-date-icon"
                                (click)="pickerDate.open()"></span>
                            <mat-datepicker #pickerDate></mat-datepicker>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'CONDITIONALLINK'">
                        <button *ngIf="colDef.conditionKey && row[colDef.conditionKey];else elseBlock"
                            class="btn p-0 text-decoration-none" (click)="onActionClick(row,i,'CONDITIONALLINK')"
                            [ngClass]="colDef.cellDataClass && colDef.cellDataClass !== '' ? colDef.cellDataClass : ''">
                            {{row[colDef.key]}}
                        </button>
                        <ng-template #elseBlock><span class="p-0 text-decoration-none d-inline-block"
                                [innerHTML]="row[colDef.key]"></span></ng-template>
                    </ng-container>
                    <ng-container *ngSwitchCase="'POPUP'">
                        <button *ngIf="row.isPopup; else dataTemplate" [attr.aria-label]="colDef.title"
                            class="btn smart-btn"
                            [ngClass]="colDef.cellPopUpClass && colDef.cellPopUpClass !== '' ? colDef.cellPopUpClass : 'link-danger'"
                            (click)="onActionClick(row,i,operationType.popup, colDef.cellPopUpClass)">
                            {{row[colDef.key]}}</button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'PROGRESSBAR'">
                        <div class="d-flex smart-progress-bar">
                            <div class="progress smart-probability-bar w-100 me-2">
                                <div [ngClass]="progressBarStatus(row)" [style.width.%]=row[colDef.key]>
                                </div>
                            </div>
                            <span class="progress-bar-label">{{row[colDef.key]}}%</span>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchDefault [ngTemplateOutlet]="dataTemplate">
                    </ng-container>
                </ng-container>
                <ng-template #dataTemplate>
                    <span class="p-0 text-truncate d-block" title="{{row[colDef.key] | htmlToContent}}"
                        [innerHTML]="row[colDef.key]"></span>
                </ng-template>
            </td>
        </ng-container>




        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length" class="p-0">
                <div *ngIf="element === expandedElement" class="expand-element-detail d-flex overflow-hidden"
                    [ngClass]="{'expanded':element === expandedElement}"
                    [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">

                    <div *ngIf="expandConfig" class="row w-100  m-auto">
                        <ng-container *ngIf="detailsTemplate; else defaultExpandTemplate">
                            <ng-container *ngTemplateOutlet="detailsTemplate"></ng-container>
                        </ng-container>
                        <ng-template #defaultExpandTemplate>
                            <ng-container *ngFor="let expandItems of expandConfig; let expandConfigIndex = index">
                                <div class="py-4 detail-cell {{expandItems.class}}">
                                    <span class="title" [title]="expandItems.title">{{expandItems.title}}</span> <br>
                                    <span class="value"
                                        [title]="element[expandItems.key]?element[expandItems.key]:null">
                                        <ng-container [ngSwitch]="expandItems.displayType">
                                            <ng-container *ngSwitchCase="'DATE_FORMAT'">
                                                {{element[expandItems.key] | date: 'MM/dd/yyyy'}}
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>
                                                {{element[expandItems.key]}}
                                            </ng-container>
                                        </ng-container>

                                    </span>
                                </div>
                                <hr class="mb-0"
                                    *ngIf="tableConfig?.horizontalLine ? expandConfigIndex=== tableConfig?.horizontalLine : expandConfigIndex===5 || expandConfigIndex===11">
                            </ng-container>
                        </ng-template>
                    </div>

                </div>
            </td>
        </ng-container>



        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: tableConfig?.tableStyle?.isHeaderSticky"
            [ngClass]="{'sticky-row': tableConfig?.tableStyle?.isHeaderSticky}"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="expand-element-row"
            [class.expand-expanded-row]="expandedElement === element"
            (paste)="tableConfig?.isPasteEnabled && pasteEvent($event)"
            (onpaste)="tableConfig?.isPasteEnabled && pasteEvent($event)">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expand-detail-row"></tr>
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <ng-container *ngIf="filterTemplate !== undefined; else noDataTemplate">
                <ng-container *ngTemplateOutlet="filterTemplate"></ng-container>
            </ng-container>
        </tr>
        <ng-template #noDataTemplate>
            <td class="mat-cell text-center" [colSpan]="columnDef?.length">
                {{tableConfig?.noDataTxt ? tableConfig?.noDataTxt : 'No data matching the filter'}}
            </td>
        </ng-template>
    </table>
</section>
<mat-paginator *ngIf="totalRows && totalRows > 0" [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
    (page)="pageChanged($event)">
</mat-paginator>